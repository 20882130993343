interface ErrorHappenedProps {
  error: Error;
  label: string;
}

export default function ErrorHappened({
  label,
}: ErrorHappenedProps): JSX.Element {
  return (
    <div className="flex w-full flex-col items-center my-8">
      <div className="text-white">Error happened: {label}</div>
    </div>
  );

  // <div>Error happened: {label}</div>
}
