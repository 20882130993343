interface TabItemProp {
  isActive: boolean;
}
export const TabsItems = ({
  isActive,
}: TabItemProp) => `rounded-md px-3 text-base text-text-gray-2 font-bold flex justify-center items-center h-10
  ${isActive ? 'bg-brand-bg-1 !text-black' : ''}`;
export const TabsWrapper = `w-full flex overflow-x-auto overflow-y-hidden space-x-2`;
export const ActivityTabIcon = `w-4 mr-2`;
export const TabsSubItems = ({
  isActive,
}: TabItemProp) => `border border-solid border-white rounded-md px-3 text-base text-white font-normal flex justify-center items-center h-10  whitespace-nowrap
  ${isActive ? '!font-bold !border-border-bg-2 border-2 !text-text-brand-1' : ''}`;
export const TabContentText = `text-base text-text-gray-1 font-thin `;
