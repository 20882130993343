import { APPROVE_ICON } from '#/constants/common/images.constants';
import AnimatedModal from '#/components/common/AnimatedModal.component';
import {
  CardContainer,
  HeaderSubTitle,
  HeaderTitle,
  ImgContainer,
  InnerContainer,
  InstallImg,
  TopBorder,
} from './ApprovePopup.styled';

const ApprovePopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <AnimatedModal
      title=""
      isOpen={isOpen}
      onClose={onClose}
      showCloseIcon={false}
    >
      <div className={CardContainer}>
        <div className="text-center">
          <div className={TopBorder} />
        </div>
        <div className={InnerContainer}>
          <div>
            <div className={ImgContainer}>
              <img
                className={InstallImg}
                src={APPROVE_ICON}
                alt="Approve Icon"
              />
            </div>
            <div className="text-center">
              <h2 className={HeaderTitle}>
                Approve transaction in your wallet
              </h2>
              <h3 className={HeaderSubTitle}>
                Afterward, return to this page.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </AnimatedModal>
  );
};
export default ApprovePopup;
