export const OVER_SCAN = 5;
export const ESTIMATE_SIZE = 300;
export const ITEMS_VIEW_CONSTANT = 14;
export const FETCH_ITEMS_HEIGHT = 20;
export const VIRTUALIZER_HEIGHT = '4250px';

/** when using the element virtualizer
 * ITEMS_VIEW_CONSTANT should be ceiling of
 * VIRTUALIZER_HEIGHT / ESTIMATE_SIZE
 */
