import { useCallback, useState, useMemo } from 'react';
import { isWalletConnect, useConnect, useSuggestChain, WalletType } from 'graz';
import { toast } from 'react-toastify';
import Button from '#/components/common/Button';
import AnimatedModal from '#/components/common/AnimatedModal.component';
import { WALLET_POPUP_ICON } from '#/constants/common/images.constants';
import { CONNECT_WALLET, LEARN_TEXT } from './ConnectWallet.constants';
import { getListedWallets } from '#/utils/graz';
import {
  CardContainer,
  IconWrapper,
  CardInnerContainer,
  CardHeader,
  HeaderTitle,
  CardIcon,
  ContentContainer,
  ContentTitle,
  ContentSubTitle,
  LearnLink,
  WalletIconWrapper,
  WalletNameText,
  PopularWalletWrapper,
  WalletPopular,
  WalletIconPopular,
  SectionTitle,
  WalletOptionWrapper,
} from './ConnectWallet.styled';
import WalletTutorials from '../WalletTutorials/WalletTutorials.component';
import WalletInstallPopup from '../WalletInstallPopup/WalletInstallPopup.component';
import env from '#/utils/env';
import { instrumentWalletConnection } from '#/utils/sentry';

const detectPlatform = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.includes('chrome') && !userAgent.includes('mobile')) {
    return 'chrome';
  } else if (userAgent.includes('firefox') && !userAgent.includes('mobile')) {
    return 'firefox';
  } else if (
    /ipad|iphone|ipod/.test(userAgent) &&
    !(window as unknown as { MSStream: unknown }).MSStream
  ) {
    return 'ios';
  } else if (userAgent.includes('android')) {
    return 'android';
  }
  return 'unknown';
};

export function isDuckDuckGoBrowser(): boolean {
  return /DuckDuckGo/.test(navigator.userAgent);
}

const ConnectWallet = ({
  isOpen,
  setOpen,
}: {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}) => {
  const [openTutorial, setOpenTutorial] = useState(false);
  const [openExtensionInfo, setOpenExtensionInfo] = useState(false);
  const [extensionWallet, _setExtensionWallet] = useState<string | undefined>();
  const [isConnecting, setIsConnecting] = useState(false);
  const [suggestChainError, setSuggestChainError] = useState<string | undefined>(undefined)
  const [connectError, setConnectError] = useState<string | undefined>(undefined)

  const { suggestAsync } = useSuggestChain();
  const { connectAsync } = useConnect();

  const handleConnect = useCallback(
    async (walletType: WalletType) => {
      setIsConnecting(true);
      setSuggestChainError(undefined)
      setConnectError(undefined)

      if (!isWalletConnect(walletType)) {
        try {
          await suggestAsync({
            chainInfo: env.chainInfo,
            walletType,
          });
        } catch (error) {
          console.warn(error);

          if (error && typeof error === "object" && "message" in error) {
            setSuggestChainError(JSON.stringify(error.message))
          } else if (typeof error === "string") {
            setSuggestChainError(error)
          }
        }
      }

      try {
        await instrumentWalletConnection(walletType, async () => {
          await connectAsync({
            chainId: env.chainInfo.chainId,
            walletType,
          });
        });
      } catch (error: unknown) {
        const errorMessage = 'Error connecting to wallet';

        if (error && typeof error === "object" && "message" in error) {
          setConnectError(JSON.stringify(error.message))
        } else if (typeof error === "string") {
          setConnectError(error)
        }

        toast.error(errorMessage);
      } finally {
        setIsConnecting(false);
      }
    },
    [suggestAsync, connectAsync],
  );

  const platform = useMemo(() => detectPlatform(), []);

  const {
    installedPriorityWallets,
    otherInstalledWallets,
    uninstalledWallets,
    allWallets,
  } = getListedWallets();

  const renderWalletButton = (
    walletType: WalletType,
    isHighlighted = false,
    isInstalled = true,
  ) => {
    const wallet = allWallets[walletType as keyof typeof allWallets];
    const downloadLink =
      'downloads' in wallet &&
      wallet.downloads &&
      wallet.downloads[platform as keyof typeof wallet.downloads];

    const isMobilePlatform = platform === 'ios' || platform === 'android';
    const isWalletConnectMobile =
      walletType === WalletType.WC_KEPLR_MOBILE ||
      walletType === WalletType.WC_LEAP_MOBILE;

    // if (walletType === WalletType.WALLETCONNECT) {
    //   return null;
    // }

    if (isWalletConnectMobile && (isDuckDuckGoBrowser() || !isMobilePlatform)) {
      return null;
    }

    return (
      <Button
        key={wallet.name}
        className={`${WalletPopular} ${isHighlighted ? 'highlighted' : ''} ${isInstalled ? '' : 'not-installed'
          }`}
        onClick={() => {
          if (isInstalled) {
            handleConnect(walletType);
          } else if (downloadLink) {
            window.open(downloadLink, '_blank', 'noopener,noreferrer');
          }
        }}
        disabled={isConnecting}
      >
        <span className={WalletIconWrapper}>
          <img
            className={WalletIconPopular}
            src={wallet.imgSrc}
            alt={`${wallet.name} Logo`}
          />
        </span>
        <span className={WalletNameText}>{wallet.name}</span>
        {!isInstalled && <span className="install-text">Install</span>}
      </Button>
    );
  };

  if (openTutorial) {
    return (
      <WalletTutorials
        isOpen={openTutorial}
        onClose={() => setOpenTutorial(false)}
      />
    );
  }

  if (openExtensionInfo) {
    return (
      <WalletInstallPopup
        walletName={extensionWallet}
        isOpen={openExtensionInfo}
        onClose={() => setOpenExtensionInfo(false)}
      />
    );
  }

  return (
    <AnimatedModal title="" isOpen={isOpen} onClose={() => setOpen(false)}>
      <div className={CardContainer}>
        <div className="text-left px-4 lg:px-6 pb-4 mb-4">
          <div className={IconWrapper}>
            <img
              className={CardIcon}
              src={WALLET_POPUP_ICON}
              alt="Wallet Icon"
            />
          </div>
          <div className={ContentContainer}>
            <h3 className={ContentTitle}>New to Crypto?</h3>

            {env.displayConnectionErrors && suggestChainError && <div style={{ color: 'white' }}>Error suggesting chain: {suggestChainError}</div>}
            {env.displayConnectionErrors && connectError && <div style={{ color: 'white' }}>Error connecting: {connectError}</div>}

            <p className={ContentSubTitle}>
              A wallet is like a digital bank account for your cryptocurrency.
              It keeps your funds safe and lets you send or receive crypto.
            </p>
            <div className="mt-3">
              <Button
                className={LearnLink}
                onClick={() => setOpenTutorial(true)}
              >
                {LEARN_TEXT}
              </Button>
            </div>
          </div>
        </div>

        <div className={CardInnerContainer}>
          <div className={CardHeader}>
            <h2 className={HeaderTitle}>{CONNECT_WALLET}</h2>
          </div>
          <div className="mt-3">
            <div className={`${PopularWalletWrapper} mt-3`}>
              {installedPriorityWallets.length > 0 && (
                <div className="wallet-section">
                  <h4 className={SectionTitle}>Recommended</h4>
                  <div className={WalletOptionWrapper}>
                    {installedPriorityWallets.map((wallet) =>
                      renderWalletButton(wallet, true),
                    )}
                  </div>
                </div>
              )}
              {otherInstalledWallets.length > 0 && (
                <div className="wallet-section">
                  <h4 className={SectionTitle}>Installed wallets</h4>
                  <div className={WalletOptionWrapper}>
                    {otherInstalledWallets.map((wallet) =>
                      renderWalletButton(wallet as WalletType),
                    )}
                  </div>
                </div>
              )}
              {uninstalledWallets.length > 0 && (
                <div className="wallet-section">
                  <h4 className={SectionTitle}>Available wallets</h4>
                  <div className={WalletOptionWrapper}>
                    {uninstalledWallets.map((wallet) =>
                      renderWalletButton(wallet as WalletType, false, false),
                    )}
                  </div>

                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AnimatedModal>
  );
};

export default ConnectWallet;
