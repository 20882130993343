import {
  CheckInput,
  FilterCardContainer,
  HeadDivider,
  HeaderWrapper,
  MarketItemContainer,
  MarketLabel,
  Title,
} from '#/components/FilterCards/FilterCards.styled';
import {
  ActivityOutcome,
  toggleOutcomeFilterAndSynchUrl,
} from '#/utils/slices/activityFiltersSlice';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import { useSearchParams } from 'react-router-dom';

const outcomeOptions: ActivityOutcome[] = ['Won', 'Lost'];

export default function OutcomeFilter() {
  const dispatch = useAppDispatch();
  const outcomeFilters = useAppSelector(
    (state) => state.activityFilters.outcomes,
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOutcomeChange = (outcome: ActivityOutcome) => {
    dispatch(
      toggleOutcomeFilterAndSynchUrl({
        outcome,
        searchParams,
        setSearchParams,
      }),
    );
  };

  return (
    <>
      <div className={HeaderWrapper}>
        <span className={Title}>Outcome</span>
      </div>
      <hr className={HeadDivider} />
      <div className={FilterCardContainer}>
        <ul>
          {outcomeOptions.map((outcome) => {
            const value = outcomeFilters[outcome] || false;

            return (
              <li
                className={MarketItemContainer}
                key={outcome}
                onClick={() => handleOutcomeChange(outcome)}
              >
                <input
                  className={`${CheckInput} !mr-0`}
                  type="checkbox"
                  id={outcome}
                  name="status_filter"
                  checked={value}
                  onChange={() => {}}
                />
                <span className={MarketLabel}>{outcome}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
