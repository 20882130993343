export const WALLET_ICON = `/v2-icons/wallet-icon.svg`;
export const CLOSE_ICON = `/v2-icons/close-icon.svg`;
export const GOOGLE_ICON = `/v2-icons/google-icon.svg`;
export const APPLE_ICON = `/v2-icons/apple-icon.svg`;
export const TROPHY_ICON = `/v2-icons/trophy-icon.svg`;
export const TROPHY_ICON_BRAND = `/v2-icons/trophy-icon-brand.svg`;
export const FOLDER_ICON = `/v2-icons/folder-icon.svg`;
export const REWARDS_ICON = `/v2-icons/rewards-icon.svg`;
export const GAMBLING_ICON = `/v2-icons/gambling-icon.svg`;
export const HELP_ICON = `/v2-icons/help-icon.svg`;
export const GREEN_INFO_ICON = `/v2-icons/green-info-icon.svg`;
export const EDIT_ICON = `/v2-icons/edit-icon.svg`;
export const DOWN_ARROW_ICON = `/v2-icons/down-arrow.svg`;
export const AVATAR_IMG = `/v2-icons/avatar.png`;
export const CALCULATOR_ICON = `/v2-icons/calculator-icon.svg`;
export const HOME_ICON = `/v2-icons/home-icon.svg`;
export const EXPLORE_ICON = `/v2-icons/explore-icon.svg`;
export const BETS_ICON = `/v2-icons/bets-icon.svg`;
export const BETS_ICON_ACTIVE = `/v2-icons/bet-icon-active.svg`;
export const NO_OF_BETS_ICON = `/v2-icons/noofbets-icon.svg`;
export const NOTIFICATION_ICON = `/v2-icons/notification-icon.svg`;
export const GRAPH_UP_ICON = `/v2-icons/graph-up-icon.svg`;
export const GRAPH_DOWN_ICON = `/v2-icons/graph-down-icon.svg`;
export const WALLET_ICON_DARK = `/v2-icons/wallet-icon-dark.svg`;
export const KYC_ICON = `/v2-icons/kyc-icon.svg`;
export const KEY_ICON = `/v2-icons/key-icon.svg`;
export const LOCK_ICON = `/v2-icons/lock-icon.svg`;
export const COPY_ICON = `/v2-icons/copy-icon.svg`;
export const DEPOSIT_ICON = `/v2-icons/deposit-icon.svg`;
export const FILE_ICON = `/v2-icons/file-icon.svg`;
export const FILTER_ICON = `/v2-icons/filter-icon.svg`;
export const LEAP_LOGO = `/v2-icons/leapwallet-logo.png`;
export const CHECK_ICON = `/v2-icons/check-icon.svg`;
export const CHECK_CIRCLE_ICON = `/v2-icons/check-circle-icon.svg`;
export const RADIO_CIRCLE_ICON = `/v2-icons/radio-circle-icon.svg`;
export const INPROGRESS_CIRCLE_ICON = `/v2-icons/inprogress-circle-icon.svg`;
export const ICON_FAILED = `/v2-icons/icon-failed.svg`;
export const GRAPH_ICON = `/v2-icons/graph-icon.svg`;
export const NEXT_BRAND_ICON = `/v2-icons/next-icon.svg`;
export const BACK_ICON = `/v2-icons/back-arrow-icon.svg`;
export const KEPLR_LOGO = `/v2-icons/keplr-logo.png`;
export const ARROW_BACK = `/v2-icons/arrow-back.svg`;
export const SSS_LOGO = `/v2-icons/logo.png`;
export const COSMO_LOGO = `/v2-icons/cosmostation-logo.png`;
export const WALLET_INSTALL_IMG = `/v2-icons/install-wallet-img.svg`;
export const ARROW_UP = `/v2-icons/arrowup.svg`;
export const ARROW_DOWN = `/v2-icons/arrowdown.svg`;
export const MESSAGE_ICON = `/v2-icons/message-icon.svg`;
export const INSTAGRAM_ICON = `/v2-icons/instagram-icon.svg`;
export const MESSENGER_ICON = `/v2-icons/messenger-icon.svg`;
export const FACEBOOK_ICON = `/v2-icons/facebook-icon.svg`;
export const TWITTER_ICON = `/v2-icons/twitter-icon.svg`;
export const MORE_ICON = `/v2-icons/more-icon.svg`;
export const SHARE_ICON = `/v2-icons/share-icon.svg`;
export const APPROVE_ICON = `/v2-icons/approve-icon.svg`;
export const SUCCESS_ICON_ACTIVE = `/v2-icons/success-icon-active.svg`;
export const BUTTON_LOADER_ICON = `/v2-icons/button-loader.png`;
export const WALLET_POPUP_ICON = `/v2-icons/wallet-popup-icon.svg`;
export const TRENDING_ICON = `/v2-icons/trending-icon.svg`;
export const FEATURED_ICON = `/v2-icons/featured-icon.svg`;
export const FOOTBALL_ICON_BRAND = `/v2-icons/football-icon-brand.svg`;
export const FOOTBALL_ICON_DARK = `/v2-icons/football-icon-dark.svg`;
export const FOOTBALL_ICON_LIGHT = `/v2-icons/football-icon.svg`;
export const CRICKET_ICON_DARK = `/v2-icons/cricket-icon-dark.svg`;
export const CRICKET_ICON_LIGHT = `/v2-icons/cricket-icon-light.svg`;
export const CRICKET_ICON_BRAND = `/v2-icons/cricket-icon-brand.svg`;
export const EMPTY_DATA_ICON = `/v2-icons/empty-data-icon.svg`;
export const UPARROW_BRAND_ICON = `/v2-icons/uparrow-icon-brand.svg`;
export const BET_ICON_BRAND = `/v2-icons/bets-icon-brand.svg`;
export const BET_AMOUNT_ICON_BRAND = `/v2-icons/bet-amount-icon-brand.svg`;
export const LIQUIDITY_ICON_BRAND = `/v2-icons/liquidity-icon-brand.svg`;
export const FILTER_ICON_LIGHT = `/v2-icons/filter-icon-light.svg`;
export const BET_ICON_ERROR = `/v2-icons/bet-icon-error.svg`;
export const TRANSACTION_ERROR_ICON = `/v2-icons/transaction-dataempty-icon.svg`;
export const BET_EMPTYDATA_ERROR_ICON = `/v2-icons/bet-dataempty-icon.svg`;
export const HOUSE_EMPTYDATA_ERROR_ICON = `/v2-icons/house-dataempty-icon.svg`;
export const NOTIFICATIONS_EMPTYDATA_ERROR_ICON = `/v2-icons/notification-dataempty-icon.svg`;
export const REWARDS_EMPTYDATA_ERROR_ICON = `/v2-icons/reward-dataempty-icon.svg`;
export const SEARCH_ICON_LIGHT = `/v2-icons/search-icon-light.svg`;
export const MENU_BARS_ICON = `/v2-icons/menu-bars-icon.svg`;
export const EIGHTEEN_PLUS_LOGO = `/v2-icons/18+logo.png`;
export const DICE_ICON = `/v2-icons/dice.png`;
export const LICENCE_LOGO = `/v2-icons/licence-logo.png`;
export const MEDIUM_LOGO = `/v2-icons/medium-logo.svg`;
export const DISCORD_LOGO = `/v2-icons/discord-logo.svg`;
export const TELEGRAM_LOGO = `/v2-icons/telegram-logo.svg`;
export const TWITTER_LOGO = `/v2-icons/twitter-logo.svg`;
export const GITBOOK_LOGO = `/v2-icons/gitbook-logo.svg`;
export const MARKET_ARROW_ICON = `/v2-icons/market-arrow-icon.svg`;
export const ODDS_UP_ARROW = `/v2-icons/up-arrow.svg`;
export const ODDS_DOWN_ARROW = `/v2-icons/down-arrow.svg`;
export const PLUSH_CIRCLE_ICON = `/v2-icons/plush-circle-icon.svg`;
export const MINUS_CIRCLE_ICON = `/v2-icons/minus-circle-icon.svg`;
export const STAR_ICON = `/v2-icons/star-icon.svg`;
export const DOWNLOAD_ICON = `/v2-icons/download-icon.svg`;
export const UPLOAD_ICON = `/v2-icons/upload-icon.svg`;
export const BETS_ICON_DARK = `/v2-icons/bet-icon-dark.svg`;
export const HOUSE_ICON_DARK = `/v2-icons/house-icon-dark.svg`;
export const HOUSE_ICON_LIGHT = `/v2-icons/house-icon-light.svg`;
export const CLOSE_ICON_BRAND = `/v2-icons/close-icon-brand.svg`;
export const DOWNARROW_BRAND_ICON = `/v2-icons/downarrow-icon-brand.svg`;
export const DOWNARROW_LIGHT_ICON = `/v2-icons/down-arrow-light.svg`;
export const SHARE_CIRCLE_ICON = `/v2-icons/share-circle-icon.svg`;
export const STAR_CIRCLE_ICON = `/v2-icons/star-circle-icon.svg`;
export const STAR_CIRCLE_ICON_SELECTED = `/v2-icons/star-circle-icon-selected.svg`;
export const SSS_FULL_LOGO = `/v2-icons/logo-full.png`;
export const HOME_ICON_BRAND = `/v2-icons/home-icon-brand.svg`;
export const EXPLORE_ICON_BRAND = `/v2-icons/explore-icon-brand.svg`;
export const FOLDER_ICON_BRAND = `/v2-icons/folder-icon-brand.svg`;
export const REWARDS_ICON_BRAND = `/v2-icons/rewards-icon-brand.svg`;
export const GAMBLING_ICON_BRAND = `/v2-icons/gambling-icon-brand.svg`;
export const CALCULATOR_ICON_BRAND = `/v2-icons/calculator-icon-brand.svg`;
export const CONTACTUS_ICON = `/v2-icons/contactus-icon.svg`;
export const CONTACTUS_ICON_BRAND = `/v2-icons/contactus-icon-brand.svg`;
export const LOG_OUT_ICON = `/v2-icons/log-out.svg`;
export const INVITES_ICON = `/v2-icons/invites-icon.svg`;
export const NOTIFICATION_ICON_BRAND = `/v2-icons/notification-icon-brand.svg`;
export const APPROVED_KYC_ICON = `/v2-icons/approved-kyc-icon.svg`;
export const BET_SETTLEMENT_IN_REVIEW_ICON = `/v2-icons/bet-settlement-in-review-icon.svg`;
export const BET_SETTLEMENT_LOSS_ICON = `/v2-icons/bet-settlement-loss-icon.svg`;
export const BET_SETTLEMENT_PROCESS_BEGUN_ICON = `/v2-icons/bet-settlement-process-begun-icon.svg`;
export const BET_SETTLEMENT_WIN_ICON = `/v2-icons/bet-settlement-win-icon.svg`;
export const COMPLETED_KYC_ICON = `/v2-icons/completed-kyc-icon.svg`;
export const DENIED_KYC_ICON = `/v2-icons/denied-kyc-icon.svg`;
export const HOUSE_SETTLEMENT_BEING_VERIFIED_ICON = `/v2-icons/house-settlement-being-verified-icon.svg`;
export const HOUSE_SETTLEMENT_ICON = `/v2-icons/house-settlement-icon.svg`;
export const HOUSE_SETTLEMENT_IN_PROCESS_ICON = `/v2-icons/house-settlement-in-process-icon.svg`;
export const HOUSE_WITHDRAWAL_ICON = `/v2-icons/house-withdrawal-icon.svg`;
export const KYC_VERIFICATION_REMINDER_ICON = `/v2-icons/kyc-verification-reminder-icon.svg`;
export const REWARDS_RECEIPT_ICON = `/v2-icons/rewards-receipt-icon.svg`;
export const NOTIFICATION_CIRCLE_ICON = `/v2-icons/notification-circlebg-icon.svg`;
export const CONNECT_WALLET_IMG = `/v2-icons/connect-wallet-redirect.png`;
export const KEPLR_LOGO_LG = `/v2-icons/wallet-icon-keplr.png`;
export const BET_SETTLEMENT_REFUND_ICON = `/v2-icons/bet-settlement-refund-icon.svg`;
export const BET_PLACEMENT_FAIL_ICON = `/v2-icons/bet-placement-fail-icon.svg`;
export const KYC_RESUBMISSION_ICON = `/v2-icons/kyc-resubmission-icon.svg`;
