import { useCallback, useState } from 'react';
import { useAccount } from 'graz';
import { WALLET_ICON } from '#/constants/common/images.constants';
import { WalletIcon } from '../NavBar/NavBar.styled';
import ConnectWallet from '../OnboardingComponent/ConnectWallet/ConnectWallet.component';
import Button from '../common/Button';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import { setIsModalOpen } from '#/utils/slices/walletConnectionSlice';

const WalletBtn = `flex justify-center items-center rounded-md bg-card-bg-1 px-4 py-2 text-white font-normal text-sm xxxs:text-base h-8 xxxs:h-9`;

export const ConnectAllChainsWallet = () => {
  const isOpen = useAppSelector((state) => state.walletConnection.isModalOpen);
  const dispatch = useAppDispatch();

  const setIsOpen = useCallback((isModalOpen: boolean) => {
    dispatch(setIsModalOpen(isModalOpen));
  }, []);
  const { data: account } = useAccount();

  if (!account) {
    return (
      <>
        <Button className={WalletBtn} onClick={() => setIsOpen(true)}>
          <img className={WalletIcon} src={WALLET_ICON} alt="Wallet Icon" />
          <span>Connect</span>
        </Button>
        <ConnectWallet isOpen={isOpen} setOpen={setIsOpen} />
      </>
    );
  }

  return null;
};
