import type { ButtonHTMLAttributes, ReactNode } from 'react';

export type MaybeIcon = {
  children?: ReactNode;
  icon?: string;
  iconPosition?: 'left' | 'right';
  iconClass?: string;
};

export type OnlyIcon = {
  children?: never;
  icon: string;
  iconPosition?: never;
  iconClass?: string;
};

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isLoading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  gtmEventName?: string;
  onClick?: () => void;
} & (MaybeIcon | OnlyIcon);

export const ICON_POSITION: Record<'left' | 'right', string> = {
  left: '',
  right: 'flex-row-reverse',
};
