import { useCallback, useEffect, useState } from 'react';
import SportsFilter from '#/components/SportsFilter';
import ExploreMatchButton from '#/components/common/ExploreButton/ExploreButton.component';
import Search from '#/components/common/Search/Search.component';
import SearchResults from '#/components/common/Search/SearchResults';
import { SAMPLE_SPORTS_LIST } from '#/constants/common/global-constants';
import Filters from '../Filters/Filters.component';
import { toast } from 'react-toastify';
import { AppToast } from '../common/ToastNotification/ToastConfig';
import ScheduleFilters from '../common/ScheduleFilters';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import {
  removeLeague,
  setDateRange,
  setSearch,
} from '#/utils/slices/searchSlice';
import VerticalMatchList from '../VerticalMatchList';
import { FOOTBALL_ICON_BRAND } from '#/constants/common/images.constants';
import AppliedFilters from '../AppliedFiltersPanel';
import { ClickedSearch } from '../common/Search/SearchResults/SearchResults.types';
import { useSearchLoader } from '#/utils/dataLoaders/useSearchLoader';
import { format, addDays } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const mockSport = {
  selectedSport: {
    sport_uid: 'soccer',
  },
};

export const FILTER_TYPES = {
  ALL: 'all',
  TODAY: 'today',
  TOMORROW: 'tomorrow',
  UPCOMING: 'upcoming',
};

export default function Explore() {
  const [showFilters, setShowFilters] = useState(false);
  const dispatch = useAppDispatch();
  const searchQuery = useAppSelector((state) => state.search);

  const { isLoading, error, data } = useSearchLoader(searchQuery);

  useEffect(() => {
    // Set default filter to 'today' when component mounts
    if (
      !searchQuery.startsAfter &&
      !searchQuery.startsBefore &&
      !searchQuery.favouritesOf
    ) {
      const now = new Date();
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const zonedNow = toZonedTime(now, timezone);

      const formatInTimeZone = (date: Date, fmt: string, tz: string) =>
        format(toZonedTime(date, tz), fmt);

      dispatch(
        setDateRange({
          startsAfter:
            formatInTimeZone(zonedNow, "yyyy-MM-dd'T'00:00:00xxx", timezone) ||
            null,
          startsBefore:
            formatInTimeZone(
              addDays(zonedNow, 1),
              "yyyy-MM-dd'T'00:00:00xxx",
              timezone,
            ) || null,
        }),
      );
    }
  }, [
    dispatch,
    searchQuery.favouritesOf,
    searchQuery.startsAfter,
    searchQuery.startsBefore,
  ]);

  useEffect(() => {
    if (error) {
      toast.error(<AppToast id={'UnableToFetch'} />, { icon: false });
    }
  }, [error]);

  const filters = searchQuery.leagues.map((league) => ({
    id: league.Id,
    label: league.Name,
  }));

  const handleRemoveFilter = (id: number) => {
    dispatch(removeLeague(id));
  };

  const setSearchInput = useCallback(
    (input: string) => {
      dispatch(setSearch(input));
    },
    [dispatch],
  );

  return (
    <div>
      <div className="px-4 pb-4">
        <SportsFilter sportlist={SAMPLE_SPORTS_LIST} selectedSport={'soccer'} />
      </div>
      <div className={'px-4 pb-4'}>
        <ExploreMatchButton />
      </div>
      <div className={'px-4 pb-4'}>
        <div className="flex items-center mb-4">
          <Search
            search={searchQuery.search}
            setSearch={setSearchInput}
            showSearchResults={true}
            onClick={() => setShowFilters(!showFilters)}
          />
        </div>
        {searchQuery.search.length ? (
          <SearchResults
            searchInput={searchQuery.search}
            handleClick={(item: ClickedSearch) =>
              console.log('Clicked item:', item)
            }
            sportUid={mockSport?.selectedSport?.sport_uid}
          />
        ) : (
          <>
            <div className="pt-0">
              <ScheduleFilters />
            </div>
            <AppliedFilters
              filters={filters}
              onRemoveFilter={handleRemoveFilter}
            />
            <div className="pt-4">
              <VerticalMatchList
                icon={FOOTBALL_ICON_BRAND}
                data={data}
                isLoading={isLoading || error}
                id={'fixtures'}
              />
            </div>
          </>
        )}
      </div>
      {showFilters ? (
        <Filters isOpen={showFilters} onClose={() => setShowFilters(false)} />
      ) : null}
    </div>
  );
}
