import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import { setDateRange, setFavouritesFilter } from '#/utils/slices/searchSlice';
import { format, addDays } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import {
  FavIcon,
  SportListWrapper,
  TagFilterItems,
} from './ScheduleFilters.styled';
import { STAR_ICON } from '#/constants/common/images.constants';
import { useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export default function ScheduleFilters() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { startsAfter, startsBefore, favouritesOf } = useAppSelector(
    (state) => state.search,
  );
  const { user, isAuthenticated } = useAuth0();

  const applyFilter = useCallback(
    (filterType: string) => {
      const now = new Date();
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const zonedNow = toZonedTime(now, timezone);

      const formatInTimeZone = (date: Date, fmt: string, tz: string) =>
        format(toZonedTime(date, tz), fmt);

      switch (filterType) {
        case 'today':
          dispatch(setFavouritesFilter(null));
          dispatch(
            setDateRange({
              startsAfter: formatInTimeZone(
                zonedNow,
                "yyyy-MM-dd'T'00:00:00xxx",
                timezone,
              ),
              startsBefore: formatInTimeZone(
                addDays(zonedNow, 1),
                "yyyy-MM-dd'T'00:00:00xxx",
                timezone,
              ),
            }),
          );
          break;
        case 'tomorrow':
          dispatch(setFavouritesFilter(null));
          dispatch(
            setDateRange({
              startsAfter: formatInTimeZone(
                addDays(zonedNow, 1),
                "yyyy-MM-dd'T'00:00:00xxx",
                timezone,
              ),
              startsBefore: formatInTimeZone(
                addDays(zonedNow, 2),
                "yyyy-MM-dd'T'00:00:00xxx",
                timezone,
              ),
            }),
          );
          break;
        case 'upcoming':
          dispatch(setFavouritesFilter(null));
          dispatch(
            setDateRange({
              startsAfter: formatInTimeZone(
                addDays(zonedNow, 2),
                "yyyy-MM-dd'T'HH:mm:ssxxx",
                timezone,
              ),
              startsBefore: null,
            }),
          );
          break;
        case 'favourite':
          // Get user ID from auth
          if (isAuthenticated && user && user.sub) {
            dispatch(setDateRange({ startsAfter: null, startsBefore: null }));
            dispatch(setFavouritesFilter(user.sub));
          } else {
            // User not authenticated
            dispatch(setFavouritesFilter(null));
          }
          break;
        default:
          dispatch(setDateRange({ startsAfter: null, startsBefore: null }));
          dispatch(setFavouritesFilter(null));
      }

      searchParams.set('filter', filterType);
      setSearchParams(searchParams);
    },
    [dispatch, isAuthenticated, searchParams, setSearchParams, user],
  );

  useEffect(() => {
    const filterParam = searchParams.get('filter');
    if (filterParam) {
      applyFilter(filterParam);
    }
  }, [applyFilter, searchParams]);

  const getActiveFilter = () => {
    if (favouritesOf) return 'favourite';
    if (startsAfter && startsBefore) {
      const today = new Date();
      const tomorrow = addDays(today, 1);
      if (startsAfter.startsWith(format(today, 'yyyy-MM-dd'))) return 'today';
      if (startsAfter.startsWith(format(tomorrow, 'yyyy-MM-dd')))
        return 'tomorrow';
    }
    if (startsAfter && !startsBefore) return 'upcoming';
    return '';
  };

  const activeFilter = getActiveFilter();

  return (
    <div className={SportListWrapper}>
      <div
        className={`${TagFilterItems({
          isActive: activeFilter === 'favourite',
        })} flex-none`}
        onClick={() => applyFilter('favourite')}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            applyFilter('favourite');
          }
        }}
      >
        <img
          className={FavIcon}
          loading="lazy"
          src={STAR_ICON}
          alt={'Star Favourites'}
        />
      </div>
      <div
        className={TagFilterItems({
          isActive: activeFilter === 'today',
        })}
        onClick={() => applyFilter('today')}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            applyFilter('today');
          }
        }}
      >
        Today
      </div>
      <div
        className={TagFilterItems({
          isActive: activeFilter === 'tomorrow',
        })}
        onClick={() => applyFilter('tomorrow')}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            applyFilter('tomorrow');
          }
        }}
      >
        Tomorrow
      </div>
      <div
        className={TagFilterItems({
          isActive: activeFilter === 'upcoming',
        })}
        onClick={() => applyFilter('upcoming')}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            applyFilter('upcoming');
          }
        }}
      >
        Upcoming
      </div>
    </div>
  );
}
