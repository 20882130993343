import { AVATAR_IMG } from '#/constants/common/images.constants';
import {
  ContentContainer,
  FieldContainer,
  ButtonSaveWrapper,
  ButtonSave,
  Header,
  InputField,
  PageSubTitle,
  PageTitle,
  ProfileImageContainer,
  ProfileImg,
  ProfileImgWrapper,
  UploadBtn,
  UploadInput,
  WraperIsolate,
  SettingText,
  SettingItems,
  SelectField,
} from './EditProfile.styled';
import Button from '#/components/common/Button';

export default function EditProfile() {
  return (
    <>
      <div className="p-4">
        <div className={Header}>
          <h2 className={PageTitle}>Edit Profile</h2>
        </div>
        <div className={ContentContainer}>
          <div className="mb-3">
            <h3 className={PageSubTitle}>Profile image</h3>
          </div>
          <div className="flex items-center">
            <div className={ProfileImgWrapper}>
              <div className={ProfileImageContainer}>
                <img
                  className={ProfileImg}
                  loading="lazy"
                  src={AVATAR_IMG}
                  alt="" />
                <div className={WraperIsolate}>
                  <span className={UploadBtn}>
                    <input className={UploadInput} type="file" name="upload" />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <p className={PageSubTitle}>
                Your profile picture is public and visible to everyone. Choose
                your best photo!
              </p>
            </div>
          </div>
          <div className={FieldContainer}>
            <input
              type="text"
              name="username"
              id="username"
              className={InputField}
              placeholder="Username" />
            <div className={ButtonSaveWrapper}>
              <Button className={ButtonSave} id="save">
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4">
        <h2 className={SettingText}>General Settings</h2>
        <div className={SettingItems}>
          <span>Odds:</span><span className='font-bold ml-2'>
            <select className={SelectField}>
              <option selected>Decimal</option>
              <option selected>Fractional</option>
              <option selected>American</option>
            </select>
          </span>
        </div>
        <div className={SettingItems}>
          <span>Currency:</span><span className='font-bold ml-2'>USD</span>
        </div>
        <div className={SettingItems}>
          <span>Language:</span><span className='font-bold ml-2'>English</span>
        </div>
      </div>
    </>
  );
}
