import { useAccount, WalletType } from 'graz';
import useBalance from '#/hooks/useBalance';
import { convertToSge } from '#/utils/sgeUtils/sgeUtils';
import { getListedWallets } from '#/utils/graz';
import { WALLET_ICON } from '#/constants/common/images.constants';
import { DecimalText, WalletBtnIcon } from '../NavBar/NavBar.styled';
import { ConnectAllChainsWallet } from '#/components/graz/connect-wallet';
import Button from '../common/Button';
import WalletDetailsCard from '../WalletDetailsCard/WalletDetailsCard.component';
import { useState } from 'react';

const WalletBtn = `flex justify-center items-center rounded-md bg-card-bg-1 px-4 py-2 text-white font-normal text-sm xxxs:text-base h-8 xxxs:h-9`;

export function getWalletLogo(walletType?: WalletType): string {
  if (!walletType) return WALLET_ICON;
  const { allWallets } = getListedWallets();
  return (
    allWallets[walletType as keyof typeof allWallets]?.imgSrc || WALLET_ICON
  );
}

function WalletConnector() {
  const { isConnected } = useAccount();

  return <>{isConnected ? <UserWallet /> : <ConnectAllChainsWallet />}</>;
}

function UserWallet() {
  const { walletType } = useAccount();
  const { data: balance, isLoading } = useBalance();

  const [isOpen, setIsOpen] = useState(false);

  const balanceSge = convertToSge(balance?.amount.toString() || '0');
  const [integerPart, decimalPart = ''] = balanceSge.split('.');

  return (
    <Button
      onClick={() => setIsOpen(true)}
      className={`${WalletBtn} inline-flex items-center ${
        isLoading ? 'opacity-50' : ''
      }`}
      disabled={isLoading}
    >
      <img
        className={WalletBtnIcon}
        src={getWalletLogo(walletType)}
        alt="Wallet Icon"
      />
      {isLoading ? (
        <span>Loading...</span>
      ) : (
        <>
          <span>
            {integerPart}
            {decimalPart && '.'}
            <span className={DecimalText}>{decimalPart.slice(0, 4)}</span>
          </span>
          <span className="ml-1">SGE</span>
        </>
      )}
      <WalletDetailsCard isOpen={isOpen} setIsOpen={setIsOpen} />
    </Button>
  );
}

export default WalletConnector;
