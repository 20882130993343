import { useQuery } from '@tanstack/react-query';
import useAccessToken from './useAccessToken';
import useAuth from './useAuth';
import { MINUTE } from '../time';
import { useEffect, useRef } from 'react';
import { loadAuth0UserData } from '../backend';

const INTERVAL = MINUTE;

const useAuth0UserData = () => {
  const { data: user } = useAuth();
  const { data: accessToken } = useAccessToken();
  const initialDelayPassed = useRef(false);

  useEffect(() => {
    const id = setTimeout(() => {
      initialDelayPassed.current = true;
    }, INTERVAL);
    return () => {
      clearTimeout(id);
    };
  }, []);

  return useQuery({
    queryKey: ['auth0UserData', user?.sub],
    queryFn: async () => {
      // This returns the same data as useAuth so we don't want to send the request right away
      // It would also lead to auth0 rate-limiting us
      if (accessToken && initialDelayPassed.current === true) {
        return loadAuth0UserData(accessToken);
      } else {
        return user;
      }
    },
    retry: 3,
    refetchInterval: INTERVAL,
    refetchOnWindowFocus: true,
    enabled: !!user && !!accessToken,
  });
};

export default useAuth0UserData;
