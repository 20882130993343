import mocks from '#/mocks/mocks.json';
import env from './env';
import { DeliverTxResponse } from '@cosmjs/stargate';
import { QueryParams } from './slices/searchSlice';
import { ActivityOutcome } from './slices/activityFiltersSlice';
import { Big } from 'bigdecimal.js';
import { User } from '@auth0/auth0-react';
import { StatusType } from '#/components/common/ActivityCard/ActivityCard.types';
import { Tendermint37Client } from '@cosmjs/tendermint-rpc';
import { Event } from '@cosmjs/tendermint-rpc/build/tendermint37';
import * as Sentry from '@sentry/react';

export type FixtureStatus =
  | 'NotStarted'
  | 'InProgress'
  | 'Postponed'
  | 'Cancelled'
  | 'Ended';

export type MarketState =
  | 'SettingUp'
  | 'Operational'
  | 'Suspended'
  | 'UnmetConditions'
  | 'Aborted'
  | 'InPlay'
  | 'Settled';

export type MarketProvision = {
  TotalBets: number;
  TotalLiquidity: string;
  TotalBetAmount: string;
};

export type Continent =
  | 'International'
  | 'Africa'
  | 'Asia'
  | 'Europe'
  | 'NorthAmerica'
  | 'Oceania'
  | 'SouthAmerica';

export type Country = {
  id: number;
  iso: string;
  lsports_name: string;
  continent: Continent;
};

export type League = {
  Id: number;
  Name: string;
  HasOperationalMarkets: boolean;
  Country: Country;
};

export type Team = {
  Id: number;
  Name: string;
};

export type Teams = {
  Away: Team;
  Home: Team;
};

export type OutcomeMovement = 'Up' | 'Down' | 'NoChange';

export type Odds = {
  lastUpdated: string;
  marketId: number;
  marketTypeId: number;
  movement: OutcomeMovement;
  outcomeName: string;
  outcomePosition: number;
  value: string;
  acceptableBetSize: string;
};

export type FixtureSummary = {
  id: number;
  fixtureId: number;
  fixtureName: string;
  fixtureStatus: FixtureStatus;
  marketStatus: MarketState;
  marketTypeName: string;
  marketTypeId: number;
  startDate: string;
  sport: Sport;
  acceptableProvisionSize: string;
  houseProvision: MarketProvision;
  league: League;
  teams: Teams;
  odds: {
    [key: string]: Odds;
  };
};

export type HomepageData = {
  featured: FixtureSummary[];
  marketOfTheDay?: FixtureSummary;
  today: FixtureSummary[];
  trending: FixtureSummary[];
};

export type FixtureFilters = {
  leagues?: number[];
};

export type FixtureSearchQuery = {
  filters: FixtureFilters;
  offset?: number | null;
  search?: string | null;
};

export type FixtureSearchResults = {
  fixtures: FixtureSummary[];
  offset: number;
  total: number;
};

export type Transaction = {
  status: string;
  type: string;
  height: number;
  hash: string;
  createdAt: string;
};
export type Fixture = {
  id: number;
  league: League;
  markets: {
    [key: string]: Market[];
  };
  name: string;
  sport: Sport;
  startDate: string;
  status: FixtureStatus;
  teams: Teams;
};

export type Sport = 'Soccer' | 'Cricket';

export type Market = {
  betLine?: string | null;
  houseProvision: MarketProvision;
  id: number;
  marketTypeId: number;
  marketTypeName: string;
  odds: {
    [key: string]: Odds;
  };
  settlementOutcome?: number | null;
  status: MarketState;
};

export type BetState =
  | 'Submitted'
  | 'Active'
  | 'Settled'
  | 'Failed'
  | 'Refunded';

export type Bet = {
  activatedAt: string | undefined;
  fixtureId: string;
  fixtureName: string;
  id: string;
  odds: string;
  marketBetLine: string;
  marketId: number;
  marketTypeId: number;
  marketTypeName: string;
  outcomeId: string;
  outcomeName: string;
  result: string;
  stake: string;
  state: BetState;
  submittedAt: string;
  potentialWinnings: string;
};

export const isSettledBet = (bet: Bet): boolean => {
  return (
    bet.state === 'Settled' ||
    bet.state === 'Failed' ||
    bet.state === 'Refunded'
  );
};

export const isSuccessfulBet = (bet: Bet): boolean => {
  return bet.state === 'Settled';
};

export const isActiveBet = (bet: Bet): boolean => {
  return bet.state === 'Submitted' || bet.state === 'Active';
};

export const getStatusType = (bet: Bet): StatusType | undefined => {
  const successful = isSuccessfulBet(bet) || isActiveBet(bet);
  if (isSettledBet(bet)) {
    return successful ? 'success' : 'failure';
  }
  return successful ? 'success' : undefined;
};

export const getBetOutcome = (bet: Bet): ActivityOutcome | undefined => {
  if (bet.state === 'Settled') {
    const stake = Big(bet.stake);
    const winnings = Big(bet.potentialWinnings);

    if (winnings.greaterThan(stake)) {
      return 'Won';
    } else {
      return 'Lost';
    }
  }
};

export type BetsData = {
  bets: Bet[];
  total: number;
  offset: number;
};

export type HouseProvisionState = 'Active' | 'Settled' | 'Failed';

export type HouseProvision = {
  amount: string;
  createdAt: string;
  fixtureId: number;
  fixtureName: string;
  id: string;
  marketId: number;
  marketTypeId: number;
  marketTypeName: string;
  marketBetLine?: string;
  profitOrLoss?: string;
  state: HouseProvisionState;
  txHash: string;
  withdrawnAmount: string;
};

export type HouseProvisionsData = {
  provisions: HouseProvision[];
  total: number;
  offset: number;
};

export type NotificationsData = {
  notifications: Notification[];
  offset: number;
  remaining: number;
};

export type Notification = {
  id: number;
  ty: NotificationType;
  date: string;
  isRead: boolean,
  metadataJson: string | null;
};

export type NotificationType =
  | 'BetSettlementWin'
  | 'BetSettlementLoss'
  | 'BetSettlementRefund'
  | 'BetPlacementFail'
  | 'HouseSettlement'
  | 'KycReminder'
  | 'KycApproved'
  | 'KycRejected'
  | 'KycPendingVerification'
  | 'KycResubmission'
  | 'RewardsReceipt'

export type TransactionsData = {
  data: Transaction[];
  offset: number;
  total: number;
};

export type FixtureTab = {
  id: string;
  name: string;
};

export type OddData = {
  [key: string]: Odds;
};

export type TxnBcResponse = {
  jsonrpc: string;
  id: number;
  result: {
    txs: {
      hash: string;
      height: string;
      index: number;
      tx: string;
      tx_result: DeliverTxResponse;
    }[];
    total_count: number;
  };
};

export type KYCToken = {
  token: string;
};

export const stringToSport = (input: string): Sport | undefined => {
  switch (input.toLowerCase()) {
    case 'soccer':
      return 'Soccer';
    case 'football':
      return 'Soccer';
    case 'cricket':
      return 'Cricket';
  }
};

export type OutcomeLiveData = {
  value: string;
  movement: string;
  acceptable_bet_size: string;
};

export type MarketLiveData = {
  status: string;
  acceptable_provision_size: string;
};

type OutcomesResponse = {
  outcomes: Record<string, OutcomeLiveData>;
  markets: Record<string, MarketLiveData>;
};

export interface Outcome {
  marketId: number;
  marketTypeId: number;
  outcomePosition: number;
}

export const outcomeId = (outcome: Outcome): string => {
  return `${outcome.marketId}-${outcome.outcomePosition}`;
};

/**
 * Fetches data with error reporting via Sentry.
 * @param url The URL to fetch.
 * @param options Fetch options.
 * @returns The fetched data.
 */
async function fetchWithSentry<T>(
  url: string,
  options?: RequestInit,
): Promise<T> {
  try {
    const response = await fetch(url, options);
    return response.json();
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
}

export const loadFixtureData = async (id: string): Promise<Fixture> => {
  return fetchWithSentry<Fixture>(`${env.apiServer}/fixtures/${id}`);
};

export const searchTransaction = async (
  query: string,
): Promise<TxnBcResponse> => {
  return fetchWithSentry<TxnBcResponse>(
    `${env.chainInfo.rpc}tx_search?${query}`,
  );
};

export const loadLeagues = async (): Promise<League[]> => {
  return fetchWithSentry<League[]>(`${env.apiServer}/leagues`);
};

export const loadKycToken = async (accessToken: string): Promise<KYCToken> => {
  return fetchWithSentry<KYCToken>(`${env.apiServer}/kyc_token`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

export const loadFixtureSearchData = async (
  queryObject: QueryParams,
  offset: number,
  pageSize: number,
): Promise<FixtureSearchResults> => {
  // TODO: validation of queryObject

  const updatedQuery = {
    search: queryObject.search,
    filters: {
      leagues: queryObject.filters.leagueIds,
      sport: queryObject.sport ?? 'Soccer',
      starts_after: queryObject.startsAfter,
      starts_before: queryObject.startsBefore,
      favourites_of: queryObject.favouritesOf,
    },
    offset: offset,
    limit: pageSize,
  };

  // Remove undefined properties
  Object.keys(updatedQuery.filters).forEach(
    (key) =>
      updatedQuery.filters[key as keyof typeof updatedQuery.filters] ===
        undefined &&
      delete updatedQuery.filters[key as keyof typeof updatedQuery.filters],
  );

  const query = `${encodeURIComponent(JSON.stringify(updatedQuery))}`;
  return fetchWithSentry<FixtureSearchResults>(
    `${env.apiServer}/fixtures/search?query=${query}`,
  ).catch((error) => {
    console.error('Error loading fixture search data:', error);
    throw error;
  });
};

export const loadHomepageData = async (sport: Sport): Promise<HomepageData> => {
  try {
    return fetchWithSentry<HomepageData>(
      withTz(`${env.apiServer}/homepage/${sport}`),
    );
  } catch (error) {
    console.error('Error loading homepage data:', error);
    throw error;
  }
};

export const withTz = (url: string): string => {
  const now = new Date().getTimezoneOffset();
  if (url.indexOf('?') > 0) {
    return `${url}&tz=${now}`;
  } else {
    return `${url}?tz=${now}`;
  }
};

export const reloadOutcomes = async (
  outcomeIds: string[],
  marketIds: number[],
): Promise<OutcomesResponse> => {
  const query = `${encodeURIComponent(JSON.stringify({ outcome_ids: outcomeIds, market_ids: marketIds }))}`;
  return fetchWithSentry<OutcomesResponse>(
    `${env.apiServer}/get-updates?query=${query}`,
  );
};

export const loadTransactions = async (
  offset: number,
  pageSize: number,
  address?: string,
  queryClient?: Tendermint37Client,
): Promise<TransactionsData> => {
  if (address && queryClient) {
    const page = Math.floor(offset / pageSize) + 1;
    const options = { order_by: 'desc', page: page, per_page: pageSize };
    const senderTxs = await queryClient.txSearchAll({
      query: `message.sender='${address}'`,
      ...options,
    });
    const recipientTxs = await queryClient.txSearchAll({
      query: `transfer.recipient='${address}'`,
      ...options,
    });
    // Merge both sender and recipient transactions into one array
    const mergedList = Array.from(
      new Map(
        [...senderTxs.txs, ...recipientTxs.txs].map((transaction) => [
          `${transaction.hash}-${transaction.height}`,
          transaction,
        ]),
      ).values(),
      // Sort the merged list in descending order by 'height'
    ).sort((a, b) => +b.height - +a.height);
    const txnTimes = await Promise.all(
      mergedList.map((txn) => {
        return queryClient.block(txn.height);
      }),
    );
    const formattedList = mergedList.map((item) => {
      const transfer = item.result.events.find(
        (event) => event.type === 'transfer',
      );
      const type = getTransactionType(!transfer, address, item.result.events);
      const time = txnTimes.find(
        (event) => event.block.header.height === item.height,
      );
      return {
        status: type === 'Failed' ? 'Failed' : 'Success',
        type: type,
        height: item.height,
        hash: item.hash.toString(),
        createdAt: time ? time.block.header.time.toString() : 'N/A',
      };
    });
    return {
      data: formattedList,
      total: senderTxs.totalCount + recipientTxs.totalCount,
      offset: offset,
    };
  } else {
    throw Error('Connect your wallet first to see your transactions.');
  }
};

function getTransactionType(
  isFailed: boolean,
  userAddress: string,
  event: readonly Event[],
) {
  try {
    const msgTypeNames: { [prop: string]: string } = {
      '/cosmos.bank.v1beta1.MsgSend': 'Send Coin',
      '/cosmos.staking.v1beta1.MsgDelegate': 'Delegation',
      '/cosmos.staking.v1beta1.MsgUndelegate': 'Undelegation',
      '/cosmos.staking.v1beta1.MsgBeginRedelegate': 'Redelegation',
      '/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward':
        'Withdraw Reward',
      '/ibc.applications.transfer.v1.MsgTransfer': 'IBC Transfer',
      '/ibc.core.channel.v1.MsgAcknowledgement': 'IBC Acknowledgement',
      '/sgenetwork.sge.subaccount.MsgWithdrawUnlockedBalances':
        'Withdraw Reward',
      '/sgenetwork.sge.reward.MsgGrantReward': 'Reward Applied',
      'wasm-bet-submission': 'Bet Placement',
      'wasm-liquidity-provision': 'House Deposit',
      'wasm-withdraw-submission': 'House Withdrawn',
    };

    if (event?.length && !isFailed) {
      const message = event.find(
        (item1) => item1.type === 'message',
      )?.attributes;

      const actionName =
        message
          ?.find((item1) => item1.key.toString() === 'action')
          ?.value.toString() || '';
      if (actionName === '/cosmwasm.wasm.v1.MsgExecuteContract') {
        const contract = event.find((item1) => item1.type.includes('wasm'));
        if (contract?.type && msgTypeNames[contract.type]) {
          return msgTypeNames[contract.type];
        }
        return contract?.attributes.find(
          (item) => item.key.toString() === 'error_msg',
        )
          ? 'Failed'
          : 'N/A';
      }
      const name = message ? actionName : 'N/A';
      if (name === '/cosmos.bank.v1beta1.MsgSend') {
        const fromAddress = message
          ?.find((item1) => item1.key.toString() === 'sender')
          ?.value.toString();
        if (userAddress !== fromAddress) return 'Received Coin';
      }
      return msgTypeNames[name] || name;
    }
    return isFailed ? 'Failed' : 'N/A';
  } catch (err) {
    return 'Transaction';
  }
}

export const loadNotifications = async (
  accessToken: string,
  offset: number,
  tag: string,
): Promise<NotificationsData> => {
  const filter =
    {
      House: ['HouseSettlement'],
      Betting: ['BetSettlementWin', 'BetSettlementLoss', 'BetSettlementRefund', 'BetPlacementFail'],
      Other: ['KycReminder', 'KycApproved', 'KycRejected', 'KycPendingVerification', 'KycResubmission', 'RewardsReceipt'],
    }[tag];

  const query = `${encodeURIComponent(JSON.stringify({ offset, filter }))}`;
  return fetch(`${env.apiServer}/users/notifications?query=${query}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }).then((d) =>
    d.json(),
  );
};

export const loadBets = async (userId: string): Promise<Bet[]> => {
  let bets: Bet[] = [];
  let offset = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const betsData = await loadPageOfBets(userId, offset);
    bets = [...bets, ...betsData.bets];
    offset = bets.length;

    if (betsData.total <= bets.length) {
      break;
    }
  }

  return bets;
};

export const loadPageOfBets = async (
  userId: string,
  offset: number,
): Promise<BetsData> => {
  const query = `${encodeURIComponent(JSON.stringify({ offset }))}`;
  return fetchWithSentry<BetsData>(
    `${env.apiServer}/bets/${userId}?query=${query}`,
  );
};

export const loadLiquidityData = async (
  userId: string,
): Promise<HouseProvision[]> => {
  let houseProvisions: HouseProvision[] = [];
  let offset = 0;

  try {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const houseProvisionsData = await loadPageOfHouseProvisions(
        userId,
        offset,
      );

      houseProvisions = [...houseProvisions, ...houseProvisionsData.provisions];
      offset = houseProvisions.length;

      if (houseProvisionsData.total <= houseProvisions.length) {
        break;
      }
    }

    return houseProvisions;
  } catch (error) {
    console.error('Error loading liquidity data:', error);
    throw error;
  }
};

export const loadPageOfHouseProvisions = async (
  userId: string,
  offset: number,
): Promise<HouseProvisionsData> => {
  const query = `${encodeURIComponent(JSON.stringify({ offset }))}`;
  return fetchWithSentry<HouseProvisionsData>(
    `${env.apiServer}/provisions/${userId}?query=${query}`,
  );
};

export const deactivateUser = async (): Promise<{ response: string }> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ response: 'Account deactivated' });
    }, 1000);
  });
};

export type UserData = {
  id: string;
  kycSessionId?: string | null;
  kycStatus: KycStatus;
  walletAddr?: string | null;
};

export const updateUserTimeLimit = async (
  timeLimit: number,
): Promise<UserData> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const response = {
        ...mocks['/users/me'].post,
        timeLimit,
      } as unknown as BackendUserData;
      resolve(response);
    }, 1000);
  });
};

export type KycStatus =
  | 'APPROVED'
  | 'REJECTED'
  | 'RESUBMISSION_REQUIRED'
  | 'SUBMISSION_REQUIRED'
  | 'PENDING_VERIFICATION';

export type BackendUserData = {
  id: string;
  kycSessionId?: string | null;
  kycStatus: KycStatus;
  walletAddr?: string | null;
};

export const loadBackendUserData = async (
  accessToken: string,
): Promise<BackendUserData> => {
  return fetchWithSentry<BackendUserData>(`${env.apiServer}/users/me`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
};

export const loadAuth0UserData = async (accessToken: string): Promise<User> => {
  return fetchWithSentry<User>(`https://${env.auth0.domain}/userinfo`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
};

export const addFavouriteFixture = async (
  fixtureId: number,
  accessToken: string,
): Promise<void> => {
  await fetchWithSentry<void>(`${env.apiServer}/favourites/${fixtureId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const removeFavouriteFixture = async (
  fixtureId: number,
  accessToken: string,
): Promise<void> => {
  await fetchWithSentry<void>(`${env.apiServer}/favourites/${fixtureId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getFavouriteFixtures = async (
  accessToken: string,
  ids?: number[],
): Promise<number[]> => {
  const query = ids ? JSON.stringify({ ids }) : 'null';
  return fetchWithSentry<number[]>(
    `${env.apiServer}/favourites?query=${encodeURIComponent(query)}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};
