import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BetSlips, BetSlip } from '../Slip';
import * as localStorageUtils from '../localStorage';
import { stringToBigdecimal } from '../bigdecimal';
import { toast } from 'react-toastify';
import { MAX_SLIP_LENGTH } from '#/constants/common/global-constants';
import { AppToast } from '#/components/common/ToastNotification/ToastConfig';

const initialState: BetSlips = {};

const slice = createSlice({
  name: 'betSlips',
  initialState,
  reducers: {
    updateAmount: (
      state,
      data: PayloadAction<{ outcomeId: string; amount: string }>,
    ) => {
      if (state[data.payload.outcomeId]) {
        const parsedAmount = stringToBigdecimal(data.payload.amount);
        const amount = parsedAmount ? parsedAmount.toString() : '0';

        state[data.payload.outcomeId] = {
          ...state[data.payload.outcomeId],
          amount,
        };

        localStorageUtils.setBetSlips(state);
      }
    },
    updateAcceptedRate: (
      state,
      data: PayloadAction<{ outcomeId: string; acceptedRate: string }>,
    ) => {
      if (state[data.payload.outcomeId]) {
        const parsedRate = stringToBigdecimal(data.payload.acceptedRate);
        const newAcceptedRate = parsedRate ? parsedRate.toString() : '0';

        const newOutcome = {
          ...state[data.payload.outcomeId].slip.outcome,
          acceptedRate: newAcceptedRate,
        };

        state[data.payload.outcomeId] = {
          ...state[data.payload.outcomeId],
          slip: {
            ...state[data.payload.outcomeId].slip,
            outcome: newOutcome,
          },
        };

        localStorageUtils.setBetSlips(state);
      }
    },
    remove: (state, data: PayloadAction<string>) => {
      delete state[data.payload];

      localStorageUtils.setBetSlips(state);
    },
    selectOrDeselect: (state, data: PayloadAction<BetSlip>) => {
      if (
        Object.keys(state).length >= MAX_SLIP_LENGTH &&
        !state[data.payload.outcome.id]
      ) {
        toast.error(AppToast({ id: 'BetslipFull' }), { icon: false });
        return;
      }
      state[data.payload.outcome.id]
        ? delete state[data.payload.outcome.id]
        : (state[data.payload.outcome.id] = {
            amount: '0',
            slip: data.payload,
          });

      localStorageUtils.setBetSlips(state);
    },
    set: (state, slips: PayloadAction<BetSlips>) => {
      Object.keys(slips.payload).forEach((outcomeId) => {
        const parsedAmount = stringToBigdecimal(
          slips.payload[outcomeId].amount,
        );
        const amount = parsedAmount ? parsedAmount.toString() : '0';

        state[outcomeId] = {
          ...slips.payload[outcomeId],
          amount,
        };
      });
    },
    clear: (state) => {
      Object.keys(state).forEach((oddsId) => {
        delete state[oddsId];
      });

      localStorageUtils.setBetSlips(state);
    },
  },
});

export const {
  remove,
  selectOrDeselect,
  updateAmount,
  updateAcceptedRate,
  set,
  clear,
} = slice.actions;
export default slice.reducer;
