import { FILTER_ICON_LIGHT } from '#/constants/common/images.constants';

const ExploreIcon = `w-6 mr-2`;
export default function FilterButton({ onClick }: { onClick: () => void }) {
  return (
    <div
      className="inline-block ml-2"
      id="filter_button"
      onClick={onClick}
      role="button"
      onKeyDown={onClick}
      tabIndex={0}
    >
      <img src={FILTER_ICON_LIGHT} className={ExploreIcon} alt="Filter Icon" />
    </div>
  );
}
