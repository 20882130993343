/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import { useVirtualizer, useWindowVirtualizer } from '@tanstack/react-virtual';
import { useEffect, useRef } from 'react';
import {
  ESTIMATE_SIZE,
  ITEMS_VIEW_CONSTANT,
  OVER_SCAN,
  VIRTUALIZER_HEIGHT,
} from './Virtualizer.constants';
import {
  VirtualizerWrapper,
  VirtualizerItemContainer,
  VirtualizerItemStyled,
} from './Virtualizer.styled';
import { useInfiniteQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { AppToast } from '../ToastNotification/ToastConfig';

type VirtualiserProps<T> = {
  title: string;
  ChildComponent: React.FunctionComponent<any>;
  getProps: (item: T) => Record<string, any>;
  query: {
    queryKey: string[];
    queryFn: (ctx: any) => Promise<any>;
    getNextPageParam: (lastGroup: any) => any;
    initialPageParam: number;
  };
  LoadingComponent: React.FunctionComponent;
  HeaderComponent: React.FunctionComponent<any>;
  isWindowRef: boolean;
  itemsViewConstant?: number;
  overScan?: number;
  estimateSize?: number;
  virtualizerHeight?: string;
};

export default function VirtualizerContainer<T>({
  ChildComponent,
  getProps,
  query,
  LoadingComponent,
  HeaderComponent,
  isWindowRef,
  estimateSize,
  overScan,
  itemsViewConstant,
  virtualizerHeight,
}: VirtualiserProps<T>) {
  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    isRefetching,
  } = useInfiniteQuery(query);

  useEffect(() => {
    refetch();
  }, [query]);

  useEffect(() => {
    if (error?.message) {
      toast.error(<AppToast id={'UnableToFetch'} />, { icon: false });
    }
  }, [error?.message]);

  const allRows = data ? data.pages.flatMap((d) => d.rows) : [];

  const parentRef = useRef<HTMLDivElement | null>(null);

  const virtualizer = isWindowRef
    ? useWindowVirtualizer({
        count: hasNextPage ? allRows.length + 1 : allRows.length,
        estimateSize: () => estimateSize || ESTIMATE_SIZE,
        scrollMargin: parentRef.current?.offsetTop ?? 0,
      })
    : useVirtualizer({
        count: hasNextPage ? allRows.length + 1 : allRows.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => estimateSize || ESTIMATE_SIZE,
        overscan: overScan || OVER_SCAN,
      });

  useEffect(() => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= allRows.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasNextPage,
    fetchNextPage,
    allRows.length,
    isFetchingNextPage,
    virtualizer.getVirtualItems(),
  ]);

  return status === 'pending' || isRefetching || status === 'error' ? (
    <>
      <LoadingComponent />
      <LoadingComponent />
      <LoadingComponent />
      <LoadingComponent />
      <LoadingComponent />
    </>
  ) : (
    <div>
      <HeaderComponent totalItems={data.pages[0].total} />
      <div
        className={VirtualizerWrapper}
        ref={parentRef}
        style={
          isWindowRef
            ? {}
            : {
                height:
                  allRows.length < (itemsViewConstant || ITEMS_VIEW_CONSTANT)
                    ? `fit-content`
                    : virtualizerHeight || VIRTUALIZER_HEIGHT,
                overflow: 'auto',
              }
        }
      >
        <div
          className={`${VirtualizerItemContainer} virtualItem`}
          style={{
            height: virtualizer.getTotalSize(),
          }}
        >
          {virtualizer.getVirtualItems().map((item) => {
            const isLoaderRow = item.index > allRows.length - 1;
            const content = allRows[item.index];
            return (
              <div
                key={item.key}
                className={VirtualizerItemStyled}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${item.size}px`,
                  transform: `translateY(${
                    isWindowRef
                      ? item.start - virtualizer.options.scrollMargin
                      : item.start
                  }px)`,
                }}
              >
                <div
                  className={VirtualizerItemStyled}
                  key={item.key}
                  data-index={item.index}
                  ref={virtualizer.measureElement}
                >
                  {isLoaderRow ? (
                    <LoadingComponent />
                  ) : (
                    <ChildComponent {...getProps(content)} />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
