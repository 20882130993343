import { useQuery } from '@tanstack/react-query';
import { loadBets } from '../backend';

const useBetsDataLoader = (userId: string | null, refetchInterval?: number) => {
  return useQuery({
    queryKey: ['betsDataLoader', userId],
    enabled: !!userId,
    queryFn: () => {
      if (userId) {
        return loadBets(userId);
      }
      return null;
    },
    refetchInterval: refetchInterval ? refetchInterval : false, // Polling based on interval, disabled by default
  });
};

export default useBetsDataLoader;
