import { ButtonText, ButtonWrapper } from './ExploreButton.styled';
import Link from '../Link.component';

export const MATCH_ROUTE = '/match/:id';

export default function ExploreMatchButton() {
  return (
    <Link className={ButtonWrapper} to={MATCH_ROUTE} id="explore_all_matches">
      <span className={ButtonText}>Explore all Matches</span>
    </Link>
  );
}
