/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useRef } from 'react';
import {
  DEFAULT_ODD_TYPE,
  MAX_CAP_TOOLTIP_MSG,
} from '#/constants/common/global-constants';
import {
  ArrowIcon,
  OddValueFlexText,
  OddValueTextHovered,
  ToolTipContents,
  ToolTipWrapper,
} from './OddButton.styled';
import ActionTag from '../../ActionTags.component';

interface OddButtonTypeProps {
  value: string;
  isVisible: boolean;
  onClick: () => void;
  oddsType: string;
  extendedValue: string;
  name: string;
  oddContainerStyle: string;
  oddLabelStyle: string;
  OddValueStyledMobile: string;
  containerId?: string;
  withLabel: boolean;
  icon: string;
}

export default function OddButton({
  value,
  isVisible,
  onClick,
  oddsType,
  extendedValue,
  name,
  oddContainerStyle,
  oddLabelStyle,
  OddValueStyledMobile,
  containerId = '',
  withLabel,
  icon,
}: OddButtonTypeProps) {
  const valueRef = useRef(value);
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    if (value !== valueRef.current) {
      setIsChanged(true);
    }
  }, [value]);

  return (
    <div className={oddContainerStyle} id={containerId}>
      <div className={ToolTipWrapper({})}>
        {!isVisible ? (
          <span className={ToolTipContents}>{MAX_CAP_TOOLTIP_MSG}</span>
        ) : null}{' '}
        <ActionTag
          type="div"
          className={OddValueStyledMobile}
          onClick={onClick}
          id="odd_value_click"
        >
          {withLabel ? <div className={oddLabelStyle}>{name}</div> : null}
          <div className="flex">
            <span className={OddValueFlexText}>
              <img src={icon} className={ArrowIcon} alt="Arrow Icon" />
              {value}
            </span>
            {DEFAULT_ODD_TYPE.key === oddsType ? (
              <span className={OddValueTextHovered}>
                {extendedValue.slice(-2)}
              </span>
            ) : null}
          </div>
        </ActionTag>
      </div>
    </div>
  );
}
