/* eslint-disable import/extensions */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import timezonePlugin from 'dayjs/plugin/timezone.js';
import { TIME_UNITS, MILLISECONDS_IN } from './datetime.constants';

dayjs.extend(utc);
dayjs.extend(timezonePlugin);

/* add test case later for all function */

/** get time zone */
export function getDayjsTimeZone() {
  return dayjs.tz.guess();
}

/** returns current time in unix  */
export function getCurrentTimeUnix() {
  return dayjs().utc().valueOf();
}

export function getCurrentLocalUnix() {
  return dayjs().unix();
}

/** returns timestamp of day end */
export function getEndOfDayUnix() {
  return dayjs
    .tz(dayjs().endOf(TIME_UNITS.DAY), getDayjsTimeZone())
    .utc()
    .valueOf();
}

/** returns a time stamp of x days after or before */
function action(day: number, doCal: string) {
  if (doCal === 'add')
    return dayjs
      .tz(dayjs().add(day, TIME_UNITS.DAY), getDayjsTimeZone())
      .utc()
      .valueOf();
  if (doCal === 'sub')
    return dayjs
      .tz(dayjs().subtract(day, TIME_UNITS.DAY), getDayjsTimeZone())
      .millisecond();
  return dayjs.tz(dayjs(day)).utc().valueOf();
}

/** add number of days */
export function addDaysUnix(day: number) {
  return action(day, 'add');
}

/** Subtracts number of days */
export function subtractDaysUnix(day: number) {
  return action(day, 'sub');
}

/** Get starting timestamp of day */
export function getStartOf(timestamp: number) {
  return dayjs(timestamp).startOf(TIME_UNITS.DAY).utc().valueOf();
}

/** Get end timestamp of day */
export function getEndOf(timestamp: number) {
  return dayjs(timestamp).endOf(TIME_UNITS.DAY).utc().valueOf();
}

/** Returns Object for chaining functions */
export function getDayjsObject(timestamp: number) {
  return dayjs(timestamp);
}

export function formatDate(timestamp: number, format: string) {
  return dayjs.unix(timestamp).format(format);
}

export function formatDateObject(date: number | string, format: string) {
  return dayjs(date).format(format);
}

export function getStartOfDayInTimezone(
  utcTime: number = Date.now(),
  timezoneOffset = 0,
) {
  const dateInTimezone = dayjs.utc(utcTime).utcOffset(timezoneOffset); // convert UTC time to the given timezone
  return dateInTimezone.startOf(TIME_UNITS.DAY).utc().valueOf();
}

export function getEndOfDayInTimezone(
  utcTime: number = Date.now(),
  timezoneOffset = 0,
) {
  const dateInTimezone = dayjs.utc(utcTime).utcOffset(timezoneOffset); // convert UTC time to the given timezone
  const endOfDayInTimezone = dateInTimezone.endOf(TIME_UNITS.DAY);
  const endOfDayMinusOneSecond = endOfDayInTimezone.subtract(1, 'second'); // subtract one second
  return endOfDayMinusOneSecond.utc().valueOf();
}

export function convertToEpochSeconds(epochInMilliseconds: number) {
  return Math.ceil(epochInMilliseconds / MILLISECONDS_IN.SECOND);
}
// Do not use this function directly. Use timezone from app config.
export function getTimeZoneOffset() {
  return new Date().getTimezoneOffset();
}

export function getTimeIntervalMilliSeconds(timestamp: number) {
  const currentTime = dayjs().unix();
  return (
    Math.abs(dayjs(timestamp).diff(currentTime, TIME_UNITS.MILLISECOND)) * 1000
  );
}

export function getTimeInterval(timestamp: number, format?: string) {
  const currentTime = dayjs().unix();
  const epochTimeDifference =
    Math.abs(dayjs(timestamp).diff(currentTime, TIME_UNITS.MILLISECOND)) * 1000;
  if (epochTimeDifference > MILLISECONDS_IN.DAY) {
    if (format) {
      formatDate(timestamp, format);
    }
    return `${Math.floor(epochTimeDifference / MILLISECONDS_IN.DAY)} days`;
  }
  if (epochTimeDifference > MILLISECONDS_IN.HOUR) {
    return `${Math.floor(epochTimeDifference / MILLISECONDS_IN.HOUR)} hours`;
  }
  if (epochTimeDifference > MILLISECONDS_IN.MINUTE) {
    return `${Math.floor(
      epochTimeDifference / MILLISECONDS_IN.MINUTE,
    )} minutes`;
  }
  return `${Math.floor(epochTimeDifference / MILLISECONDS_IN.SECOND)} seconds`;
}

export function getMaxAge(timestamp: number) {
  const currenttime = dayjs().unix();
  if (timestamp < currenttime) {
    return 0;
  }
  return dayjs(timestamp).diff(currenttime, TIME_UNITS.MILLISECOND);
}

export function timeAgo(dateString: string): string {
  const now = new Date();
  const past = new Date(dateString);
  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);

  const seconds = diffInSeconds;
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30); // Approximation for a month
  const years = Math.floor(days / 365); // Approximation for a year

  if (seconds < 60) {
    return `${seconds}s ago`;
  } else if (minutes < 60) {
    return `${minutes}m ago`;
  } else if (hours < 24) {
    return `${hours}h ago`;
  } else if (days < 7) {
    return `${days}d ago`;
  } else if (weeks < 4) {
    return `${weeks}w ago`;
  } else if (months < 12) {
    return `${months}m ago`;
  } else {
    return `${years}y ago`;
  }
}

export function getMaxAgeDiff(timestamp: number) {
  // Get the current timestamp in milliseconds
  const currentTimestamp = dayjs().valueOf();
  const timestampMaxAge = timestamp * 1000;
  // Calculate the difference in milliseconds
  return timestampMaxAge - currentTimestamp;
}

export function getCurrentLocalTimeUnix() {
  return dayjs().unix();
}

export function getDurationInSeconds(value: number, type: TIME_UNITS) {
  switch (type) {
    case TIME_UNITS.SECOND:
      return value * MILLISECONDS_IN.SECOND;
    case TIME_UNITS.MINUTE:
      return value * MILLISECONDS_IN.MINUTE;
    case TIME_UNITS.HOUR:
      return value * MILLISECONDS_IN.HOUR;
    case TIME_UNITS.DAY:
      return value * MILLISECONDS_IN.DAY;
    case TIME_UNITS.WEEK:
      return value * 7 * MILLISECONDS_IN.DAY;
    case TIME_UNITS.MONTH:
      return value * 30 * MILLISECONDS_IN.DAY;
    case TIME_UNITS.YEAR:
      return value * 365 * MILLISECONDS_IN.DAY;
    default: {
      return 0;
    }
  }
}

// duration in millisecond
export function addDurationToTimestampUnix(duration: number) {
  return dayjs().add(duration, TIME_UNITS.MILLISECOND).unix();
}

export function getFormatedTZtime(
  utcTime: number,
  timezoneOffset: number,
  formatString: string,
) {
  return dayjs.utc(utcTime).utcOffset(timezoneOffset).format(formatString);
}

/**
 * Formats the given UTC timestamp into the desired format.
 * @param utcTimestamp - The UTC timestamp to format.
 * @param format - The desired date format string. Defaults to 'MMM D | HH:mm A'.
 * @param timezone - The timezone to use. Defaults to the result of getDayjsTimeZone().
 * @returns The formatted date string.
 */
export function formatDateToDesiredFormat(
  utcTimestamp: string,
  format = 'MMM D YYYY | HH:mm A',
  timezone = getDayjsTimeZone(),
): string {
  return dayjs.utc(utcTimestamp).tz(timezone).format(format);
}
