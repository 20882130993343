/* eslint-disable @typescript-eslint/no-unused-vars */
import { DEFAULT_ODD_TYPE } from '#/constants/common/global-constants';
import {
  MainMatchContainer,
  MainMatchTitle,
  MatchHeaderWrapper,
  MarketText,
  LeaguesWrapper,
  LeagueName,
  MarketOddsBtn,
  TeamName,
  OddsValue,
  MarketOddsWrapper,
  MatchTitleWrapper,
  MarketIcon,
  WhiteOddsValue,
  WhiteTeamName,
  WrapperForDesktop,
} from './MainMarketCard.styled';
import { FixtureSummary, Odds, outcomeId } from '#/utils/backend';
import {
  MARKET_ARROW_ICON,
  ODDS_DOWN_ARROW,
  ODDS_UP_ARROW,
} from '#/constants/common/images.constants';
import OddButton from '#/components/common/Buttons/OddButton/OddButton.component';
import { getOutcomeName } from '#/utils/marketsMap';
import { getDisplayOddValue } from '#/utils/appUtils';
import { formatDateToDesiredFormat } from '#/utils/datetime/datetime';
import { DATE_TIME_FORMATS } from '#/utils/datetime/datetime.constants';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import { selectOrDeselect } from '#/utils/slices/betSlipsSlice';
import { isOddSelected } from '#/components/SlipsDrawers/SlipsDrawer.utils';

type MainMarketProp = {
  mainMarket: FixtureSummary;
  oddType?: number;
};

export default function MainMarketCard({
  mainMarket,
  oddType,
}: MainMarketProp) {
  const dispatch = useAppDispatch();
  const outcomes = useAppSelector((state) => state.outcomes.outcomes);
  const betSlips = useAppSelector((state) => state.betSlips);

  function addBetSlip(market: FixtureSummary, odd: Odds) {
    dispatch(
      selectOrDeselect({
        fixture: {
          id: market.fixtureId.toString(),
        },
        market: {
          id: market.id,
          name: market.marketTypeName,
          marketTypeId: market.marketTypeId,
        },
        outcome: {
          id: outcomeId(odd),
          position: odd.outcomePosition,
          name: odd.outcomeName,
          acceptedRate: odd.value,
        },
        homeTeam: {
          name: market.teams.Home.Name,
        },
        awayTeam: {
          name: market.teams.Away.Name,
        },
        slippageParams: {
          slippage: '0',
          odds: odd.value,
        },
        betProvisions: {
          acceptableBetSize: odd.acceptableBetSize
        }
      }),
    );
  }
  return (
    <div className={MainMatchContainer}>
      <div className={WrapperForDesktop}>
        <div className={MatchHeaderWrapper}>
          <div className='lg:hidden'>
            <div className={MatchTitleWrapper}>
              <span>
                <img className={MarketIcon} src={MARKET_ARROW_ICON} alt="Icon" />
              </span>
              <div className={`${MarketText} animateText`}>Market</div>
            </div>
            <div className={`${MainMatchTitle} animateText`}>of the Day</div>
          </div>

          <div className='hidden lg:block mt-4'>
            <div className={MatchTitleWrapper}>
              <span>
                <img className={MarketIcon} src={MARKET_ARROW_ICON} alt="Icon" />
              </span>
              <div className={`${MarketText} animateText`}>Market of the Day</div>
            </div>
          </div>
        </div>
        <div className={LeaguesWrapper}>
          <p className={LeagueName}>{mainMarket.league.Name}</p>
          <p className={LeagueName}>
            {formatDateToDesiredFormat(
              mainMarket.startDate,
              DATE_TIME_FORMATS.DATE_TIME_2,
            )}
          </p>
        </div>
      </div>
      <div className={MarketOddsWrapper}>
        {Object.keys(mainMarket.odds)
          .filter((_, index) => index < 3)
          .map((oddKey, index) => {
            const odd = mainMarket.odds[oddKey];
            const outcomeData = outcomes[outcomeId(odd)];

            const value = outcomeData?.value || '-';
            const movement = outcomeData?.movement || 'Down';
            const isSelected = isOddSelected(betSlips, odd);
            return (
              <OddButton
                key={odd.outcomePosition}
                value={getDisplayOddValue({
                  oddsType: String(oddType) || DEFAULT_ODD_TYPE.key,
                  oddValues: { decimal: value, american: '', fractional: '' },
                })}
                extendedValue={getDisplayOddValue({
                  oddsType: String(oddType) || DEFAULT_ODD_TYPE.key,
                  oddValues: {
                    decimal: odd.value,
                    american: '',
                    fractional: '',
                  },
                  extended: true,
                })}
                isVisible={true}
                onClick={() => addBetSlip(mainMarket, odd)}
                oddsType={String(oddType) || DEFAULT_ODD_TYPE.key}
                name={
                  getOutcomeName(
                    odd.marketTypeId,
                    odd.outcomePosition,
                    mainMarket.teams.Home.Name,
                    mainMarket.teams.Away.Name,
                  ) || odd.outcomeName
                }
                oddContainerStyle={MarketOddsBtn(isSelected)}
                oddLabelStyle={index === 1 ? WhiteTeamName : TeamName}
                withLabel={true}
                OddValueStyledMobile={index === 1 ? WhiteOddsValue : OddsValue}
                icon={
                  movement
                    ? movement === 'Up'
                      ? ODDS_UP_ARROW
                      : ODDS_DOWN_ARROW
                    : ''
                }
              />
            );
          })}
      </div>
    </div>
  );
}
