import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SigningStargateClient } from '@cosmjs/stargate';
import { SigningCosmWasmClient } from '@cosmjs/cosmwasm-stargate';

interface State {
  queryClient: SigningStargateClient | undefined;
  txClient: SigningCosmWasmClient | undefined;
}

const initialState: State = {
  queryClient: undefined,
  txClient: undefined,
};

const slice = createSlice({
  name: 'chainClients',
  initialState,
  reducers: {
    setQueryClient: (
      state,
      data: PayloadAction<SigningStargateClient | undefined>,
    ) => {
      state.queryClient = data.payload;
    },
    setTxClient: (
      state,
      data: PayloadAction<SigningCosmWasmClient | undefined>,
    ) => {
      state.txClient = data.payload;
    },
    clear: (state) => {
      state.queryClient = undefined;
      state.txClient = undefined;
    },
  },
});

export const { setQueryClient, setTxClient, clear } = slice.actions;
export default slice.reducer;
