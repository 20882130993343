/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { CardHeader, BetCount, BetCountText } from './style';
import { ARROW_DOWN, ARROW_UP } from '#/constants/common/images.constants';
import AnimatedModal from '../common/AnimatedModal.component';

interface SlipsDrawerProps {
  count: number;
  heading: string;
  index: number;
  isCollapsed: boolean;
  onToggle: (index: number) => void;
  children: React.ReactNode;
}
const SlipsDrawer = ({
  count,
  heading,
  index,
  isCollapsed,
  onToggle,
  children,
}: SlipsDrawerProps) => {
  const toggleCollapse = () => {
    onToggle(index);
  };

  const title = (
    <div className={CardHeader} onClick={toggleCollapse}>
      <div className="flex items-center">
        <span className={BetCount}>{count}</span>
        <span className={BetCountText}>{heading}</span>
      </div>
      <div>
        <img
          src={isCollapsed ? ARROW_DOWN : ARROW_UP}
          alt={isCollapsed ? 'Expand' : 'Collapse'}
          className="w-4"
        />
      </div>
    </div>
  );

  return (
    <>
      {!isCollapsed ? (
        <div>{title}</div>
      ) : (
        <AnimatedModal
          title={title}
          isOpen={true}
          onClose={() => toggleCollapse()}
          showCloseIcon={false}
          backgroundClassName="bg-primary-bg-3"
          headerHasPadding={true}
        >
          <div className="w-full">{children}</div>
        </AnimatedModal>
      )}
    </>
  );
};

export default SlipsDrawer;
