/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
interface LinkPropTypes {
  id?: string;
  className?: string;
  onClick: (prop: any) => void;
  key?: string;
  width?: string;
  height?: string;
  loading?: string;
  src: string;
  alt?: string;
}

export default function ImgActionTag({
  id = 'action_tag_click',
  className,
  onClick,
  key = '',
  width = '',
  height = '',
  loading,
  src,
  alt = '',
}: LinkPropTypes) {
  return (
    <img
      className={className}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
      id="action-tag-link"
      key={key}
      data-testid={id}
      width={width}
      height={height}
      loading={loading as any}
      src={src}
      alt={alt}
    />
  );
}
