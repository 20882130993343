import { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

const ModalContainer = `fixed top-0 left-0 block w-full h-full outline-none overflow-x-hidden overflow-y-auto bg-white-82 bg-opacity-50 z-10 lg:bg-black lg:bg-opacity-[0.8]`;
const ModalDialog = `mx-auto relative w-auto pointer-events-none max-w-md h-full flex place-items-center`;
const ModalContent = `relative sm:relative w-full pointer-events-auto bg-primary-bg-1 lg:bg-primary-bg-4 overflow-hidden bg-clip-padding rounded-2xl mx-4`;

const CenterModal = ({
  isOpen,
  children,
}: {
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div className={ModalContainer}>
      <div className={ModalDialog}>
        <div className={ModalContent}>{children}</div>
      </div>
    </div>,
    document.getElementById('portal-root')!,
  );
};

export default CenterModal;
