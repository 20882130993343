export interface BetOddSelected {
  $isSelected?: boolean;
  $hasCapacityReached?: boolean;
  $isUpdated?: boolean;
  $isMainMarketCard: boolean;
}
interface ToolTipContainerProp {
  $noCursor?: boolean;
}
export const OddValueStyled = ({
  $isSelected,
  $hasCapacityReached,
  $isUpdated,
  $isMainMarketCard,
}: BetOddSelected) => `group lg:w-16.5 4xl:w-24 5xl:w-32 6xl:w-36 px-2 md:px-4 py-1.5 5xl:px-8 5xl:py-1.5 flex justify-center text-xs 2xl:text-sm 3xl:text-base 4xl:text-lg 5xl:text-2xl 6xl:text-3xl bg-card-bg-2 lg:bg-transparent border-0 lg:border border-solid border-white lg:rounded-full text-text-gray-2 font-semibold lg:font-normal text-center mr-0 xl:mr-2
      ${$isSelected
    ? ' !bg-success-bg-2 text-white lg:text-text-gray-1 lg:!bg-success-bg-1'
    : ''
  }
        ${$hasCapacityReached
    ? ' pointer-events-none bg-card-bg-2 text-white lg:!opacity-25 lg:!text-black lg:!bg-text-gray-2'
    : ''
  }
        ${$hasCapacityReached
    ? ' !bg-card-bg-2 !text-white lg:opacity-25 lg:text-black lg:bg-text-gray-2'
    : ''
  }
        ${' !bg-transparent pointer-events-none cursor-none border-none'}
        ${$isUpdated ? ' !animate-flash' : ''}
        ${$isSelected && $isMainMarketCard
    ? ' !border border-solid !border-border-bg-2'
    : ''
  }
        ${$isMainMarketCard
    ? ' !border border-solid !border-border-bg-2 !px-3'
    : ''
  }
        ${$isMainMarketCard
    ? ' 5xl:w-36 6xl:w-48 px-1 md:px-4 py-1.5 5xl:px-8 5xl:py-1.5 6xl:py-4 flex justify-center bg-transparent border border-solid lg:border-white lg:rounded-full text-white font-medium lg:font-normal text-center mr-0 xl:mr-2 7xl:mr-4'
    : ''
  }`;
export const OddValueText = ``;
export const OddValueTextHovered = `hidden lg:group-hover:block`;
export const ToolTipContainer = ({
  $noCursor,
}: ToolTipContainerProp) => `group flex items-center ml-1 lg:cursor-pointer relative
    ${'cursor-none'}
    ${$noCursor ? ' cursor-auto' : ''}`;
export const ToolTipContents = `group-hover:!visible rounded-md 5xl:rounded-xl 5xl:rounded-xl w-auto shadow-md shadow-black/50 whitespace-nowrap absolute invisible py-1.5 px-3 font-normal text-xs 3xl:text-base 4xl:text-lg 5xl:text-2xl 6xl:text-3xl 6xl:text-3xl bg-tooltip-bg-1 font-normal text-white -translate-x-2/4 -left-8 top-auto bottom-[calc(100%_+_7px)] after:content-[''] after:absolute after:-bottom-1 after:left-[80%] after:-translate-x-2/4 after:h-2.5 after:w-2.5 after:bg-tooltip-bg-1 after:rotate-45`;
//  Style for V2
export const OddValueStyledMobile = `group rounded-lg w-24 px-2 pt-1.5 pb-1 flex justify-center items-center text-sm bg-card-bg-2 text-text-gray-2 font-semibold mr-0`;
export const OddValueFlexText = `flex items-center`;
export const ArrowIcon = `size-2.5 3xl:size-3.5 mr-1`;
export const ToolTipWrapper = ({
  $noCursor,
}: ToolTipContainerProp) => `flex items-center lg:cursor-pointer relative flex-1 w-full
    ${' cursor-none'}
    ${$noCursor ? ' cursor-auto' : ''}`;

export const OddLabelButtonStyleMobile = (
  $isSelected: boolean) => `rounded-md w-full px-2 pt-2 pb-1.5 xl:py-3 flex flex-col justify-center items-center text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl 6xl:text-2xl bg-black text-white font-semibold mr-0 flex-1 ${$isSelected ? 'bg-selected-color-1' : ''}`;
export const OddButtonStyleMobile =
  'rounded-md w-full xs:w-20 h-10 px-3 xs:px-2 pt-1.5 pb-1 flex flex-col justify-center items-center text-sm bg-black text-text-gray-2 font-semibold mr-0';
