import { Fixture, FixtureTab, Market, Odds } from '#/utils/backend';
import MarketCard from './MarketCard/MarketCard.component';
import WithData from './Wrapper/WrapWithTransitionStates';
import {
  LoaderBg,
  LoaderBtn,
  LoaderCardContainer,
  LoaderSeparator,
} from './common/LoaderStyle/LoaderStyle.styled';
import { UPARROW_BRAND_ICON } from '#/constants/common/images.constants';
import { getMarketsInCategory } from '#/utils/marketsMap';

function MarketsWithData({
  data,
  expanded,
  setExpandedCard,
  fixtureTabSelected,
  addBetSlip,
  addHouseSlip,
}: {
  data: Fixture;
  expanded: boolean;
  setExpandedCard: (item: boolean) => void;
  fixtureTabSelected: FixtureTab;
  addBetSlip: (market: Market, odd: Odds) => void;
  addHouseSlip: (market: Market) => void;
}) {
  const markets = getMarketsInCategory(
    Object.keys(data.markets),
    fixtureTabSelected.id,
  ).flatMap((id) => data.markets[id]);

  return markets.map((market) => (
    <MarketCard
      key={market.id}
      market={market}
      expanded={expanded}
      setExpandedCard={() => setExpandedCard(true)}
      addBetSlip={addBetSlip}
      addHouseSlip={addHouseSlip}
      teams={data.teams}
    />
  ));
}

const LoadingComponent = () => {
  return (
    <div className="px-0">
      <div className={`${LoaderCardContainer} !rounded-none`}>
        <div>
          <div className="flex justify-between mb-3">
            <div className={`${LoaderBg} h-4 w-1/3`}></div>
            <span>
              <img className="w-3" src={UPARROW_BRAND_ICON} alt="icon" />
            </span>
          </div>
          <div className="flex space-x-2 mb-2">
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
          </div>
          <div className="text-white font-xs font-normal">MARKET STATS</div>
          <div className="w-full mt-2 mb-3">
            <div className="flex w-full space-x-2 items-center mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-1/2`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
            <div className="flex w-full space-x-2 items-center  mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-2/3`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
            <div className="flex w-full space-x-2 items-center  mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-1/3`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
          </div>
          <div className={`${LoaderBg} !rounded-md h-10 w-full`}></div>
        </div>
        <div className={LoaderSeparator} />
        <div>
          <div className="flex justify-between mb-3">
            <div className={`${LoaderBg} h-4 w-1/3`}></div>
            <span>
              <img className="w-3" src={UPARROW_BRAND_ICON} alt="icon" />
            </span>
          </div>
          <div className="flex space-x-2 mb-2">
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
          </div>
          <div className="text-white font-xs font-normal">MARKET STATS</div>
          <div className="w-full mt-2 mb-3">
            <div className="flex w-full space-x-2 items-center mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-1/2`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
            <div className="flex w-full space-x-2 items-center  mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-2/3`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
            <div className="flex w-full space-x-2 items-center  mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-1/3`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
          </div>
          <div className={`${LoaderBg} !rounded-md h-10 w-full`}></div>
        </div>
        <div className={LoaderSeparator} />
        <div>
          <div className="flex justify-between mb-3">
            <div className={`${LoaderBg} h-4 w-1/3`}></div>
            <span>
              <img className="w-3" src={UPARROW_BRAND_ICON} alt="icon" />
            </span>
          </div>
          <div className="flex space-x-2 mb-2">
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
          </div>
          <div className="text-white font-xs font-normal">MARKET STATS</div>
          <div className="w-full mt-2 mb-3">
            <div className="flex w-full space-x-2 items-center mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-1/2`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
            <div className="flex w-full space-x-2 items-center  mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-2/3`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
            <div className="flex w-full space-x-2 items-center  mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-1/3`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
          </div>
          <div className={`${LoaderBg} !rounded-md h-10 w-full`}></div>
        </div>
      </div>
    </div>
  );
};

const Markets = WithData<Fixture>(MarketsWithData, LoadingComponent, true);

export default Markets;
