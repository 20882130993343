export const CardContainer = `relative w-full mb-3 rounded-md bg-primary-bg-2 p-4`;
export const Title = `text-base text-white font-normal mt-2`;
export const SubTitle = `text-sm text-white font-light mt-2`;
export const CardHeader = `flex justify-between items-center mb-2`;
export const DateText = `text-sm text-white font-light text-right`;
export const StatusBadge = `flex-none text-center uppercase text-sm text-white font-bold rounded-md px-6 py-1 border border-solid border-card-bg-2`;
export const CardFooter = `flex justify-between items-center mt-3`;
export const CardFooterText = `text-sm text-white font-normal`;
export const CardFooterIcon = `w-4 mr-1.5`;
export const CardFlex = `flex justify-between items-center mb-2`;
export const CardFlexItems = `flex flex-1`;
export const LabelText = `text-sm text-white font-light mr-2`;
export const ValueText = `text-sm text-white font-normal`;
export const FlexCenter = `flex items-center`;
export const WonBadge = `flex-none min-w-24 text-center uppercase text-sm text-text-success-1 font-bold rounded-md px-6 py-1 bg-success-bg-2`;
export const LostBadge = `flex-none min-w-24 text-center uppercase text-sm text-text-danger-1 font-bold rounded-md px-6 py-1 bg-danger-bg-2`;
