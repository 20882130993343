import ActivityCard from '#/components/common/ActivityCard/ActivityCard.component';
import { formatDateToDesiredFormat } from '#/utils/datetime/datetime';
import {
  Bet,
  BetsData,
  getBetOutcome,
  getStatusType,
  isActiveBet,
  isSettledBet,
} from '#/utils/backend';
import WithData from './Wrapper/WrapWithTransitionStates';
import {
  LoaderBg,
  LoaderCardContainer,
} from './common/LoaderStyle/LoaderStyle.styled';
import EmptyList from './common/EmptyStates/EmptyState.component';
import {
  DOWNLOAD_ICON,
  UPLOAD_ICON,
} from '#/constants/common/images.constants';
import { getMarketNameById, getOutcomeName } from '#/utils/marketsMap';
import { useAppSelector } from '#/utils/store';
import { ActivityOutcome } from '#/utils/slices/activityFiltersSlice';
import { useEffect } from 'react';

function BetActivityCardsWithData({
  searchInput,
  activeSubItem,
  data,
}: {
  searchInput: string;
  activeTab: string;
  activeSubItem: string;
  data: Bet[];
}) {
  const activityFilters = useAppSelector((state) => state.activityFilters);

  const filteredData = data.filter((bet) => {
    const fitsTheTab =
      activeSubItem === 'activeBets' ? isActiveBet(bet) : isSettledBet(bet);
    const fitsSearch =
      searchInput.length > 0
        ? bet.fixtureName.toLowerCase().includes(searchInput.toLowerCase())
        : true;

    // TODO: The backend team would have to send us the sport of each bet
    // const allowedSports = []

    // Object.keys(activityFilters.sports).forEach((sport) => {
    //   if(activityFilters.sports[sport as Sport]) {
    //     allowedSports.push(sport)
    //   }
    // })

    // const fitsSport = allowedSports.length > 0
    //   ? allowedSports.includes(bet.sport)
    //   : true



    const allowedOutcomes: ActivityOutcome[] = [];

    Object.keys(activityFilters.outcomes).forEach((outcome) => {
      const typedOutcome = outcome as ActivityOutcome;
      if (activityFilters.outcomes[typedOutcome]) {
        allowedOutcomes.push(typedOutcome);
      }
    });


    const betOutcome = getBetOutcome(bet);

    const fitsOutcome =
      allowedOutcomes.length > 0
        ? allowedOutcomes.find((allowedOutcome) => {
          return betOutcome === allowedOutcome;
        })
        : true;

    const allowedMarketTypeIds: number[] = [];

    Object.keys(activityFilters.marketTypeIds).forEach((marketTypeId) => {
      const typedMarketTypeId = parseInt(marketTypeId);
      if (activityFilters.marketTypeIds[typedMarketTypeId]) {
        allowedMarketTypeIds.push(typedMarketTypeId);
      }
    });

    const fitsMarketTypeId =
      allowedMarketTypeIds.length > 0
        ? allowedMarketTypeIds.find((allowedMarketTypeId) => {
          return allowedMarketTypeId === bet.marketTypeId;
        })
        : true;

    return fitsTheTab && fitsSearch && fitsOutcome && fitsMarketTypeId;
  });

  if (!filteredData.length) {
    return <EmptyList id="bets" />;
  }

  return (
    <>
      {filteredData.map((bet) => {
        const outcomePosition = parseInt(bet.outcomeId.split('-')[1] || '0');
        const statusType = getStatusType(bet);
        return (
          <ActivityCard
            key={bet.id}
            statusText={bet.state}
            statusType={statusType}
            dateText={formatDateToDesiredFormat(bet.submittedAt || '')}
            title={`${getOutcomeName(bet.marketTypeId, outcomePosition)} @ ${bet.odds}`}
            titleText={`${getMarketNameById(bet.marketTypeId)}`}
            subTitle={bet.fixtureName}
            footerInfo={[
              { iconSrc: UPLOAD_ICON, text: `${bet.stake} SGE` },
              { iconSrc: DOWNLOAD_ICON, text: `${bet.potentialWinnings} SGE` },
            ]}
          />
        );
      })}
    </>
  );
}

const LoadingComponent = () => {
  return (
    <div className="px-0">
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5 w-11/12 mb-2`}></div>
          <div className="w-full flex items-center">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
        </div>
      </div>
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5 w-10/12 mb-2`}></div>
          <div className="w-full flex items-center">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
        </div>
      </div>
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5 w-11/12 mb-2`}></div>
          <div className="w-full flex items-center">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BetActivityCards = WithData<BetsData>(
  BetActivityCardsWithData,
  LoadingComponent,
  true,
);

export default BetActivityCards;
