/* eslint-disable no-param-reassign */
interface BetSlip {
  name: string;
  marketUID: string;
  eventName: string;
  oddValues: {
    american: string;
    decimal: string;
    fractional: string;
  };
  oddUID: string;
  oddsType?: string;
  amount: string | number;
  betUID?: string;
  ticket?: string;
  capacity: string;
  oddName: string;
  updatedOddValues?: {
    american: string;
    decimal: string;
    fractional: string;
  };
  typeUrl?: string;
  isMainMarket: boolean;
}

export function getIsBetOddSelected(betSlips: BetSlip[], oddUID: string) {
  return betSlips.findIndex((bet) => bet.oddUID === oddUID) >= 0;
}

export function getIsMarketSelected(betSlips: BetSlip[], marketUID: string) {
  return betSlips.findIndex((bet) => bet.marketUID === marketUID) >= 0;
}
