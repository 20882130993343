import { useSearchParams } from 'react-router-dom';
import MarketFilters from '../MarketFilter/MarketFilter.component';
import Modal from '../common/Modal.component';
import SportsFilterModal from '../SportsFilter/SportsFilterModal.component';
import OutcomeFilter from '../common/OutcomeFilter/OutcomeFilter.component';
import FiltersButton from './FiltersButton.component';
import { clearActivityFilters } from '#/utils/slices/activityFiltersSlice';
import { useAppDispatch } from '#/utils/store';

const BetsFilters = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const clearFilters = () => {
    dispatch(clearActivityFilters({ searchParams, setSearchParams }));
  };

  const applyFilters = () => {
    // Apply filters logic can be implemented here if needed
    onClose();
  };

  return (
    <Modal title="Filters" isOpen={isOpen} onClose={onClose}>
      <div className="w-full p-5">
        <div className="mb-5 overflow-y-auto space-y-3 max-h-[400px]">
          <OutcomeFilter />
          <SportsFilterModal />
          <MarketFilters />
        </div>
        <FiltersButton
          clearFilters={clearFilters}
          applyFilters={applyFilters}
        />
      </div>
    </Modal>
  );
};

export default BetsFilters;
