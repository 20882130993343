import { useAuth0 } from '@auth0/auth0-react';

const useAuth = () => {
  const auth0 = useAuth0();

  return {
    error: auth0.error,
    isError: auth0.error !== undefined,
    isLoading: auth0.isLoading,
    data: auth0.user,
    logout: () => {
      auth0.logout({
        logoutParams: { returnTo: window.location.origin },
      });
    },
  };
};

export default useAuth;
