import {
  CheckInput,
  HeaderWrapper,
  Title,
  HeadDivider,
  FilterCardContainer,
  MarketItemContainer,
  MarketLabel,
} from '#/components/FilterCards/FilterCards.styled';
import { SAMPLE_SPORTS_LIST } from '#/constants/common/global-constants';
import { Sport, stringToSport } from '#/utils/backend';
import { toggleSportFilterAndSynchUrl } from '#/utils/slices/activityFiltersSlice';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import { useSearchParams } from 'react-router-dom';

const sportsOptions = SAMPLE_SPORTS_LIST.map((sport) => sport.name);

export default function SportsFilterModal() {
  const dispatch = useAppDispatch();
  const sportFilters = useAppSelector((state) => state.activityFilters.sports);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSportChange = (sport: Sport) => {
    dispatch(
      toggleSportFilterAndSynchUrl({ sport, searchParams, setSearchParams }),
    );
  };

  return (
    <>
      <div className={HeaderWrapper}>
        <span className={Title}>Sport</span>
      </div>
      <hr className={HeadDivider} />
      <div className={FilterCardContainer}>
        <ul>
          {sportsOptions.map((sport) => {
            const typedSport = stringToSport(sport);

            if (typedSport) {
              const value = sportFilters[typedSport] || false;

              return (
                <li
                  className={MarketItemContainer}
                  key={sport}
                  onClick={() => {
                    handleSportChange(typedSport);
                  }}
                >
                  <input
                    className={`${CheckInput} !mr-0`}
                    type="checkbox"
                    id={sport}
                    checked={value}
                    onChange={() => {}}
                  />
                  <span className={MarketLabel}>{sport}</span>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </>
  );
}
