import { WALLET_INSTALL_IMG } from '#/constants/common/images.constants';
import AnimatedModal from '#/components/common/AnimatedModal.component';
import {
  CardContainer,
  HeaderSubTitle,
  HeaderTitle,
  ImgContainer,
  InnerContainer,
  InstallBtn,
  InstallImg,
  TopBorder,
} from './WalletInstallPopup.styled';
import RedirectLink from '#/components/common/RedirectLink.component';

function getWalletInstallLinks(walletName: string) {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    if (walletName.toLowerCase() === 'keplr') {
      return 'https://apps.apple.com/us/app/keplr-wallet/id1567851089';
    }
    return 'https://apps.apple.com/us/app/leap-cosmos/id1642465549';
  }
  if (walletName.toLowerCase() === 'keplr') {
    return 'https://play.google.com/store/apps/details?id=com.chainapsis.keplr';
  }
  return 'https://play.google.com/store/apps/details?id=io.leapwallet.cosmos';
}

const WalletInstallPopup = ({
  walletName = 'keplr',
  isOpen,
  onClose,
}: {
  walletName?: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <AnimatedModal title="" isOpen={isOpen} onClose={onClose}>
      <div className={CardContainer}>
        <div className="text-center">
          <div className={TopBorder} />
        </div>
        <div className={InnerContainer}>
          <div className={ImgContainer}>
            <img
              className={InstallImg}
              src={WALLET_INSTALL_IMG}
              alt="Wallet Install Icon"
            />
          </div>
          <div className="text-center">
            <h2
              className={HeaderTitle}
            >{`Access Six Sigma Sports through ${walletName}`}</h2>
            <h3
              className={HeaderSubTitle}
            >{`To connect, first open or install the ${walletName} app if you haven't already.`}</h3>
            <h3 className={`${HeaderSubTitle} mt-2`}>
              {`Then, use the ${walletName} browser within the app to access sixsigmasports.io.`}
            </h3>
          </div>
          <div className="w-full text-center mb-6 mt-8">
            <RedirectLink
              className={InstallBtn}
              href={getWalletInstallLinks(walletName)}
              target="_blank"
            >
              {`Open or install ${walletName}`}
            </RedirectLink>
          </div>
        </div>
      </div>
    </AnimatedModal>
  );
};
export default WalletInstallPopup;
