// src/store/responsibleGamblingSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BetLimitState {
  dailyLimit: number;
  weeklyLimit: number;
  monthlyLimit: number;
}

interface TimeLimitState {
  reminderFrequency: string;
}

interface ResponsibleGamblingState {
  betLimit: BetLimitState;
  timeLimit: TimeLimitState;
  timeoutPeriod: string; // Holds the date string like "2024-12-12T23:59:59"
}

const loadInitialTimeoutPeriod = (): string => {
  return localStorage.getItem('timeoutPeriod') || '';
};

const initialState: ResponsibleGamblingState = {
  betLimit: {
    dailyLimit: 0,
    weeklyLimit: 0,
    monthlyLimit: 0,
  },
  timeLimit: {
    reminderFrequency: 'None',
  },
  timeoutPeriod: loadInitialTimeoutPeriod(), // Initialize from localStorage if available
};

const responsibleGamblingSlice = createSlice({
  name: 'responsibleGambling',
  initialState,
  reducers: {
    setBetLimit: (state, action: PayloadAction<BetLimitState>) => {
      state.betLimit = action.payload;
    },
    setTimeLimit: (state, action: PayloadAction<TimeLimitState>) => {
      state.timeLimit = action.payload;
    },
    setTimeoutPeriod: (state, action: PayloadAction<string>) => {
      state.timeoutPeriod = action.payload;
      localStorage.setItem('timeoutPeriod', action.payload); // Sync with localStorage
    },
    resetLimits: (state) => {
      state.betLimit = initialState.betLimit;
      state.timeLimit = initialState.timeLimit;
      state.timeoutPeriod = ''; // Reset timeoutPeriod
      localStorage.removeItem('timeoutPeriod'); // Remove from localStorage
    },
  },
});

export const { setBetLimit, setTimeLimit, setTimeoutPeriod, resetLimits } =
  responsibleGamblingSlice.actions;

export default responsibleGamblingSlice.reducer;
