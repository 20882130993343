import { useQuery } from '@tanstack/react-query';
import { loadLiquidityData } from '../backend';

const useLiquidityDataLoader = (userId: string | null) => {
  return useQuery({
    queryKey: [`liquidityDataLoader`, userId],
    enabled: !!userId,
    queryFn: () => {
      if (userId) {
        return loadLiquidityData(userId);
      }
    },
  });
};

export default useLiquidityDataLoader;
