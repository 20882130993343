import { TRENDING_ICON } from '#/constants/common/images.constants';
import { FixtureSummary, HomepageData } from '#/utils/backend';
import TrendingList from './MatchCard/TrendingCard/TrendingCard.component';
import {
  CardHeaderWrapper,
  HeadTitle,
  IconWrapper,
} from './MatchCard/TrendingCard/TrendingCard.styled';
import WithData from './Wrapper/WrapWithTransitionStates';
import {
  LoaderBg,
  LoaderBtn,
  LoaderCardContainer,
} from './common/LoaderStyle/LoaderStyle.styled';

const HorizontalMatchListWithData = ({
  data,
  title,
  icon,
}: {
  data: FixtureSummary[];
  title: string;
  icon: string;
}) => {
  if (data.length === 0) return null;

  return (
    <div className="pb-4 pl-4 3xl:pb-6 4xl:pb-8">
      <TrendingList markets={data} title={title} icon={icon} />
    </div>
  );
};

const LoadingComponent = ({
  title,
  icon,
}: {
  title?: string;
  icon?: string;
}) => {
  return (
    <div className="px-4">
      <div className={CardHeaderWrapper}>
        <span className={IconWrapper}>
          {icon ? <img src={TRENDING_ICON} alt={'icon'} /> : null}
        </span>
        <h2 className={HeadTitle}>{title}</h2>
      </div>
      <div>
        <div className="flex overflow-x-auto overflow-y-hidden flex-nowrap space-x-4 mb-6">
          <div className={`${LoaderCardContainer} w-64 xxxs:w-72 flex-none`}>
            <div className={`${LoaderBg} h-4 w-4/5 mb-1`}></div>
            <div className={`${LoaderBg} h-4 w-3/5 mb-2`}></div>
            <div className={`${LoaderBg} h-4 w-1/3 mb-3`}></div>
            <div className="flex space-x-2">
              <div className={`${LoaderBg} ${LoaderBtn}`}></div>
              <div className={`${LoaderBg} ${LoaderBtn}`}></div>
              <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            </div>
          </div>
          <div className={`${LoaderCardContainer} w-64 xxxs:w-72 flex-none`}>
            <div className={`${LoaderBg} h-4 w-4/5 mb-1`}></div>
            <div className={`${LoaderBg} h-4 w-3/5 mb-2`}></div>
            <div className={`${LoaderBg} h-4 w-1/3 mb-3`}></div>
            <div className="flex space-x-2">
              <div className={`${LoaderBg} ${LoaderBtn}`}></div>
              <div className={`${LoaderBg} ${LoaderBtn}`}></div>
              <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HorizontalMatchList = WithData<HomepageData>(
  HorizontalMatchListWithData,
  LoadingComponent,
);

export default HorizontalMatchList;
