export const IframeContainer = `w-full h-full`;
export const HeadTitle = `text-xl text-white font-normal tracking-wider mt-8`;
export const ButtonWrapper = `w-full relative flex justify-between items-center mb-5 mt-14`;
export const ButtonNext = `py-2 px-14`;
export const NavDotsWrapper = `w-full flex justify-center items-center my-6`;
export const NavDotsActive = `size-3 rounded-full bg-white mx-1`;
export const NavDotsInactive = `size-3 rounded-full bg-white-40 mx-1`;
export const StepContents = `w-full relative px-4`;
export const BackBtn = `text-base font-bold text-white`;
export const BackBtnIcon = `w-3 mr-2`;
export const BgContainer = `before:h-52 overflow-hidden  sm:before:rounded-lg lg:before:rounded-2xl 5xl:before:rounded-3xl before:bg-no-repeat before:bg-[url('/v2-icons/tutorials-bg.png')] before:absolute before:left-0 before:top-0 before:w-full before:bg-cover before:z-0`;
export const BgInnerContainer = `h-52 w-full`;
export const VideoWrapper = `w-4/5 mx-auto relative pt-7`;
export const ButtonWhite = `bg-white rounded-md p-2 flex justify-center items-center w-full text-base font-bold text-black w-auto`;
export const ModalContentWrapper = `w-full relative`;
export const WalletContentText = `text-sm 3xl:text-lg 4xl:text-xl 5xl:text-2xl 6xl:text-4xl text-text-gray-5 font-normal mt-3 mb-5`;
