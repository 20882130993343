import MobileCapacityToast from '#/components/MatchCard/MobileCapacityToast';
import { useState } from 'react';
import ActionTag from '#/components/common/ActionTags.component';
import { DEFAULT_ODD_TYPE } from '#/constants/common/global-constants';
import OddButton from '#/components/common/Buttons/OddButton/OddButton.component';
import {
  CardContainer,
  MultipleOddsContainer,
  MultipleOddsWrapper,
  OddHeaderMultiple,
} from './MarketCard.styled';
import type { MarketCardProps } from './MarketCard.types';
import HouseParams from './HouseParams/HouseParams.component';
import { OddLabelButtonStyleMobile } from '#/components/common/Buttons/OddButton/OddButton.styled';
import { getDisplayOddValue } from '#/utils/appUtils';
import { getMarketNameById, getOutcomeName } from '#/utils/marketsMap';
import {
  OddsContainer,
  OddNameMobile,
} from '../common/MarketComponent/DashboardMarket.styled';
import {
  ODDS_DOWN_ARROW,
  ODDS_UP_ARROW,
} from '#/constants/common/images.constants';
import CardCollapse from './CardCollapse.component';
import { useAppSelector } from '#/utils/store';
import { outcomeId } from '#/utils/backend';
import { isOddSelected } from '../SlipsDrawers/SlipsDrawer.utils';

export default function MarketCard({
  market,
  setExpandedCard,
  addBetSlip,
  addHouseSlip,
  teams,
}: MarketCardProps) {
  const oddsType = String(DEFAULT_ODD_TYPE.key);
  const [showCapacityMessage, setShowCapacityMessage] = useState(false);
  const totalOdds = Object.keys(market.odds).length;
  const isMarketDisabled = market.status !== 'Operational';
  const [openIndex, setOpenIndex] = useState(-1);
  const outcomes = useAppSelector((state) => state.outcomes.outcomes);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };
  const betSlips = useAppSelector((state) => state.betSlips);

  return (
    <div className={CardContainer}>
      <ActionTag
        type="div"
        className={MultipleOddsWrapper}
        onClick={() => {
          if (setExpandedCard) {
            setExpandedCard(market.id);
          }
        }}
        id="match_details_mutiple_odds_collapse_card_click"
      >
        <CardCollapse
          index={0}
          isCollapsed={openIndex === 0}
          onToggle={handleToggle}
          title={
            <span
              className={OddHeaderMultiple}
            >{`${getMarketNameById(market.marketTypeId) || market.marketTypeName} ${market.betLine ? market.betLine : ''}`}</span>
          }
        >
          {showCapacityMessage ? <MobileCapacityToast /> : null}

          <div
            className={MultipleOddsContainer({
              hasMoreThanTwoOdds: totalOdds > 2,
              $isDisabled: isMarketDisabled,
            })}
          >
            {Object.keys(market.odds).map((oddKey) => {
              const odd = market.odds[oddKey];
              const outcomeData = outcomes[outcomeId(odd)];

              const value = outcomeData?.value || '-';
              const movement = outcomeData?.movement || 'Down';
              const isSelected = isOddSelected(betSlips, odd);
              return (
                <div className="w-full" key={odd.outcomePosition}>
                  <OddButton
                    value={getDisplayOddValue({
                      oddsType: String(1) || DEFAULT_ODD_TYPE.key,
                      oddValues: {
                        decimal: value,
                        american: '',
                        fractional: '',
                      },
                      extended: true,
                    })}
                    extendedValue={getDisplayOddValue({
                      oddsType: String(1) || DEFAULT_ODD_TYPE.key,
                      oddValues: {
                        decimal: odd.value.toString(),
                        american: '',
                        fractional: '',
                      },
                      extended: true,
                    })}
                    isVisible={isMarketDisabled}
                    onClick={() =>
                      !isMarketDisabled
                        ? addBetSlip(market, odd)
                        : setShowCapacityMessage(true)
                    }
                    oddsType={oddsType}
                    name={
                      getOutcomeName(
                        odd.marketTypeId,
                        odd.outcomePosition,
                        teams.Home.Name,
                        teams.Away.Name,
                      ) || odd.outcomeName
                    }
                    oddContainerStyle={OddsContainer}
                    oddLabelStyle={OddNameMobile}
                    withLabel={true}
                    OddValueStyledMobile={OddLabelButtonStyleMobile(isSelected)}
                    icon={
                      movement
                        ? movement === 'Up'
                          ? ODDS_UP_ARROW
                          : ODDS_DOWN_ARROW
                        : ''
                    }
                  />
                </div>
              );
            })}
          </div>
          <HouseParams addHouseSlip={addHouseSlip} market={market} />
        </CardCollapse>
      </ActionTag>
    </div>
  );
}
