import {
  InnerContainer,
  NavIcon,
  NavIconActive,
  NavItems,
  NavigationContainer,
} from './MobileNavigation.styled';
import Link from '../Link.component';
import {
  EXPLORE_TEXT,
  HOME_TEXT,
  IMG_ALT_TEXT,
  MYBETS_TEXT,
  NOTIFICATIONS_TEXT,
} from './MobileNavigation.constants';
import { useLocation } from 'react-router-dom';
import {
  BETS_ICON,
  BETS_ICON_ACTIVE,
  EXPLORE_ICON,
  EXPLORE_ICON_BRAND,
  HOME_ICON,
  HOME_ICON_BRAND,
  NOTIFICATION_ICON,
  NOTIFICATION_ICON_BRAND,
} from '#/constants/common/images.constants';

type NavItem = {
  iconActive: string;
  to: string;
  icon: string;
  text: string;
};

const navItems: NavItem[] = [
  {
    to: '/Soccer',
    icon: HOME_ICON,
    iconActive: HOME_ICON_BRAND,
    text: HOME_TEXT,
  },
  {
    to: '/explore',
    icon: EXPLORE_ICON,
    iconActive: EXPLORE_ICON_BRAND,
    text: EXPLORE_TEXT,
  },
  {
    to: '/my-bets',
    icon: BETS_ICON,
    iconActive: BETS_ICON_ACTIVE,
    text: MYBETS_TEXT,
  },
  {
    to: '/notifications',
    icon: NOTIFICATION_ICON,
    iconActive: NOTIFICATION_ICON_BRAND,
    text: NOTIFICATIONS_TEXT,
  },
];

export default function MobileNavigation() {
  const { pathname } = useLocation();
  return (
    <div className={NavigationContainer}>
      <div className={InnerContainer}>
        {navItems.map((item, index) => (
          <Link
            key={index}
            className={NavItems({
              isActive: pathname.toLowerCase().includes(item.to.toLowerCase()),
            })}
            to={item.to}
          >
            <span>
              {pathname.toLowerCase().includes(item.to.toLowerCase()) ? (
                <img
                  className={NavIconActive}
                  src={item.iconActive}
                  alt={IMG_ALT_TEXT}
                />
              ) : (
                <img className={NavIcon} src={item.icon} alt={IMG_ALT_TEXT} />
              )}
            </span>
            <span>{item.text}</span>
          </Link>
        ))}
      </div>
    </div>
  );
}
