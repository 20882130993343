import { loadFixtureData, outcomeId } from '#/utils/backend';
import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '../store';
import { Outcomes, updateOutcomes } from '../slices/outcomesSlice';

const useFixtureData = (id: string) => {
  const dispatch = useAppDispatch();

  return useQuery({
    queryKey: ['fixture', id],
    queryFn: async () => {
      return loadFixtureData(id).then((data) => {
        const newOutcomes: Outcomes = {};

        Object.values(data.markets).forEach((markets) => {
          markets.forEach((market) => {
            Object.values(market.odds).forEach((odds) => {
              newOutcomes[outcomeId(odds)] = {
                value: odds.value,
                movement: odds.movement,
                acceptable_bet_size: odds.acceptableBetSize,
              };
            });
          });
        });

        dispatch(updateOutcomes(newOutcomes));

        return data;
      });
    },
  });
};

export default useFixtureData;
