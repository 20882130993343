/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { BACK_ICON } from '#/constants/common/images.constants';
import { useSearchParams } from 'react-router-dom';
import {
  ContentContainer,
  ContentText,
  TabTitleText,
  FieldContainer,
  InputLabel,
  InputField,
  InputFieldWrapper,
  SgeText,
} from '../ResponsibleGambling.styled';
import SaveButton from './SaveButton.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '#/utils/store';
import { setBetLimit } from '#/utils/slices/responsibleGamblingSlice';

export default function BetLimit() {
  const dispatch = useDispatch();
  const { dailyLimit, weeklyLimit, monthlyLimit } = useSelector(
    (state: RootState) => state.responsibleGambling.betLimit,
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(
      setBetLimit({
        ...{ dailyLimit, weeklyLimit, monthlyLimit },
        [name]: value,
      }),
    );
  };

  const [, setSearchParams] = useSearchParams();

  const handleBackClick = () => {
    setSearchParams({});
  };

  return (
    <div className={ContentContainer}>
      <h3 className={`${TabTitleText} mb-4`}>
        <img
          className="w-2.5 mr-2 cursor-pointer"
          src={BACK_ICON}
          alt="Back Icon"
          onClick={handleBackClick}
        />
        BET LIMIT
      </h3>
      <p className={ContentText}>
        {`This facility enables you to limit the amount of money that you are able to deposit online into your account on either a 24-hour, 7-day, and/or 30-day basis. It's an effective way to control your spending and ensure responsible gambling practices. At Six Sigma, we prioritize your well-being and promote responsible gambling, and our Bet Limits feature is designed to support you in making informed decisions about your betting activities.`}
      </p>
      <div className="mt-5">
        <div className={FieldContainer}>
          <label htmlFor="daily_limit" className={InputLabel}>
            Daily Limit
          </label>
          <div className={InputFieldWrapper}>
            <input
              type="text"
              name="dailyLimit"
              id="daily_limit"
              className={InputField}
              placeholder="0"
              value={dailyLimit}
              onChange={handleChange}
            />
            <span className={SgeText}>SGE</span>
          </div>
        </div>
        <div className={FieldContainer}>
          <label htmlFor="weekly_limit" className={InputLabel}>
            Weekly Limit
          </label>
          <div className={InputFieldWrapper}>
            <input
              type="text"
              name="weeklyLimit"
              id="weekly_limit"
              className={InputField}
              placeholder="0"
              value={weeklyLimit}
              onChange={handleChange}
            />
            <span className={SgeText}>SGE</span>
          </div>
        </div>
        <div className={FieldContainer}>
          <label htmlFor="monthly_limit" className={InputLabel}>
            Monthly Limit
          </label>
          <div className={InputFieldWrapper}>
            <input
              type="text"
              name="monthlyLimit"
              id="monthly_limit"
              className={InputField}
              placeholder="0"
              value={monthlyLimit}
              onChange={handleChange}
            />
            <span className={SgeText}>SGE</span>
          </div>
        </div>
        <SaveButton />
      </div>
    </div>
  );
}
