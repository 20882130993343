import { useAuth0 } from '@auth0/auth0-react';
import { ButtonSave, ButtonSaveWrapper } from '../ResponsibleGambling.styled';
import Button from '#/components/common/Button';
import useUserData from '#/hooks/useUserData';
import env from '#/utils/env';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '#/utils/store';
import { toast } from 'react-toastify';
import {
  setBetLimit,
  setTimeLimit,
  setTimeoutPeriod,
} from '#/utils/slices/responsibleGamblingSlice';
import { Big } from 'bigdecimal.js';
import { useKycStatus } from '#/components/SlipsDrawers/Components/useKycStatus';
import dayjs from 'dayjs';
import { deactivateUser, updateUserTimeLimit } from '#/utils/backend';
import {
  useAccount,
  useExecuteContract,
  ExecuteContractMutationArgs,
  useCosmWasmSigningClient,
} from 'graz';
import { setIsModalOpen } from '#/utils/slices/walletConnectionSlice';

function SaveButton() {
  const { isAuthenticated } = useUserData();
  const { loginWithRedirect, logout } = useAuth0();
  const { data: account } = useAccount();
  const address = account?.bech32Address;
  const { data: signingClient } = useCosmWasmSigningClient();
  const { executeContract } = useExecuteContract({
    contractAddress: env.contract,
  });
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { kycToken, isLoading: kycLoading } = useKycStatus();
  const tab = searchParams.get('tab');

  const { betLimit, timeLimit, timeoutPeriod } = useSelector(
    (state: RootState) => state.responsibleGambling,
  );

  const handleSaveClick = async () => {
    if (!address) {
      dispatch(setIsModalOpen(true));
      return;
    }

    if (!isAuthenticated) {
      loginWithRedirect();
      return;
    }

    try {
      switch (tab) {
        case 'betLimit':
          await handleBetLimitSave();
          dispatch(setBetLimit(betLimit));
          break;

        case 'timeLimit':
          await handleTimeLimitSave(); // Directly call the function
          dispatch(setTimeLimit(timeLimit));
          break;

        case 'timeOut':
          await handleTimeOutSave();
          dispatch(setTimeoutPeriod(timeoutPeriod));
          break;

        case 'accountDeactivation':
          await handleAccountDeactivation(); // Directly call the function
          break;

        default:
          toast.error('Unknown action.');
          break;
      }
    } catch (error) {
      toast.error('An error occurred while saving. Please try again.');
      console.error(error);
    }
  };

  const handleBetLimitSave = async () => {
    try {
      const payload: Record<string, string | null> = {
        token: kycToken,
      };

      if (!signingClient) {
        throw new Error('Signing client not found');
      }

      if (betLimit.dailyLimit && betLimit.dailyLimit > 0) {
        payload.daily = new Big(betLimit.dailyLimit * 10 ** 6)
          .toBigInt()
          .toString();
      }

      if (betLimit.weeklyLimit && betLimit.weeklyLimit > 0) {
        payload.weekly = new Big(betLimit.weeklyLimit * 10 ** 6)
          .toBigInt()
          .toString();
      }

      if (betLimit.monthlyLimit && betLimit.monthlyLimit > 0) {
        payload.monthly = new Big(betLimit.monthlyLimit * 10 ** 6)
          .toBigInt()
          .toString();
      }

      const msg: ExecuteContractMutationArgs<Record<string, unknown>> = {
        msg: {
          set_gambling_limits: payload,
        },
        funds: [], // No funds required for this operation
      };

      executeContract(
        {
          signingClient,
          ...msg,
        },
        {
          onSuccess: () => {
            toast.success('Bet limits have been saved.');
          },
          onError: () => {
            toast.error('Failed to save bet limits.');
          },
        },
      );
    } catch (err: unknown) {
      toast.error((err as Error).message);
    }
  };

  const handleTimeLimitSave = async () => {
    try {
      await updateUserTimeLimit(Number(timeLimit.reminderFrequency));
      toast.success('Time limit has been saved.');
    } catch (error) {
      toast.error('Failed to save time limit.');
      console.error('Error saving time limit:', error);
    }
  };

  const handleTimeOutSave = async () => {
    const convertTimeoutToDateString = (timeout: string): string => {
      const currentDate = dayjs();

      switch (timeout) {
        case '15 Days':
          return currentDate.add(15, 'day').toISOString();

        case '1 Month':
          return currentDate.add(1, 'month').toISOString();

        case '1 Year':
          return currentDate.add(1, 'year').toISOString();

        default:
          throw new Error('Invalid timeout period');
      }
    };

    try {
      const timeoutDateString = convertTimeoutToDateString(timeoutPeriod);

      if (!signingClient) {
        throw new Error('Signing client not found');
      }

      const msg: ExecuteContractMutationArgs<Record<string, unknown>> = {
        msg: {
          set_gambling_limits: {
            token: kycToken,
            duration: timeoutDateString,
          },
        },
        funds: [], // No funds required for this operation
      };

      executeContract(
        {
          signingClient,
          ...msg,
        },
        {
          onSuccess: () => {
            toast.success('Timeout period has been saved.');
          },
          onError: () => {
            toast.error('Failed to save timeout period.');
          },
        },
      );
    } catch (error) {
      toast.error('An error occurred while processing the timeout period.');
      console.error(error);
    }
  };

  const handleAccountDeactivation = async () => {
    try {
      const response = await deactivateUser();

      toast.info(response.response);
      logout({
        logoutParams: { returnTo: window.location.origin },
      });

      // Additional logic to sign the user out can be added here
    } catch (error) {
      toast.error('Failed to deactivate account.');
      console.error('Error deactivating account:', error);
    }
  };

  function getButtonText() {
    if (!address) {
      return 'Connect to Wallet';
    }

    if (kycLoading) {
      return 'Loading...';
    }

    if (!isAuthenticated) {
      return 'Login to Save';
    }

    if (tab === 'accountDeactivation') {
      return 'Deactivate Account';
    }

    return 'Save';
  }

  return (
    <div className={ButtonSaveWrapper}>
      <Button
        className={ButtonSave}
        id="save"
        onClick={handleSaveClick}
        disabled={kycLoading}
      >
        {getButtonText()}
      </Button>
    </div>
  );
}

export default SaveButton;
