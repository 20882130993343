import {
  CAPACITY_TOAST_MESSAGE,
  INFO_ALT,
} from './MobileCapacityToast.constants';
import {
  FlexContainer,
  FlexOne,
  IconWrapper,
  PlacedIcon,
  ToastContainer,
  ToastText,
} from './MobileCapacityToast.styled';
import { GREEN_INFO_ICON } from '#/constants/common/images.constants';

export default function MobileCapacityToast() {
  return (
    <div className={ToastContainer}>
      <div className={FlexContainer}>
        <div className={FlexOne}>
          <div className={IconWrapper}>
            <img
              className={PlacedIcon}
              loading="lazy"
              src={GREEN_INFO_ICON}
              alt={INFO_ALT}
            />
          </div>
          <span className={ToastText}>{CAPACITY_TOAST_MESSAGE}</span>
        </div>
      </div>
    </div>
  );
}
