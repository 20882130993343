import { useState } from 'react';
import Transactions from '#/components/Transactions/Transactions.component';
import useTransactionsDataLoader from '#/utils/dataLoaders/useTransactionsLoader';
import { SEARCH_ICON_LIGHT} from '#/constants/common/images.constants';
import {
  Header,
  PageTitle,
} from './Transactions/Transactions.styled';
import FilterButton from '#/components/common/FilterButton.component';
import { SearchInputField, SearchInputFieldWrapper, SearchLeftIcon } from './common/Search/Search.styled';
import TransactionsFilter from './Transactions/TransactionsFilter.component';
import { AppToast } from './common/ToastNotification/ToastConfig';
import { toast } from 'react-toastify';
import { useAccount } from 'graz';


export default function TransactionsList() {
  const pageSize = 10; // Number of transactions per page
  const [showFilters, setShowFilters] = useState(false);
  const { data: account } = useAccount();
  const { data, isLoading, error } = useTransactionsDataLoader(0, pageSize, account?.bech32Address.toString());


  if (error) {
    toast.error(<AppToast id={'UnableToFetch'} />, { icon: false });
  }

  return (
    <div className='px-4'>
      <div className={Header}>
        <h2 className={PageTitle}>Transactions</h2>
      </div>
      <div className="flex items-center">
        <div className={SearchInputFieldWrapper}>
          <img
            className={SearchLeftIcon}
            src={SEARCH_ICON_LIGHT}
            alt="icon"
            loading="lazy"
          />
          <input
            className={SearchInputField}
            aria-label={'Search'}
            type="text"
            placeholder="Search"
            autoComplete="off"
          />

        </div>
        <FilterButton onClick={() => setShowFilters(!showFilters)} />
      </div>
      <Transactions 
        data={data}
        isLoading={isLoading || error}
        id={'data'}
      />
      {showFilters ? <TransactionsFilter isOpen={showFilters} onClose={() => setShowFilters(false)} /> : null}
    </div>
  );
}
