export const FooterContainer = `w-full pb-5 pt-7 px-4`;
export const FooterHeader = `w-full flex`;
export const PageTitle = `text-white font-bold text-base xxxs:text-xl tracking-wider`;
export const FooterLogo = `w-8 mr-3`;
export const SubTitle = `text-white font-normal text-sm xxxs:text-base tracking-wider mt-5`;
export const RequirmentBtnWrapper = `w-full flex space-x-4 my-8`;
export const RequirmentBtn = `w-auto text-white font-normal text-sm xxxs:text-base space-x-3 px-2 xxxs:px-4 py-2 flex items-center rounded-md bg-card-bg-1`;
export const RequirmentLogo = `w-5.5`;
export const LicenceLogo = `w-6`;
export const FooterLinks = `block text-white font-light text-sm xxxs:text-base mb-2.5`;
export const Separator = `block h-0 border border-solid border-t-0 border-border-bg-1 my-3`;
export const SocialIconWrapper = `flex w-full space-x-3.5 mt-4`;
export const SocialIcon = `size-6`;
export const DiscordIcon = `w-7`;
