import { useAuth0 } from '@auth0/auth0-react';
import useAuth from './useAuth';
import { MINUTE } from '../time';
import { useQuery } from '@tanstack/react-query';

const useAccessToken = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { data: user } = useAuth();

  return useQuery({
    queryKey: ['accessToken', user?.sub],
    queryFn: async () => {
      return getAccessTokenSilently();
    },
    retry: 3,
    refetchInterval: MINUTE,
    refetchOnWindowFocus: true,
    enabled: !!user,
  });
};

export default useAccessToken;
