import { Fixture, loadFixtureSearchData, outcomeId } from '../backend';
import { useQuery } from '@tanstack/react-query';
import { Outcomes, updateOutcomes } from '../slices/outcomesSlice';
import { QueryParams } from '../slices/searchSlice';
import { Dispatch } from '@reduxjs/toolkit';
import { useAppDispatch } from '../store';

export const customQuerySerializer = (
  params: Record<string, unknown>,
): string => {
  const queryObject = params.query;
  return `query=${encodeURIComponent(JSON.stringify(queryObject))}`;
};

interface SearchResult {
  rows: Fixture[];
  total: number;
  nextOffset: number | null;
}

export const useSearchLoader = (
  query: QueryParams,
  page: number = 0,
  pageSize: number = 10,
) => {
  const dispatch = useAppDispatch();
  const updatedQuery = {
    ...query,
    offset: page * pageSize,
  };

  const resp = useQuery({
    queryKey: [
      'fixtures/search',
      query.search,
      query.filters,
      query.sport,
      query.startsAfter,
      query.startsBefore,
      query.favouritesOf,
      page,
      pageSize,
    ],
    queryFn: async () => {
      return loadFixtureSearchData(updatedQuery, updatedQuery.offset, pageSize)
        .then((data) => {
          const newOutcomes: Outcomes = {};

          data.fixtures.forEach((fixture) => {
            Object.values(fixture.odds).forEach((odds) => {
              newOutcomes[outcomeId(odds)] = {
                value: odds.value,
                movement: odds.movement,
                acceptable_bet_size: odds.acceptableBetSize,
              };
            });
          });

          dispatch(updateOutcomes(newOutcomes));
          console.log('outcomes redux registry updated in useSearchLoader');

          return data;
        })
        .catch((error) => {
          console.error('Error loading fixture search data:', error);
          throw error;
        });
    },
    staleTime: 0, // Force refetch each time
  });

  return resp;
};

export type InfiniteQueryCtx = {
  pageParam: number;
};

export function searchInfiniteQuery(
  dispatch: Dispatch,
  query: QueryParams,
  pageSize: number = 10,
) {
  return {
    queryKey: ['search', query.search || '', (query.offset || 0).toString()],
    queryFn: async (ctx: InfiniteQueryCtx) => {
      return loadFixtureSearchData(query, ctx.pageParam, pageSize)
        .then((data) => {
          const newOutcomes: Outcomes = {};

          data.fixtures.forEach((fixture) => {
            Object.values(fixture.odds).forEach((odds) => {
              newOutcomes[outcomeId(odds)] = {
                value: odds.value,
                movement: odds.movement,
                acceptable_bet_size: odds.acceptableBetSize,
              };
            });
          });

          dispatch(updateOutcomes(newOutcomes));
          console.log('outcomes redux registry updated in searchInfiniteQuery');

          return data;
        })
        .then((responseData) => {
          const { fixtures, offset, total } = responseData;

          return {
            rows: fixtures,
            total: total,
            nextOffset:
              offset + fixtures.length >= total
                ? null
                : offset + fixtures.length,
          };
        })
        .catch((error) => {
          console.error('Error loading fixture search data:', error);
          throw error;
        });
    },
    getNextPageParam: (lastGroup: SearchResult) => lastGroup.nextOffset,
    initialPageParam: 0,
  };
}
