import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
  createRoutesFromChildren,
  matchRoutes,
  useNavigationType,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import NavBar from './components/NavBar/NavBar.component';
import Explore from './components/routes/Explore';
import './styles/tailwind.css';
import '@interchain-ui/react/styles';
import SportHome from './components/routes/SportHome';
import MyBets from './components/routes/MyBets';
import { Sport } from './utils/backend';
import { ReactNode, useEffect } from 'react';
import * as localStorageUtils from '#/utils/localStorage';
import { decodeBetSlips, decodeHouseSlips } from './utils/Slip';
import { set as setBetSlips } from '#/utils/slices/betSlipsSlice';
import { set as setHouseSlips } from '#/utils/slices/houseSlipsSlice';
import store, { useAppDispatch, useAppSelector } from '#/utils/store';
import { Provider } from 'react-redux';
import SlipsDrawers from './components/SlipsDrawers/SlipsDrawers.component';
import MobileNavigation from './components/common/MobileNavigation/MobileNavigation.component';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notifications from './components/routes/Notifications';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import env from '#/utils/env';
import GetStarted from './components/OnboardingComponent/GetStarted/GetStarted.component';
import WalletDeposit from './components/routes/Deposit';
import Footer from './components/Footer/Footer.component';
import useUserData from './hooks/useUserData';
import FixtureDetails from './components/routes/FixtureDetails';
import TransactionsList from './components/TransactionsList';
import ResponsibleGambling from './components/ResponsibleGambling/ResponsibleGambling.component';
import * as Sentry from '@sentry/react';
import useSentryUser from './hooks/useSentryUser';
import {
  configItems,
  logo,
  menuItems,
} from './components/NavBar/NavBar.constants';
import SideMenu from './components/NavBar/SideMenuPanel/SideMenu.component';
import {
  ForDesktopLeftPanel,
  RightContainer,
} from './components/NavBar/SideMenuPanel/SideMenu.styled';
import { GrazProvider, useAccount, useDisconnect } from 'graz';
import EditProfile from './components/EditProfile/EditProfile.component';

if (env.sentry) {
  Sentry.init({
    normalizeDepth: 10,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserProfilingIntegration(),
    ],
    tracePropagationTargets: [
      'localhost',
      'https://staging.sixsigmasports.app',
      'https://testnet.sixsigmasports.app',
      'https://sixsigmasports.app',
    ],
    ...env.sentry,
  });
}

function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    } else {
      const id = document.getElementById(hash.substring(1));
      if (id) {
        id.scrollIntoView();
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [hash, pathname]);

  return null;
}

function Layout({ children }: { children: ReactNode }) {
  const { isAuthenticated, user } = useUserData();
  const { logout } = useAuth0();
  const shouldShowGetStarted =
    isAuthenticated && (!user?.sgeAddress || user?.kycStatus !== 'APPROVED');

  return (
    <>
      <ScrollToTop />
      <div className="bg-primary-bg-1 font-Helvetica h-fit xl:flex xl:flex-wrap">
        <div className={ForDesktopLeftPanel}>
          <SideMenu
            menuItems={menuItems}
            configItems={configItems}
            user={user}
            isAuthenticated={isAuthenticated}
            logo={logo}
            logout={logout}
          />
        </div>
        <div className={RightContainer}>
          <NavBar />
          {shouldShowGetStarted && <GetStarted />}
          {children}
          <SlipsDrawers />
          <Footer />
        </div>
      </div>
      <MobileNavigation />
      <ToastContainer
        toastClassName={
          'w-full xl:w-1/3 top-14 left-0 items-center \nshadow-toast-shadow-1'
        }
        toastStyle={{
          backgroundColor: '#222232',
          borderRadius: '0.375rem',
          boxShadow:
            'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)',
          padding: '1rem',
        }}
        style={{ width: '97%', paddingLeft: '1rem' }}
        hideProgressBar={true}
        closeButton={false}
      />
    </>
  );
}

const defaultSport: Sport = 'Soccer';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Navigate to={`/${defaultSport.toLowerCase()}`} replace />,
  },
  {
    path: '/:sportId',
    element: (
      <Layout>
        <SportHome />
      </Layout>
    ),
  },
  {
    path: '/explore',
    element: (
      <Layout>
        <Explore />
      </Layout>
    ),
  },
  {
    path: '/my-bets',
    element: (
      <Layout>
        <MyBets />
      </Layout>
    ),
  },
  {
    path: '/match-detail/:fixtureId',
    element: (
      <Layout>
        <FixtureDetails />
      </Layout>
    ),
  },
  {
    path: '/deposit',
    element: (
      <Layout>
        <WalletDeposit />
      </Layout>
    ),
  },
  {
    path: '/notifications',
    element: (
      <Layout>
        <Notifications />
      </Layout>
    ),
  },
  {
    path: '/transactions',
    element: (
      <Layout>
        <TransactionsList />
      </Layout>
    ),
  },
  {
    path: '/responsible-gambling',
    element: (
      <Layout>
        <ResponsibleGambling />
      </Layout>
    ),
  },
  {
    path: '/edit-profile',
    element: (
      <Layout>
        <EditProfile />
      </Layout>
    ),
  },
]);

function Loaders(): JSX.Element {
  const { data: account } = useAccount();
  const { disconnect } = useDisconnect();

  useSentryUser();

  const linkedAddress = useAppSelector((state) => state.users.sgeAddress);

  useEffect(() => {
    if (account && linkedAddress) {
      if (account.bech32Address !== linkedAddress) {
        toast.warning(`Please connect to ${linkedAddress}`);
        disconnect();
      }
    }
  }, [account, linkedAddress]);

  // TODO: Delete once there's no code relying on user data in redux
  const { fetchUser } = useUserData();

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return <></>;
}

function AppInner(): JSX.Element {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const betSlipsFromStorage = localStorageUtils.getBetSlips();
    const houseSlipsFromStorage = localStorageUtils.getHouseSlips();

    if (betSlipsFromStorage) {
      const decodedSlips = decodeBetSlips(JSON.parse(betSlipsFromStorage));

      if (decodedSlips) {
        dispatch(setBetSlips(decodedSlips));
      }
    }

    if (houseSlipsFromStorage) {
      const decodedSlips = decodeHouseSlips(JSON.parse(houseSlipsFromStorage));

      if (decodedSlips) {
        dispatch(setHouseSlips(decodedSlips));
      }
    }
  }, []);

  return (
    <>
      <Loaders />
      <RouterProvider router={router} />
    </>
  );
}

export default function App(): JSX.Element {
  const auth0 = env.auth0;

  return (
    <div
      className={`bet-mode bg-primary-bg-1 font-Helvetica min-h-screen relative pb-20`}
      id="app-container"
    >
      <div className="bg-primary-bg-1 font-Helvetica h-fit">
        <QueryClientProvider client={new QueryClient()}>
          <Provider store={store}>
            <Auth0Provider
              domain={auth0.domain}
              clientId={auth0.clientId}
              authorizationParams={{
                redirect_uri: auth0.redirectUri,
                audience: auth0.audience,
              }}
              cacheLocation="localstorage"
              useRefreshTokens
            >
              <GrazProvider
                grazOptions={{
                  chains: [env.chainInfo],
                  chainsConfig: {
                    [env.chainInfo.chainId]: {
                      gas: {
                        price: '10000000',
                        denom: 'usge',
                      },
                    },
                  },
                  walletConnect: {
                    options: { projectId: '0592f75fa032204a69cda88879fcc53c' },
                    web3Modal: {
                      themeMode: 'dark',
                    },
                  },
                  walletDefaultOptions: {
                    sign: {
                      preferNoSetMemo: true,
                    },
                  },
                }}
              >
                <AppInner />
              </GrazProvider>
            </Auth0Provider>
          </Provider>
          <ReactQueryDevtools buttonPosition="bottom-right" />
        </QueryClientProvider>
      </div>
      <div id="portal-root"></div>
    </div>
  );
}
