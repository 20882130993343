export const CONNECT_WALLET = 'Connect Wallet';
export const LEARN_TEXT = 'Learn how to setup a Wallet';
export const SELECT_WALLET = 'Select Wallet';
export const KEPLR_TEXT = 'Keplr Mobile';
export const LEAP_TEXT = 'Leap Mobile';

export const LOGIN_TEXT = 'Login';
export const EMAIL_TEXT = 'E-mail';
export const PASSWORD_TEXT = 'Password';
export const CONTINUE_TEXT = 'Continue';
export const CONTINUE_GOOGLE = 'Continue with Google';
export const CONTINUE_APPLE = 'Continue with Apple';
