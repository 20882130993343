import FiltersButton from '../Filters/FiltersButton.component';
import Modal from '../common/Modal.component';
import StatusFilter from './StatusFilter.component';
import { HeadDivider } from './Transactions.styled';
import TypeFilter from './TypeFilter.component';

const TransactionsFilter = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  function clearFilters() {}

  function applyFilters() {}

  return (
    <Modal title="Filters" isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col p-5">
        <StatusFilter />
        <TypeFilter />
        <hr className={`${HeadDivider} !mt-0`} />
        <FiltersButton
          clearFilters={clearFilters}
          applyFilters={applyFilters}
        />
      </div>
    </Modal>
  );
};

export default TransactionsFilter;
