export const CardMainContainer = `w-full`;
export const CardHeaderWrapper = `flex items-center mb-4 3xl:mb-6 4xl:mb-8`;
export const IconWrapper = `mr-2`;
export const HeadTitle = `text-white font-bold text-lg xxxs:text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl tracking-widest capitalize`;
export const CardScrollContainer = `flex overflow-x-auto overflow-y-hidden flex-nowrap space-x-4 3xl:space-x-6 pr-4`;
export const CardContainer = `w-64 xxxs:w-72 xl:w-80 3xl:w-96 5xl:w-[35rem] rounded-md 3xl:rounded-lg 5xl:rounded-xl bg-primary-bg-2 p-4 5xl:p-8 flex-none`;
export const CardTitle = `text-white font-normal tracking-wider text-sm 3xl:text-lg 4xl:text-xl 5xl:text-2xl 6xl:text-3xl`;
export const CardSubTitle = `text-white font-light text-xs mb-3 mt-0.5 flex`;
export const OddValueWrapper = `rounded-md w-20 px-2 pt-1.5 pb-1 flex flex-col justify-center items-center text-sm bg-card-bg-2 text-text-gray-2 font-semibold mr-0`;
export const OddLabelStyle = `text-xxxs uppercase leading-3 font-normal sm:text-xs text-text-gray-2`;
export const OddValueStyle = `text-sm bg-card-bg-2 text-text-gray-2 font-semibold`;
export const OddValueContainer = `flex space-x-3 mt-3`;
export const ExploreAll = `text-sm xxxs:text-base xl:text-lg text-white font-bold tracking-widest`;
export const ExploreMatchContainer = `my-6 text-center`;
export const CardInnerContainer = ({ $isDisabled }: { $isDisabled: boolean }) =>
  `flex flex-col justify-between h-full ${$isDisabled ? ' opacity-25' : ''}`;
export const IconSize = `w-4.5 3xl:w-6 4xl:w-7`;
export const LostBadge = `flex-none h-7 text-center uppercase text-sm text-text-danger-1 font-bold rounded-md px-2 py-1 bg-danger-bg-2`;
export const WonBadge = `flex-none h-7 text-center uppercase text-sm text-text-success-1 font-bold rounded-md px-2 py-1 bg-success-bg-2`;
