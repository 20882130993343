import React from 'react';
import { ApplyBtn, ButtonWrapper, ClearBtn } from './FiltersButton.styled';
import Button from '#/components/common/Button';

type FiltersButtonProps = {
  clearFilters: () => void;
  applyFilters: () => void;
};

const FiltersButton: React.FC<FiltersButtonProps> = ({
  clearFilters,
  applyFilters,
}) => {
  return (
    <div className={ButtonWrapper}>
      <Button className={ClearBtn} onClick={clearFilters}>
        Clear All
      </Button>
      <Button className={ApplyBtn} onClick={applyFilters}>
        Apply
      </Button>
    </div>
  );
};

export default FiltersButton;
