export const GroupContainer = `mb-3 lg:mb-0 bg-card-bg-1 w-full text-left sm:rounded-md 5xl:rounded-lg 6xl:rounded-2xl lg:[&>*:nth-child(3)]:rounded-t-lg lg:[&>*:last-child]:rounded-b-lg`;
export const SeeAll = `text-sm lg:text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl 6xl:text-4xl p-4 cursor-pointer 4xl:pr-8`;
export const SeeAllWrapper = `flex justify-end items-center`;
export const MobDashOddNames = `text-xs 3xl:text-base 4xl:text-lg 5xl:text-2xl 6xl:text-3xl text-text-gray-2 px-3.5 md:px-4`;
export const MobDashOddWrapper = ({
  $isLoggedIn,
}: {
  $isLoggedIn: boolean;
}) => `justify-end flex px-3 md:px-4 py-2 align-baseline lg:hidden sticky top-26 z-1
    ${$isLoggedIn ? ' !px-10 md:px-12' : ''}`;
export const MobOddNamesSeparator = `h-0 lg:hidden border border-solid border-t-0 border-card-bg-2`;

export const GroupMatchesCardContainer = `isolate lg:isolation-auto`;
export const TitleText = `text-sm text-white font-normal`;
export const Title = `text-sm text-white font-medium mr-2`;
export const TitleContainer = `flex px-4 py-4`;
// Style for V2
export const GroupWrapper = `mb-3 lg:mb-0 rounded-md bg-primary-bg-2 p-4`;
export const TitleWrapper = `flex pb-4`;
export const MobDashOddContainer = ({
  $isLoggedIn,
}: {
  $isLoggedIn: boolean;
}) => `justify-between flex py-2 lg:hidden
    ${$isLoggedIn ? ' ' : ''}`;
export const MobDashboardOddNames = `text-sm 3xl:text-base 4xl:text-lg 5xl:text-2xl 6xl:text-3xl text-text-gray-2 uppercase`;
export const MobOddNameSeparator = `h-0 lg:hidden border border-solid border-t-0 border-card-bg-2 mb-4`;
export const HomeOddsName = `text-text-gray-1 font-thin text-xs xxxs:text-sm uppercase w-14 xxs:w-16 xs:w-20 text-center inline-block`;
export const HomeOddsWrapper = `flex justify-between w-44 xxxs:w-56 xs:w-64 space-x-3`;
