interface CollapseContenProp {
  expand: boolean;
}
export const CollapseCardContainer = `relative`;
export const CollapseCardHeader = `aria-expanded:bg-[url('/v2-icons/arrowup.svg')] 5xl:aria-expanded:bg-sm
aria-expanded:content-['']
aria-expanded:bg-no-repeat aria-expanded:bg-[right_top_8px]
aria-[expanded=false]:bg-[url('/v2-icons/arrowdown.svg')]
aria-[expanded=false]:content-['']
aria-[expanded=false]:bg-no-repeat aria-[expanded=false]:bg-[right_top_8px] 5xl:aria-[expanded=false]:bg-sm
block text-sm lg:text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl 6xl:text-4xl text-text-gray-1 font-medium leading-tight ease-in-out relative w-full text-left`;

export const CollapseCardHeaderElement = `${CollapseCardHeader}block cursor-pointer text-xs lg:text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl text-text-gray-1 font-normal leading-tight ease-in-out relative w-full text-left`;

export const CollapseContent = ({ expand }: CollapseContenProp) => `
  ${expand ? ' block visible' : 'hidden'}`;

export const CollapseCardNode = `rounded-lg 5xl:rounded-2xl text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl text-text-gray-1 w-full border border-transparent relative py-2`;

export const CollapseCardChild = `block bg-transparent`;
