import { DEFAULT_ODD_TYPE } from '#/constants/common/global-constants';
import {
  BeHouseText,
  FlexContainerBeHouse,
  ForWebFlex,
  HouseIcon,
  MatchCardWrapper,
  MatchContainer,
  MatchDivider,
} from './MatchCard.styled';
import MatchLabelCard from '../MatchLabel/MatchLabelCard.component';
import DetailsCard from '../MatchDetails/MatchDetails.component';
import { useState } from 'react';
import DashboardMarket from '#/components/common/MarketComponent/DashboardMarket.component';
import ActionTag from '#/components/common/ActionTags.component';
import {
  outcomeId,
  type FixtureSummary,
  type Market,
  type Odds,
} from '#/utils/backend';
import {
  MINUS_CIRCLE_ICON,
  PLUSH_CIRCLE_ICON,
} from '#/constants/common/images.constants';
import MatchHouseDetails from '../MatchHouseDetails/MatchHouseDetails.component';
import { useAppDispatch } from '#/utils/store';
import { selectOrDeselect as selectOrDeselectHouse } from '#/utils/slices/houseSlipsSlice';
import { selectOrDeselect as selectOrDeselectBet } from '#/utils/slices/betSlipsSlice';

type MatchProps = {
  fixture: FixtureSummary;
  oddType?: number;
};

export function getDashMarket(fixture: FixtureSummary, market: Market) {
  return {
    fixtureId: fixture.fixtureId,
    fixtureName: fixture.fixtureName,
    houseProvision: fixture.houseProvision,
    odds: fixture.odds,
    marketTypeId: fixture.marketTypeId,
    marketTypeName: fixture.marketTypeName,
    id: market.id,
    teams: fixture.teams,
    fixtureStatus: fixture.fixtureStatus,
    marketStatus: fixture.marketStatus,
    league: fixture.league,
    startDate: fixture.startDate,
  };
}

export default function MatchCard({
  fixture,
  oddType = parseInt(DEFAULT_ODD_TYPE.key, 10),
}: MatchProps) {
  const [showHouseCard, setShowHouseCard] = useState(false);
  const dispatch = useAppDispatch();

  function addHouseSlip(market: FixtureSummary) {
    dispatch(
      selectOrDeselectHouse({
        fixture: {
          id: fixture.id.toString(),
        },
        market: {
          id: market.id,
          name: market.marketTypeName,
          marketTypeId: market.marketTypeId,
        },
        homeTeam: {
          name: fixture.teams.Home.Name,
        },
        awayTeam: {
          name: fixture.teams.Away.Name,
        },
        houseProvisions: {
          liquidityOnMarket: market.acceptableProvisionSize
        }
      }),
    );
  }

  function addBetSlip(market: ReturnType<typeof getDashMarket>, odd: Odds) {
    dispatch(
      selectOrDeselectBet({
        fixture: {
          id: market.fixtureId.toString(),
        },
        market: {
          id: market.id,
          name: market.marketTypeName,
          marketTypeId: market.marketTypeId,
        },
        outcome: {
          id: outcomeId(odd),
          position: odd.outcomePosition,
          name: odd.outcomeName,
          acceptedRate: odd.value,
        },
        homeTeam: {
          name: market.teams.Home.Name,
        },
        awayTeam: {
          name: market.teams.Away.Name,
        },
        slippageParams: {
          slippage: '0',
          odds: odd.value,
        },
        betProvisions: {
          acceptableBetSize: odd.acceptableBetSize
        }
      }),
    );
  }

  return (
    <>
      <div
        className={MatchContainer({
          $isDisabled: fixture.fixtureStatus !== 'NotStarted',
          $isMainMarketFixture: false,
        })}
      >
        <div className={`${MatchCardWrapper} mb-3`} key={fixture?.id}>
          <div className={ForWebFlex}>
            <MatchLabelCard
              homeTeam={fixture.teams.Home.Name}
              awayTeam={fixture.teams.Away.Name}
              fixtureUID={fixture.fixtureId.toString()}
              status={fixture.fixtureStatus}
            />
            <DetailsCard
              fixture={fixture}
              isMainMarketFixture={false}
              isMainMarketCard={false}
            />
          </div>
          <div className="w-full lg:w-auto lg:flex-1">
            <DashboardMarket
              market={fixture}
              oddType={oddType}
              addBetSlip={addBetSlip}
            />
          </div>
        </div>

        <ActionTag
          ariaExpanded={showHouseCard}
          type="div"
          id={'show_house_stats'}
          onClick={() => setShowHouseCard(!showHouseCard)}
          className={FlexContainerBeHouse}
        >
          <img
            className={HouseIcon}
            src={showHouseCard ? MINUS_CIRCLE_ICON : PLUSH_CIRCLE_ICON}
            alt={showHouseCard ? 'Collapse' : 'Expand'}
          />
          <span className={BeHouseText}>Be The House</span>
        </ActionTag>
      </div>
      {showHouseCard ? (
        <MatchHouseDetails
          market={fixture}
          fixtureID={fixture.fixtureId.toString()}
          addHouseSlip={addHouseSlip}
        />
      ) : (
        <hr className={MatchDivider} />
      )}
    </>
  );
}
