import { twMerge } from 'tailwind-merge';
import { ICON_POSITION } from './Button.types';

// Basic button styles
export const BasicButtonStyles = `select-none whitespace-nowrap text-center`;

// Dynamic styling based on props
export const dynamicButtonStyles = ({
  isLoading,
  hasIcon,
  iconPosition,
  className,
}: {
  isLoading: boolean;
  hasIcon: boolean;
  iconPosition: 'left' | 'right';
  className?: string;
}) =>
  twMerge(
    BasicButtonStyles,
    hasIcon ? `flex justify-around items-center` : '',
    ICON_POSITION[iconPosition],
    isLoading
      ? 'cursor-wait'
      : 'disabled:cursor-not-allowed disabled:opacity-50',
    className,
  );

// Styles for button with loading state
export const LoadingButtonStyles = `relative block flex items-center space-around`;

// Spinner loader styles for the button
export const SpinnerLoaderStyles = `w-4 h-4 text-white mr-2`;

// Styles for button icons
export const IconStyles = (iconClass: string) => `${iconClass}`;

// Exporting as module
export default {
  dynamicButtonStyles,
  LoadingButtonStyles,
  SpinnerLoaderStyles,
  IconStyles,
  ICON_POSITION,
};
