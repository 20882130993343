/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useAppDispatch, useAppSelector } from '#/utils/store';
import SlipsDrawer from './SlipsDrawer.component';
import {
  AmountText,
  ButtonAccept,
  ButtonReject,
  CardInnerContainer,
  CheckInput,
  CheckLabel,
  ClearButton,
  CloseIcon,
  ContainerScroll,
  ErrorWrapper,
  FlexCenter,
  FlexWrapper,
  InnerContainer,
  InputField,
  OddInfoSubText,
  OddInfoText,
  OddsWrapper,
  PlaceBetBtn,
  PlaceHouseBtn,
  Separator,
  SgeText,
  SlipsSeparator,
  TeamNameText,
  TextError,
  TextOdds,
  TotalAmountText,
} from './style';
import * as betSlipsEvents from '#/utils/slices/betSlipsSlice';
import * as houseSlipsEvents from '#/utils/slices/houseSlipsSlice';
import { useState, useEffect } from 'react';
import { Big } from 'bigdecimal.js';
import { stringToBigdecimal } from '#/utils/bigdecimal';
import Button from '#/components/common/Button';
import { CLOSE_ICON } from '#/constants/common/images.constants';
import { PartialExecuteInstruction, useExecute } from '#/hooks/useExecute';
import useUserData from '#/hooks/useUserData';
import { useKycStatus } from './Components/useKycStatus';
import useOutcomesReloader from '#/utils/dataLoaders/useOutcomesReloader';
import { toast } from 'react-toastify';
import { getMarketNameById, getOutcomeName } from '#/utils/marketsMap';
import { convertToSge } from '#/utils/sgeUtils/sgeUtils';
import ApprovePopup from './Components/ApprovePopup/ApprovePopup.component';
import { useAccount } from 'graz';
import useBalance from '#/hooks/useBalance';
import { setIsModalOpen } from '#/utils/slices/walletConnectionSlice';
import useBetsDataLoader from '#/utils/dataLoaders/useBetsDataLoader';
import { AppToast } from '../common/ToastNotification/ToastConfig';
import extractBetIdsFromResult from './SlipsDrawer.utils';

interface ErrorProps {
  children: React.ReactNode;
}

function Error({ children }: ErrorProps): JSX.Element {
  return (
    <div className={ErrorWrapper}>
      <span className={TextError}>{children}</span>
    </div>
  );
}


export default function SlipsDrawers(): JSX.Element {
  const betSlips = useAppSelector((state) => state.betSlips);
  const houseSlips = useAppSelector((state) => state.houseSlips);
  const outcomes = useAppSelector((state) => state.outcomes.outcomes);
  const { executeMultiple } = useExecute();
  const { isConnected: isWalletConnected } = useAccount();
  const { data: balance } = useBalance();
  const betSlipItems = Object.values(betSlips);
  const houseSlipItems = Object.values(houseSlips);
  const [betSlipsAccepted, setBetSlipsAccepted] = useState(false);
  const [houseSlipsAccepted, setHouseSlipsAccepted] = useState(false);
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const userId = useAppSelector((state) => state.users.auth0Id);
  const [isPolling, setIsPolling] = useState(false); // To control polling
  const { data: betsData, refetch } = useBetsDataLoader(userId, isPolling ? 3000 : undefined); // Poll every 3 seconds only when isPolling is true
  const [betIds, setBetIds] = useState<string[]>([]); // Track placed bet IDs

  // Check for bet state changes and batch toast notifications
  useEffect(() => {
    if (betsData && betIds.length > 0) {
      let allBetsResolved = true; // Track if all bets have reached a final state
      const activeBets = [];
      const failedBets = [];

      // Check for each betId if the bet has reached a terminal state
      betIds.forEach((betId) => {
        const newBet = betsData.find((bet) => bet.id === betId); // Match by bet ID

        if (newBet) {
          if (newBet.state === 'Active') {
            activeBets.push(newBet.id); // Collect "Active" bets
          } else if (newBet.state === 'Failed') {
            failedBets.push(newBet.id); // Collect "Failed" bets
          } else {
            allBetsResolved = false; // If any bet is not in terminal state, keep polling
          }
        } else {
          allBetsResolved = false; // Bet not found, keep polling
        }
      });

      // If all bets are resolved, send a single notification
      if (allBetsResolved) {
        // Send batch notifications for all bets that were resolved
        if (activeBets.length > 0) {
          toast.success(<AppToast id={activeBets.length === 1 ? 'BetPlaced' : 'BetsPlaced'} />, { icon: false });
        }

        if (failedBets.length > 0) {
          setTimeout(() => toast.error(<AppToast id={failedBets.length === 1 ? 'BetFailed' : 'BetsFailed'} />, { icon: false }), 1000);
        }
        // Stop polling and reset states
        setIsPolling(false);
        setBetIds([]); // Clear betIds for next set of bets
      }
    }
  }, [betsData, betIds]);


  const balanceSge =
    stringToBigdecimal(convertToSge(balance?.amount.toString() || '0')) ??
    Big('0');

  const dispatch = useAppDispatch();

  const { isAuthenticated } = useUserData();
  const { kycToken, isLoading: isKycLoading } = useKycStatus();

  useOutcomesReloader({
    outcomeIds: Object.keys(betSlips),
    marketIds: Object.values(betSlips).map((betSlip) => betSlip.slip.market.id),
  });

  const minimumBetSlipAmount = Big('0.05');
  const minimumHouseSlipAmount = Big('0.05');

  const totalBetSlipAmount = betSlipItems.reduce(
    (acc, val) => acc.add(stringToBigdecimal(val.amount) || Big('0')),
    Big('0'),
  );

  const isPlacingBets = isWalletConnected
    ? isAuthenticated && !!kycToken && !isKycLoading
    : false;

  const anyBetSlipsErrors = betSlipItems.some((item) => {
    const outcomeValue = outcomes[item.slip.outcome.id]?.value;
    return (
      item.amount === '0' ||
      (stringToBigdecimal(item.amount) || Big('0')).lowerThan(
        minimumBetSlipAmount,
      ) || stringToBigdecimal(item.amount)?.greaterThanOrEquals(item.slip.betProvisions.acceptableBetSize) ||
      item.slip.outcome.acceptedRate !== outcomeValue
    );
  });

  const isBetSlipButtonDisabled = isPlacingBets
    ? anyBetSlipsErrors ||
    totalBetSlipAmount.greaterThan(balanceSge ?? Big('0'))
    : false;

  const anyHouseSlipsErrors = houseSlipItems.some(
    (item) =>
      item.amount === '0' ||
      (stringToBigdecimal(item.amount) || Big('0')).lowerThan(
        minimumHouseSlipAmount,
      ) || stringToBigdecimal(item.amount)?.greaterThanOrEquals(item.slip.houseProvisions.liquidityOnMarket),
  );

  const betetSlipsTotals = betSlipItems.reduce(
    (acc, betSlipItem) => {
      const amount = stringToBigdecimal(betSlipItem.amount) || Big('0');
      const rate =
        stringToBigdecimal(betSlipItem.slip.outcome.acceptedRate) || Big('0');

      return {
        total: acc.total.add(amount),
        returns: acc.returns.add(amount.multiply(rate)),
      };
    },
    {
      total: Big('0'),
      returns: Big('0'),
    },
  );

  const totalHouseSlipsDespositAmount = houseSlipItems.reduce(
    (acc, houseSlipItem) => {
      const amount = stringToBigdecimal(houseSlipItem.amount) || Big('0');

      return acc.add(amount);
    },
    Big('0'),
  );

  const isHouseSlipButtonDisabled = isPlacingBets
    ? anyHouseSlipsErrors ||
    !houseSlipsAccepted ||
    totalHouseSlipsDespositAmount.greaterThan(balanceSge)
    : false;

  const [openIndex, setOpenIndex] = useState(-1);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  const handleProvideLiquidity = async () => {
    if (!isWalletConnected) {
      dispatch(setIsModalOpen(true));
      return;
    }

    setLoading(true);
    const timeout = setTimeout(() => {
      setShowApprovePopup(true);
    }, 1000);
    try {
      const msgs = houseSlipItems.map((houseSlip) => {
        const { slip } = houseSlip;

        const msg: PartialExecuteInstruction = {
          msg: {
            house_provide_liquidity: {
              token: kycToken,
              market_id: String(slip.market.id),
            },
          },
          funds: [
            {
              amount: String(Number(houseSlip.amount) * 10 ** 6),
              denom: 'usge',
            },
          ],
        };

        return msg;
      });

      await executeMultiple(msgs, {
        onSuccess: () => {
          toast.success('House Provision Successful');
          dispatch(houseSlipsEvents.clear());
          setLoading(false);
          setShowApprovePopup(false);
        },
      });
    } catch (error) {
      clearTimeout(timeout);
      console.error('Error submitting house slip:', error);
      setLoading(false);
      setShowApprovePopup(false);
    }
  };

  const handleSubmitBet = async () => {
    if (!isWalletConnected) {
      dispatch(setIsModalOpen(true));
      return;
    }

    setLoading(true);
    const timeout = setTimeout(() => {
      setShowApprovePopup(true);
    }, 1000);
    try {
      const msgs = betSlipItems.map((betSlip) => {
        const { slip } = betSlip;
        const slippage = !betSlipsAccepted
          ? {
            slippage: slip.slippageParams?.slippage || '0',
            odds: slip.slippageParams?.odds || slip.outcome.acceptedRate,
          }
          : null;
        const msg: PartialExecuteInstruction = {
          msg: {
            submit_bet: {
              market_id: String(slip.market.id),
              outcome: slip.outcome.position,
              slippage,
              token: kycToken,
              participations: [],
              unlocks: [],
            },
          },
          funds: [
            {
              amount: String(Number(betSlip.amount) * 10 ** 6),
              denom: 'usge',
            },
          ],
        };

        return msg;
      });

      await executeMultiple(msgs, {
        onSuccess: (result) => {
          const betIds = extractBetIdsFromResult(result);
          dispatch(betSlipsEvents.clear());
          setLoading(false);
          setShowApprovePopup(false);
          setIsPolling(true); // Start polling after placing bet
          refetch();
          toast.success(<AppToast id={betIds.length === 1 ? "ProcessingBet" : "ProcessingBets"} />, { icon: false });
          if (betIds && betIds.length > 0) {
            setBetIds((prevBetIds) => [...prevBetIds, ...betIds]); // Store new bet IDs
          }
        },
      });
    } catch (error) {
      clearTimeout(timeout);
      console.error('Error submitting bet:', error);
      setLoading(false);
      setShowApprovePopup(false);
    }
  };

  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(async () => {
        // Poll for new odds
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [loading]);

  const getButtonContent = (suffix: string) => {
    if (loading || isKycLoading) {
      return (
        <>
          <div className="flex items-center mr-4">
            <div className="PageLoader !w-4 !h-4"></div>
          </div>
          Loading...
        </>
      );
    }

    if (!isAuthenticated) {
      return `Login to ${suffix}`;
    }

    if (!isWalletConnected) {
      return `Connect Wallet to ${suffix}`;
    }

    if (!kycToken) {
      return 'KYC Required';
    }

    return suffix;
  };

  return (
    <div
      className={`${CardInnerContainer} ${openIndex !== -1 ? '!-bottom-0.5 lg:!bottom-0' : ''}`}
    >
      <div>
        {betSlipItems.length > 0 && (
          <>
            {showApprovePopup ? (
              <ApprovePopup isOpen={true} onClose={() => null} />
            ) : (
              <>
                <SlipsDrawer
                  index={0}
                  isCollapsed={openIndex === 0}
                  onToggle={handleToggle}
                  count={betSlipItems.length}
                  heading={'Bet Slip'}
                >
                  <div className={InnerContainer}>
                    <div className="text-right mb-4">
                      <Button
                        className={ClearButton}
                        onClick={() => {
                          dispatch(betSlipsEvents.clear());
                        }}
                      >
                        Remove All
                      </Button>
                    </div>
                    <div className={Separator} />
                    <div className={`${ContainerScroll} ContainerScrollNone`}>
                      {betSlipItems.map((betSlip) => {
                        const amount =
                          stringToBigdecimal(betSlip.amount) || Big('0');
                        const currentRate =
                          outcomes[betSlip.slip.outcome.id]?.value;
                        const { slip } = betSlip;
                        return (
                          <div key={betSlip.slip.outcome.id}>
                            <div className={`${FlexWrapper} mb-4`}>
                              <h2 className={OddInfoText}>
                                {' '}
                                {`${getOutcomeName(betSlip.slip.market.marketTypeId, betSlip.slip.outcome.position, betSlip.slip.homeTeam.name, betSlip.slip.awayTeam.name)} @ ${betSlip.slip.outcome.acceptedRate}`}
                              </h2>
                              <Button
                                className={ClearButton}
                                onClick={() => {
                                  dispatch(
                                    betSlipsEvents.selectOrDeselect(
                                      betSlip.slip,
                                    ),
                                  );
                                }}
                              >
                                <img
                                  className={CloseIcon}
                                  src={CLOSE_ICON}
                                  alt="Close Icon"
                                />
                              </Button>
                            </div>
                            <div className={`${FlexWrapper} mb-4 flex-wrap`}>
                              <div className="flex-1 pr-2">
                                <h3
                                  className={OddInfoSubText}
                                >{`${getMarketNameById(betSlip.slip.market.marketTypeId) || betSlip.slip.market.name}`}</h3>
                                <p className={TeamNameText}>
                                  {' '}
                                  {`${betSlip.slip.homeTeam.name} v ${betSlip.slip.awayTeam.name}`}
                                </p>
                              </div>
                              <span className="relative">
                                <input
                                  className={InputField}
                                  type="number"
                                  min="0"
                                  value={
                                    betSlip.amount === '0' ? '' : betSlip.amount
                                  }
                                  placeholder="Amount"
                                  onChange={(e) => {
                                    dispatch(
                                      betSlipsEvents.updateAmount({
                                        outcomeId: betSlip.slip.outcome.id,
                                        amount: e.target.value,
                                      }),
                                    );
                                  }}
                                  onKeyDown={(
                                    e: React.KeyboardEvent<HTMLInputElement>,
                                  ) => {
                                    if (e.key === 'Enter') {
                                      (e.target as HTMLInputElement).blur();
                                    }
                                  }}
                                />
                                {betSlip.amount === '0'
                                  ? ''
                                  : betSlip.amount !== '0' && (
                                    <span className={SgeText}>SGE</span>
                                  )}
                              </span>

                              {isWalletConnected && amount.lowerThan(minimumBetSlipAmount) && (
                                <Error>
                                  Minimum amount is{' '}
                                  {minimumBetSlipAmount.toString()} SGE
                                </Error>
                              )}
                              {isWalletConnected && balanceSge.lowerThan(totalBetSlipAmount) && (
                                <Error>Insufficient wallet balance</Error>
                              )}
                              {isWalletConnected && stringToBigdecimal(betSlip?.amount)?.greaterThan(slip?.betProvisions?.acceptableBetSize) && (
                                <Error>{`Max bet amount for this is ${Number(slip?.betProvisions?.acceptableBetSize).toFixed(4)} SGE`}</Error>
                              )}
                              {currentRate ? (
                                currentRate ===
                                  betSlip.slip.outcome.acceptedRate ? (
                                  <></>
                                ) : (
                                  <div className={OddsWrapper}>
                                    <Error>
                                      <div className={TextOdds}>
                                        The odds have changed from
                                        <br />{' '}
                                        {
                                          betSlip.slip.outcome.acceptedRate
                                        } to {currentRate}.
                                      </div>
                                      <div className="text-center space-x-4">
                                        <Button
                                          className={ButtonAccept}
                                          onClick={() => {
                                            dispatch(
                                              betSlipsEvents.updateAcceptedRate(
                                                {
                                                  outcomeId:
                                                    betSlip.slip.outcome.id,
                                                  acceptedRate: currentRate,
                                                },
                                              ),
                                            );
                                          }}
                                        >
                                          Accept
                                        </Button>
                                        <Button
                                          className={ButtonReject}
                                          onClick={() => {
                                            dispatch(
                                              betSlipsEvents.selectOrDeselect(
                                                betSlip.slip,
                                              ),
                                            );
                                          }}
                                        >
                                          Reject
                                        </Button>
                                      </div>
                                    </Error>
                                  </div>
                                )
                              ) : (
                                // this breaks the layout
                                <></>
                              )}
                            </div>

                            <div className={Separator} />
                          </div>
                        );
                      })}
                    </div>
                    <div className="mt-2">
                      <div className={FlexCenter}>
                        <input
                          className={CheckInput}
                          type="checkbox"
                          id="accept-txn-odd-change"
                          checked={betSlipsAccepted}
                          onChange={() => {
                            setBetSlipsAccepted(!betSlipsAccepted);
                          }}
                        />
                        <label
                          htmlFor="accept-txn-odd-change"
                          className={CheckLabel}
                        >
                          Accept all odds and line changes
                        </label>
                      </div>
                    </div>
                    <div className={`${FlexWrapper} mt-3`}>
                      <span className={TotalAmountText}>Total Bet Amount:</span>
                      <span className={AmountText}>
                        <span>{betetSlipsTotals.total.toString()}</span>{' '}
                        <span>SGE</span>{' '}
                      </span>
                    </div>
                    <div className={`${FlexWrapper} mt-1`}>
                      <span className={TotalAmountText}>
                        Total Estimated Return:
                      </span>
                      <span className={AmountText}>
                        <span>{betetSlipsTotals.returns.toString()} </span>{' '}
                        <span>SGE</span>
                      </span>
                    </div>
                    <div className="my-5 flex justify-center">
                      <Button
                        className={PlaceBetBtn({
                          isBetSlipButtonDisabled,
                        })}
                        onClick={handleSubmitBet}
                        disabled={isBetSlipButtonDisabled}
                      >
                        <div className="flex justify-center items-center">
                          {getButtonContent('Place Bet')}
                        </div>
                      </Button>
                    </div>
                  </div>
                </SlipsDrawer>
                <div className={SlipsSeparator} />
              </>
            )}
          </>
        )}
      </div>
      <div>
        {houseSlipItems.length > 0 && (
          <>
            <SlipsDrawer
              index={1}
              isCollapsed={openIndex === 1}
              onToggle={handleToggle}
              count={houseSlipItems.length}
              heading={'House Slip'}
            >
              <div className={InnerContainer}>
                <div className="text-right mb-4">
                  <Button
                    className={ClearButton}
                    onClick={() => {
                      dispatch(houseSlipsEvents.clear());
                    }}
                  >
                    Remove All
                  </Button>
                </div>

                <div className={Separator} />
                <div className={`${ContainerScroll} ContainerScrollNone`}>
                  {houseSlipItems.map((houseSlip) => {
                    const amount =
                      stringToBigdecimal(houseSlip.amount) || Big('0');

                    return (
                      <div key={houseSlip.slip.market.id}>
                        <div className={`${FlexWrapper} mb-4`}>
                          <h2 className={OddInfoText}>
                            {' '}
                            {getMarketNameById(
                              houseSlip.slip.market.marketTypeId,
                            ) || houseSlip.slip.market.name}
                          </h2>
                          <Button
                            className={ClearButton}
                            onClick={() => {
                              dispatch(
                                houseSlipsEvents.selectOrDeselect(
                                  houseSlip.slip,
                                ),
                              );
                            }}
                          >
                            <img
                              className={CloseIcon}
                              src={CLOSE_ICON}
                              alt="Close Icon"
                            />
                          </Button>
                        </div>
                        <div
                          className={`${FlexWrapper} mb-4 items-center flex-wrap`}
                        >
                          <div className="flex-1 pr-2">
                            <p className={TeamNameText}>
                              {' '}
                              {`${houseSlip.slip.homeTeam.name} - ${houseSlip.slip.awayTeam.name}`}
                            </p>
                          </div>
                          <span className="relative">
                            <input
                              className={InputField}
                              type="number"
                              min="0"
                              value={
                                houseSlip.amount === '0' ? '' : houseSlip.amount
                              }
                              placeholder="Amount"
                              onChange={(e) => {
                                dispatch(
                                  houseSlipsEvents.updateAmount({
                                    marketId: houseSlip.slip.market.id,
                                    amount: e.target.value,
                                  }),
                                );
                              }}
                            />
                            {houseSlip.amount === '0'
                              ? ''
                              : houseSlip.amount !== '0' && (
                                <span className={SgeText}>SGE</span>
                              )}
                          </span>

                          {isWalletConnected && amount.lowerThan(minimumHouseSlipAmount) && (
                            <Error>
                              Minimum amount is{' '}
                              {minimumHouseSlipAmount.toString()} SGE
                            </Error>
                          )}
                          {isWalletConnected && balanceSge.lowerThan(
                            totalHouseSlipsDespositAmount,
                          ) && <Error>Insufficient wallet balance</Error>}
                          {isWalletConnected && stringToBigdecimal(houseSlip?.amount)?.greaterThan(houseSlip.slip.houseProvisions.liquidityOnMarket) && (
                            <Error>{`Maximum liquidity on the market is ${houseSlip.slip.houseProvisions.liquidityOnMarket} SGE`}</Error>
                          )}
                        </div>
                        <div className={Separator} />
                      </div>
                    );
                  })}
                </div>
                <div className={FlexCenter}>
                  <input
                    className={CheckInput}
                    type="checkbox"
                    id="accept-txn-odd-change"
                    checked={houseSlipsAccepted}
                    onChange={() => {
                      setHouseSlipsAccepted(!houseSlipsAccepted);
                    }}
                  />
                  <label htmlFor="accept-txn-odd-change" className={CheckLabel}>
                    Accept all odds and line changes
                  </label>
                </div>
                <div className={`${FlexWrapper} mt-3`}>
                  <span className={TotalAmountText}>Total Deposit Amount:</span>
                  <span className={AmountText}>
                    {totalHouseSlipsDespositAmount.toString()} SGE
                  </span>
                </div>
                <div className="my-5">
                  <Button
                    className={PlaceHouseBtn({
                      isHouseSlipButtonDisabled,
                    })}
                    onClick={() => handleProvideLiquidity()}
                    disabled={isHouseSlipButtonDisabled}
                  >
                    {getButtonContent('Provide Liquidity')}
                  </Button>
                </div>
              </div>
            </SlipsDrawer>
            <div className={SlipsSeparator} />
          </>
        )}
      </div>
    </div>
  );
}
