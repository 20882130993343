import { toast } from 'react-toastify';
import { AppToast } from '../common/ToastNotification/ToastConfig';
import Deposit from '../Deposit/Deposit.component';
import { copyToClipboard } from '#/utils/string/string';
import EmptyList from '../common/EmptyStates/EmptyState.component';
import { useAccount } from 'graz';

export default function WalletDeposit() {
  const { data: account, isConnected } = useAccount();
  const address = account?.bech32Address;

  async function copyAddress(address: string) {
    await copyToClipboard(address);
    toast.error(<AppToast id="AddressCopiedSuccess" />, { icon: false });
  }

  if (!address || !isConnected) {
    return <EmptyList id="walletDeposit" />;
  }

  return (
    <div className="px-4">
      <Deposit address={address} copyAddress={copyAddress} />
    </div>
  );
}
