/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

// Define the types for the props
type DataRecord<T> = Record<string, T>;

interface WithDataProps<T> {
  data: DataRecord<T> | undefined;
  isLoading: boolean;
  id: keyof DataRecord<T>;
  component: React.ComponentType<{ data: T } & any>; // Child component
  shimmerComponent: React.ComponentType<{
    title?: string;
    icon?: string;
    header?: string;
  }>;
  passFullData?: boolean; // Optional prop with default value false
}

// HOC function
function WithData<T>(
  WrappedComponent: React.ComponentType<{ data: T } & any>,
  ShimmerComponent: React.ComponentType<{
    title?: string;
    icon?: string;
    header?: string;
  }>,
  passFullData = false,
) {
  // Define the enhanced component
  const WithDataComponent: React.FC<WithDataProps<T> & any> = (props) => {
    const { data, isLoading, id, ...rest } = props;

    if (isLoading) {
      return (
        <ShimmerComponent title={id} icon={rest.icon} header={rest.header} />
      );
    }

    if (passFullData && data) {
      return <WrappedComponent data={data} {...rest} />;
    }

    if (!passFullData && data && data[id]) {
      return <WrappedComponent data={data[id]} {...rest} />;
    }

    return null;
  };

  // Set the display name for the HOC
  WithDataComponent.displayName = `WithData(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return WithDataComponent;
}

export default WithData;
