import { useEffect, useState } from 'react';
import useUserData from '#/hooks/useUserData';

const useAccessToken = () => {
  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { getAccessToken, isAuthenticated } = useUserData();

  useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated) {
        try {
          const accessToken = await getAccessToken();
          setToken(accessToken);
        } catch (err) {
          console.error(err);
          setError(err as Error);
        }
      } else {
        setToken(null);
      }
      setIsLoading(false);
    };

    fetchToken();
  }, [isAuthenticated, getAccessToken]);

  return { token, isLoading, error };
};

export default useAccessToken;
