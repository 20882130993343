interface FilterListItemProp {
  isActive: boolean;
}

export const FilterCardContainer = `block bg-transparent max-h-36	overflow-y-scroll`;

export const FilterList = `list-none max-h-60 overflow-x-hidden overflow-y-scroll`;
export const FilterListItemContainer = `py-2 lg:py-2 4xl:py-4 5xl:py-6 lg:cursor-pointer flex items-center justify-between`;

export const FilterListItem = ({
  isActive,
}: FilterListItemProp) => `w-full text-xs lg:text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl 6xl:text-4xl text-white flex justify-between font-light
  ${
    isActive
      ? ' w-full text-xs lg:text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl 6xl:text-4xl font-light'
      : ''
  }`;

// Style for V2
export const HeaderWrapper = `flex justify-between items-center`;
export const Title = `text-base text-text-gray-1 font-light uppercase`;
export const RemoveText = `text-sm 3xl:text-sm 4xl:text-base 5xl:text-lg 6xl:text-xl text-white font-normal`;
export const HeadDivider = `h-0 border border-solid border-t-0 border-border-bg-1 mt-2 mb-0`;
export const FilterListItemText = ({
  isActive,
}: FilterListItemProp) => `w-full text-sm lg:text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl 6xl:text-4xl text-white flex items-center font-normal
  ${isActive ? ' text-white' : ''}`;
export const MarketItemContainer = `pb-3 lg:py-2 4xl:py-4 5xl:py-6 lg:cursor-pointer flex items-center`;
export const MarketLabel = `text-sm lg:text-base text-white font-normal ml-2 capitalize`;
export const CheckInput = `size-4 6xl:size-5 mr-2 appearance-none rounded-sm  border border-text-gray-2 focus:outline-none align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer checked:bg-brand-bg-1 checked:bg-[url('/v2-icons/check-icon-dark.svg')] checked:border-brand-bg-1 checked:bg-[length:10px_10px]`;
export const TopLeague = `text-base font-light uppercase -ml-4`;
