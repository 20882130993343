import Button from '#/components/common/Button';
import {
  CardContainer,
  CardHeader,
  InnerContainer,
  DisconnectBtn,
  AddressText,
  AvailBalance,
  DecimalText,
  AvailBalanceText,
  RewardsBalance,
  TotalDecimalText,
  CardFooter,
  IconContainer,
  CardFooterIcon,
  CopyIcon,
  FlexWrapper,
  RewardsBalanceText,
} from './WalletDetailsCard.styled';
import {
  COPY_ICON,
  DEPOSIT_ICON,
  FILE_ICON,
} from '#/constants/common/images.constants';
import RedirectLink from '#/components/common/RedirectLink.component';
import AnimatedModal from '../common/AnimatedModal.component';
import { copyToClipboard } from '#/utils/string/string';
import { toast } from 'react-toastify';
import { AppToast } from '../common/ToastNotification/ToastConfig';
import { convertToSge } from '#/utils/sgeUtils/sgeUtils';
import { useAccount, useDisconnect } from 'graz';
import useBalance from '#/hooks/useBalance';

const WalletDetailsCard = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (item: boolean) => void;
}) => {
  const { disconnect } = useDisconnect();
  const { data: account, walletType } = useAccount();
  const address = account?.bech32Address;
  const { data: balance } = useBalance();

  async function copyAddress(address: string) {
    await copyToClipboard(address);
    toast.error(<AppToast id="AddressCopiedSuccess" />, { icon: false });
  }

  if (!walletType || !address || !balance) {
    return null;
  }

  if (!isOpen) {
    return null;
  }

  const balanceSge = convertToSge(balance?.amount.toString() || '0').split('.');
  return (
    <AnimatedModal title="" isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className={CardContainer}>
        <div className={CardHeader}>
          <div className={FlexWrapper}>
            <RedirectLink
              className={AddressText}
              href=""
              target="_blank"
              rel="noreferrer"
              id="sportsbook_transaction_explorer_link"
            >{`${address.slice(0, 10)}....${address.slice(
              address.length - 5,
              address.length,
            )}`}</RedirectLink>
            <Button onClick={() => copyAddress(address)}>
              <img className={CopyIcon} src={COPY_ICON} alt="Copy icon" />
            </Button>
          </div>
          <div>
            <Button
              className={DisconnectBtn}
              id="wallet_modal_disconnect_click"
              onClick={disconnect}
            >
              Disconnect
            </Button>
          </div>
        </div>
        <>
          <div>
            <div className={AvailBalance}>
              <span>
                {balanceSge[0]}
                {balanceSge.length > 1 ? (
                  <span className={DecimalText}>
                    .{balanceSge[1].slice(0, 4)}
                  </span>
                ) : null}
              </span>
              <span className="ml-1">SGE</span>
            </div>
            <div className={AvailBalanceText}>Available balance</div>
          </div>
          <div className={InnerContainer}>
            <div className="mb-6 px-4 pt-2">
              <div className={RewardsBalance}>
                <span>300</span>
                <span>SGE</span>
              </div>
              <div className={`${RewardsBalanceText} !px-0`}>
                Rewards (*R) balance
              </div>
            </div>
            <div className="px-4 py-4">
              <div className={RewardsBalance}>
                <span>
                  12463.<span className={TotalDecimalText}>78364</span>
                </span>
                <span className="ml-1">SGE</span>
              </div>
              <div className={`${RewardsBalanceText} !px-0`}>Total balance</div>
            </div>
            <div className={CardFooter}>
              <RedirectLink className={IconContainer} href={'/deposit'}>
                <img
                  className={CardFooterIcon}
                  src={DEPOSIT_ICON}
                  alt="Deposit icon"
                />
                <span>Deposit</span>
              </RedirectLink>
              <RedirectLink className={IconContainer} href={'/transactions'}>
                <img
                  className={CardFooterIcon}
                  src={FILE_ICON}
                  alt="Transactions icon"
                />
                <span>Transactions</span>
              </RedirectLink>
            </div>
          </div>
        </>
      </div>
    </AnimatedModal>
  );
};

export default WalletDetailsCard;
