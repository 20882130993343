import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KycStatus } from '../backend';

export interface UserState {
  isLoading: boolean;
  sgeAddress: string | null;
  username: string | null;
  image: string | null;
  email: string | null;
  wallet: string | null;
  walletBalance: number;
  language: string;
  oddsFormat: 'decimal' | 'fractional' | 'american';
  responsibleGamblingTimeLimit: number | null;
  name: string | null;
  notifications: UserNotification[];
  kycSessionId: string | null;
  kycStatus: KycStatus | null;
  responsibleGamblingDepositLimit: number | null;
  responsibleGamblingLossLimit: number | null;
  timezone: string;
  currency: string;
  referralCode: string | null;
  auth0Id: string | null;
  isEmailVerified: boolean;
}

export interface UserNotification {
  id: string;
  title: string;
  message: string;
  type:
    | 'Invalid'
    | 'BetSettlement'
    | 'OrderbookResolution'
    | 'HouseSettlement'
    | 'Withdrawal'
    | 'WagerProcessing'
    | 'DepositProcessing'
    | 'RewardProcessing'
    | 'RewardProcessed';
  priority: 'Info' | 'Warning' | 'Success' | 'Error';
  read: boolean;
  createdAt: string;
}

const initialState: UserState = {
  isLoading: true,
  sgeAddress: null,
  kycSessionId: null,
  kycStatus: null,
  wallet: null,
  language: 'en',
  oddsFormat: 'decimal',
  responsibleGamblingTimeLimit: null,
  notifications: [],
  walletBalance: 0,
  responsibleGamblingDepositLimit: null,
  responsibleGamblingLossLimit: null,
  timezone: 'UTC',
  currency: 'USD',
  referralCode: null,
  username: null,
  image: null,
  email: null,
  name: null,
  auth0Id: null,
  isEmailVerified: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Partial<UserState>>) => {
      return { ...state, ...action.payload };
    },
    clearUser: () => {
      return initialState;
    },
    addNotification: (state, action: PayloadAction<UserNotification>) => {
      state.notifications.push(action.payload);
    },
    markNotificationAsRead: (state, action: PayloadAction<string>) => {
      const notification = state.notifications.find(
        (n) => n.id === action.payload,
      );
      if (notification) {
        notification.read = true;
      }
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
    updateKycStatus: (state, action: PayloadAction<UserState['kycStatus']>) => {
      state.kycStatus = action.payload;
    },

    updateWalletBalance: (state, action: PayloadAction<number>) => {
      state.walletBalance = action.payload;
    },

    updateResponsibleGamblingLimits: (
      state,
      action: PayloadAction<{
        depositLimit?: number | null;
        lossLimit?: number | null;
        timeLimit?: number | null;
      }>,
    ) => {
      if (action.payload.depositLimit !== undefined) {
        state.responsibleGamblingDepositLimit = action.payload.depositLimit;
      }
      if (action.payload.lossLimit !== undefined) {
        state.responsibleGamblingLossLimit = action.payload.lossLimit;
      }
      if (action.payload.timeLimit !== undefined) {
        state.responsibleGamblingTimeLimit = action.payload.timeLimit;
      }
    },

    updateUserPreferences: (
      state,
      action: PayloadAction<{
        timezone?: string;
        currency?: string;
        language?: string;
        oddsFormat?: 'decimal' | 'fractional' | 'american';
      }>,
    ) => {
      return { ...state, ...action.payload };
    },

    setReferralCode: (state, action: PayloadAction<string>) => {
      state.referralCode = action.payload;
    },
  },
});

export const {
  setUser,
  clearUser,
  addNotification,
  markNotificationAsRead,
  clearNotifications,
  updateKycStatus,
  updateWalletBalance,
  updateResponsibleGamblingLimits,
  updateUserPreferences,
  setReferralCode,
} = userSlice.actions;

export default userSlice.reducer;
