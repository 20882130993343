/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from '@tanstack/react-query';
import { TransactionsData, loadTransactions } from '../backend';
// import { useChain } from '@cosmos-kit/react';
// import { useAppSelector } from '../store';
import env from '../env';
import { useTendermintClient } from 'graz';

const useTransactionsDataLoader = (
  page: number = 0,
  pageSize: number = 10,
  address?: string,
) => {
  const { data: signingClient } = useTendermintClient({
    type: "tm37",
    chainId: env.chainInfo.chainId,
    multiChain: false
  })

  return useQuery<TransactionsData>({
    queryKey: ['transactions', page, pageSize],
    queryFn: () => loadTransactions(page, pageSize, address, signingClient),
  });
};

export default useTransactionsDataLoader;
