import React from 'react';
import AnimatedModal from './AnimatedModal.component';

interface GenericModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<GenericModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
}) => {
  return (
    <AnimatedModal title={title} isOpen={isOpen} onClose={onClose}>
      <div>{children}</div>
    </AnimatedModal>
  );
};

export default Modal;
