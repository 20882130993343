import {
  NotificationHeader,
  PageTitle,
  TabFilterItems,
  TabsWrapper,
} from './Notifications.styled';

interface NotificationsFilterTabsProps {
  selectedFilter: string;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
}

const NotificationsFilterTabs: React.FC<NotificationsFilterTabsProps> = ({
  selectedFilter,
  setSelectedFilter,
}) => {
  const tabs = [
    { label: 'All', value: '' },
    { label: 'House', value: 'House' },
    { label: 'Betting', value: 'Betting' },
    { label: 'Other', value: 'Other' },
  ];

  return (
    <>
      <div className={NotificationHeader}>
        <h2 className={PageTitle}>Notifications</h2>
      </div>
      <div className={TabsWrapper}>
        {tabs.map((tab) => (
          <div
            key={tab.value}
            className={TabFilterItems({
              isActive: selectedFilter === tab.value,
            })}
            onClick={() => setSelectedFilter(tab.value)}
          >
            {tab.label}
          </div>
        ))}
      </div>
    </>
  );
};

export default NotificationsFilterTabs;
