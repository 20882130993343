import {
  BET_EMPTYDATA_ERROR_ICON,
  EMPTY_DATA_ICON,
  HOUSE_EMPTYDATA_ERROR_ICON,
  NOTIFICATIONS_EMPTYDATA_ERROR_ICON,
  REWARDS_EMPTYDATA_ERROR_ICON,
  TRANSACTION_ERROR_ICON,
} from '#/constants/common/images.constants';
import Link from '../Link.component';
const MainContainer = `flex h-full w-full justify-center items-center flex-col`;
const InnerContainer = `flex justify-center items-center flex-col my-20`;
const Icon = `w-16 mb-5`;
const Title = `text-base font-normal text-white mb-2`;
const SubTitle = `text-sm font-normal text-text-gray-1`;
const ExploreText = `text-base font-bold text-white`;

const EmptyListConfig = {
  bets: {
    title: 'Your bets will appear here',
    subText: 'Make your first bet to see it listed here.',
    iconSrc: BET_EMPTYDATA_ERROR_ICON,
    buttonText: '',
    buttonLink: '',
    buttonId: '',
  },
  houseDeposits: {
    title: 'Your house activities will appear here',
    subText: 'Make your first engagement to see it listed here.',
    iconSrc: HOUSE_EMPTYDATA_ERROR_ICON,
    buttonText: '',
    buttonLink: '',
    buttonId: '',
  },
  notifications: {
    title: 'Your notifications will appear here',
    subText: 'Check back here for updates.',
    iconSrc: NOTIFICATIONS_EMPTYDATA_ERROR_ICON,
    buttonText: '',
    buttonLink: '',
    buttonId: '',
  },
  rewards: {
    title: 'Your rewards will appear here',
    subText: 'Start exploring to earn rewards!',
    iconSrc: REWARDS_EMPTYDATA_ERROR_ICON,
    buttonText: '',
    buttonLink: '',
    buttonId: '',
  },
  search: {
    title: 'Swish and a miss!',
    subText: 'No results found. Try another query.',
    iconSrc: EMPTY_DATA_ICON,
    buttonText: '/explore',
    buttonLink: 'see_all_matches',
    buttonId: 'EXPLORE ALL MATCHES',
  },
  transactions: {
    title: 'Your transactions will appear here',
    subText: 'Make your first transaction to see it listed here.',
    iconSrc: TRANSACTION_ERROR_ICON,
    buttonText: '',
    buttonLink: '',
    buttonId: '',
  },
  walletDeposit: {
    title: 'No wallet connected',
    subText: 'Connect your wallet to see your wallet qr code here.',
    iconSrc: REWARDS_EMPTYDATA_ERROR_ICON,
    buttonText: '',
    buttonLink: '',
    buttonId: '',
  },
  homepage: {
    title: '',
    subText: 'No matches available',
    iconSrc: EMPTY_DATA_ICON,
    buttonText: '',
    buttonLink: '',
    buttonId: '',
  },
};

export default function EmptyList({
  id,
}: {
  id: keyof typeof EmptyListConfig;
}): JSX.Element {
  const { title, subText, iconSrc, buttonText, buttonLink, buttonId } =
    EmptyListConfig[id];
  return (
    <div className={MainContainer}>
      <div className={InnerContainer}>
        <div>
          <img className={Icon} src={iconSrc} alt="Icon" />
        </div>
        <div className="text-center">
          <h2 className={Title}>{title}</h2>
          <h3 className={SubTitle}>{subText}</h3>
        </div>
        {buttonText && buttonLink ? (
          <div className="text-center mt-20">
            <Link to={buttonLink} id={buttonId}>
              <p className={ExploreText}>{buttonText}</p>
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}
