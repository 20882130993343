import { toast } from 'react-toastify';
import { AppToast } from '../common/ToastNotification/ToastConfig';
import { Fixture, Market, Odds, outcomeId } from '#/utils/backend';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '#/utils/store';
import { getAllMarketCategories } from '#/utils/marketsMap';
import MatchDetailsHeader from '../MatchDetails/MatchDetails.component';
import FixtureTabs from '../MatchDetails/MatchDetailsFixtureTab/MatchDetailsFixtureTab.component';
import Markets from '../Markets';
import { selectOrDeselect as selectOrDeselectHouse } from '#/utils/slices/houseSlipsSlice';
import { selectOrDeselect as selectOrDeselectBet } from '#/utils/slices/betSlipsSlice';
import useFixtureData from '#/utils/dataLoaders/useFixtureData';

function MatchDetailComponent({
  result,
  isLoading,
}: {
  result?: Fixture;
  isLoading: boolean;
}) {
  const [fixtureTabs, setFixtureTabs] = useState(getAllMarketCategories([]));
  const [expanded, setExpandedCard] = useState(false);
  const [fixtureTabSelected, selectFixtureTab] = useState(fixtureTabs[0]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!isLoading && result) {
      setFixtureTabs(getAllMarketCategories(Object.keys(result.markets)));
    }
  }, [isLoading, result]);

  useEffect(() => {
    setExpandedCard(false);
  }, [fixtureTabSelected]);

  function addHouseSlip(market: Market) {
    if (!result) {
      toast.error(<AppToast id="UnableToFetch" />, { icon: false });
      return;
    }
    dispatch(
      selectOrDeselectHouse({
        fixture: {
          id: result.id.toString(),
        },
        market: {
          id: market.id,
          name: market.marketTypeName,
          marketTypeId: market.marketTypeId,
        },
        homeTeam: {
          name: result.teams.Home.Name,
        },
        awayTeam: {
          name: result.teams.Away.Name,
        },
        houseProvisions: {
          liquidityOnMarket: market.houseProvision.TotalLiquidity
        }
      }),
    );
  }

  function addBetSlip(market: Market, odd: Odds) {
    if (!result) {
      toast.error(<AppToast id="UnableToFetch" />, { icon: false });
      return;
    }
    dispatch(
      selectOrDeselectBet({
        fixture: {
          id: result.id.toString(),
        },
        market: {
          id: market.id,
          name: market.marketTypeName,
          marketTypeId: market.marketTypeId,
        },
        outcome: {
          id: outcomeId(odd),
          position: odd.outcomePosition,
          name: odd.outcomeName,
          acceptedRate: odd.value,
        },
        homeTeam: {
          name: result.teams.Home.Name,
        },
        awayTeam: {
          name: result.teams.Away.Name,
        },
        slippageParams: {
          slippage: '0',
          odds: odd.value,
        },
        betProvisions: {
          acceptableBetSize: odd.acceptableBetSize
        }
      }),
    );
  }

  return (
    <div className="px-0">
      <MatchDetailsHeader data={result} isLoading={isLoading} id={''} />
      <div className="w-full">
        <div className="px-4 mt-4">
          <FixtureTabs
            fixtureTabNames={fixtureTabs}
            selectFixtureTab={selectFixtureTab}
            fixtureTabSelected={fixtureTabSelected}
          />
        </div>
        <div className="pt-2 px-4">
          <Markets
            data={result}
            isLoading={isLoading}
            id={'markets'}
            expanded={expanded}
            setExpandedCard={setExpandedCard}
            fixtureTabSelected={fixtureTabSelected}
            addBetSlip={addBetSlip}
            addHouseSlip={addHouseSlip}
          />
        </div>
      </div>
    </div>
  );
}

export default function FixtureDetails() {
  const { fixtureId } = useParams();

  if (!fixtureId) {
    return <div>No fixture id</div>;
  }

  const { error, data, isLoading } = useFixtureData(fixtureId);

  if (error) {
    toast.error(<AppToast id={'UnableToFetch'} />, { icon: false });
  }
  return (
    <div>
      <MatchDetailComponent result={data} isLoading={isLoading || !!error} />
    </div>
  );
}
