import { defineChainInfo } from 'graz';

export const mainnet = defineChainInfo({
  chainId: 'sgenet-1',
  currencies: [
    {
      coinDenom: 'sge',
      coinMinimalDenom: 'usge',
      coinDecimals: 6,
      coinGeckoId: 'six-sigma',
    },
  ],
  rest: 'https://rest.sge.tcnetwork.io/',
  rpc: 'https://rpc.sge.tcnetwork.io/',
  bech32Config: {
    bech32PrefixAccAddr: 'sge',
    bech32PrefixAccPub: 'sgepub',
    bech32PrefixValAddr: 'sgevaloper',
    bech32PrefixValPub: 'sgevaloperpub',
    bech32PrefixConsAddr: 'sgevalcons',
    bech32PrefixConsPub: 'sgevalconspub',
  },
  chainName: 'sge',
  feeCurrencies: [
    {
      coinDenom: 'sge',
      coinMinimalDenom: 'usge',
      coinDecimals: 6,
      coinGeckoId: 'six-sigma',
      gasPriceStep: {
        low: 0.1,
        average: 0.25,
        high: 0.5,
      },
    },
  ],
  stakeCurrency: {
    coinDenom: 'sge',
    coinMinimalDenom: 'usge',
    coinDecimals: 6,
    coinGeckoId: 'six-sigma',
  },
  bip44: {
    coinType: 118,
  },
});

export const testnet = defineChainInfo({
  chainId: 'sge-network-4',
  currencies: [
    {
      coinDenom: 'sge',
      coinMinimalDenom: 'usge',
      coinDecimals: 6,
    },
  ],
  rest: 'https://api.testnet.sgenetwork.io/',
  rpc: 'https://rpc.testnet.sgenetwork.io/',
  bech32Config: {
    bech32PrefixAccAddr: 'sge',
    bech32PrefixAccPub: 'sgepub',
    bech32PrefixValAddr: 'sgevaloper',
    bech32PrefixValPub: 'sgevaloperpub',
    bech32PrefixConsAddr: 'sgevalcons',
    bech32PrefixConsPub: 'sgevalconspub',
  },
  chainName: 'sge-testnet',
  feeCurrencies: [
    {
      coinDenom: 'sge',
      coinMinimalDenom: 'usge',
      coinGeckoId: 'six-sigma',
      coinDecimals: 6,
    },
  ],
  stakeCurrency: {
    coinDenom: 'sge',
    coinMinimalDenom: 'usge',
    coinDecimals: 6,
  },
  bip44: {
    coinType: 118,
  },
});

export const staging = defineChainInfo({
  chainId: 'stage-sgenetwork',
  currencies: [
    {
      coinDenom: 'sge',
      coinMinimalDenom: 'usge',
      coinDecimals: 6,
    },
  ],
  rest: 'https://api.staging.sgenetwork.io/',
  rpc: 'https://rpc.staging.sgenetwork.io/',
  bech32Config: {
    bech32PrefixAccAddr: 'sge',
    bech32PrefixAccPub: 'sgepub',
    bech32PrefixValAddr: 'sgevaloper',
    bech32PrefixValPub: 'sgevaloperpub',
    bech32PrefixConsAddr: 'sgevalcons',
    bech32PrefixConsPub: 'sgevalconspub',
  },
  chainName: 'sge-staging',
  feeCurrencies: [
    {
      coinDenom: 'sge',
      coinMinimalDenom: 'usge',
      coinDecimals: 6,
      coinGeckoId: 'six-sigma',
    },
  ],
  stakeCurrency: {
    coinDenom: 'sge',
    coinMinimalDenom: 'usge',
    coinDecimals: 6,
  },
  bip44: {
    coinType: 118,
  },
});
