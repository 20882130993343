import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Sport } from '../backend';
import { SetURLSearchParams } from 'react-router-dom';

export type ActivityOutcome = 'Won' | 'Lost';

export const stringToActivityOutcome = (
  input: string,
): ActivityOutcome | undefined => {
  switch (input.toLowerCase()) {
    case 'won':
      return 'Won';
    case 'lost':
      return 'Lost';
  }
};

export type State = {
  search: string;
  outcomes: Record<ActivityOutcome, boolean>;
  sports: Record<Sport, boolean>;
  marketTypeIds: Record<number, boolean>;
};
const initialState: State = {
  search: '',
  outcomes: {} as Record<ActivityOutcome, boolean>,
  sports: {} as Record<Sport, boolean>,
  marketTypeIds: {} as Record<number, boolean>,
};

export const SPORT_URL_PARAM = 'sport';
export const OUTCOME_URL_PARAM = 'outcome';
export const MARKET_TYPE_ID_URL_PARAM = 'market';

const synchSportUrlParams = (
  sports: Record<Sport, boolean>,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
) => {
  searchParams.delete(SPORT_URL_PARAM);

  Object.keys(sports).forEach((sport) => {
    if (sports[sport as Sport] === true) {
      searchParams.append(SPORT_URL_PARAM, sport);
    }
  });

  setSearchParams(searchParams);
};

const synchOutcomeUrlParams = (
  outcomes: Record<ActivityOutcome, boolean>,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
) => {
  searchParams.delete(OUTCOME_URL_PARAM);

  Object.keys(outcomes).forEach((outcome) => {
    if (outcomes[outcome as ActivityOutcome] === true) {
      searchParams.append(OUTCOME_URL_PARAM, outcome);
    }
  });

  setSearchParams(searchParams);
};

const synchMarketTypeIdUrlParams = (
  marketTypeIds: Record<number, boolean>,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
) => {
  searchParams.delete(MARKET_TYPE_ID_URL_PARAM);

  Object.keys(marketTypeIds).forEach((marketTypeId) => {
    const key = marketTypeId as unknown as number;
    if (marketTypeIds[key] === true) {
      searchParams.append(MARKET_TYPE_ID_URL_PARAM, marketTypeId);
    }
  });

  setSearchParams(searchParams);
};

const slice = createSlice({
  name: 'activityFilters',
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setOutcomeFilters: (state, action: PayloadAction<ActivityOutcome[]>) => {
      action.payload.forEach((outcome) => {
        state.outcomes[outcome] = true;
      });

      // Does not update the URL search params because it's intended to be called after reading from them
    },
    toggleOutcomeFilterAndSynchUrl: (
      state,
      action: PayloadAction<{
        outcome: ActivityOutcome;
        searchParams: URLSearchParams;
        setSearchParams: SetURLSearchParams;
      }>,
    ) => {
      const outcome = action.payload.outcome;
      const current_value = state.outcomes[outcome] || false;
      state.outcomes[outcome] = !current_value;

      synchOutcomeUrlParams(
        state.outcomes,
        action.payload.searchParams,
        action.payload.setSearchParams,
      );
    },
    setSportFilters: (state, action: PayloadAction<Sport[]>) => {
      action.payload.forEach((sport) => {
        state.sports[sport] = true;
      });

      // Does not update the URL search params because it's intended to be called after reading from them
    },
    toggleSportFilterAndSynchUrl: (
      state,
      action: PayloadAction<{
        sport: Sport;
        searchParams: URLSearchParams;
        setSearchParams: SetURLSearchParams;
      }>,
    ) => {
      const sport = action.payload.sport;
      const current_value = state.sports[sport] || false;
      state.sports[sport] = !current_value;

      synchSportUrlParams(
        state.sports,
        action.payload.searchParams,
        action.payload.setSearchParams,
      );
    },
    setMarketTypeIdFilter: (state, action: PayloadAction<number[]>) => {
      action.payload.forEach((marketTypeId) => {
        state.marketTypeIds[marketTypeId] = true;
      });

      // Does not update the URL search params because it's intended to be called after reading from them
    },
    toggleMarketTypeIdFilterAndSynchUrl: (
      state,
      action: PayloadAction<{
        marketTypeId: number;
        searchParams: URLSearchParams;
        setSearchParams: SetURLSearchParams;
      }>,
    ) => {
      const marketTypeId = action.payload.marketTypeId;
      const current_value = state.marketTypeIds[marketTypeId] || false;
      state.marketTypeIds[marketTypeId] = !current_value;

      synchMarketTypeIdUrlParams(
        state.marketTypeIds,
        action.payload.searchParams,
        action.payload.setSearchParams,
      );
    },
    clearActivityFilters: (
      state,
      action: PayloadAction<{
        searchParams: URLSearchParams;
        setSearchParams: SetURLSearchParams;
      }>,
    ) => {
      state.sports = initialState.sports;
      state.outcomes = initialState.outcomes;
      state.marketTypeIds = initialState.marketTypeIds;

      synchSportUrlParams(
        state.sports,
        action.payload.searchParams,
        action.payload.setSearchParams,
      );
      synchOutcomeUrlParams(
        state.outcomes,
        action.payload.searchParams,
        action.payload.setSearchParams,
      );
      synchMarketTypeIdUrlParams(
        state.marketTypeIds,
        action.payload.searchParams,
        action.payload.setSearchParams,
      );
    },
  },
});

export const {
  setSearch,
  setOutcomeFilters,
  toggleOutcomeFilterAndSynchUrl,
  setSportFilters,
  toggleSportFilterAndSynchUrl,
  setMarketTypeIdFilter,
  toggleMarketTypeIdFilterAndSynchUrl,
  clearActivityFilters,
} = slice.actions;
export default slice.reducer;
