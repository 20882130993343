import MainMarketCard from './MatchCard/MainMarket/MainMarketCard.component';
import WithData from './Wrapper/WrapWithTransitionStates';
import { FixtureSummary, HomepageData } from '#/utils/backend';
import {
  LoaderBg,
  LoaderBtn,
  LoaderCardContainer,
} from './common/LoaderStyle/LoaderStyle.styled';

const CardContainer = `overflow-y-hidden rounded-md p-4 3xl:p-6 relative before:bg-no-repeat before:bg-[url('/v2-icons/marketoftheday-bg.png')] before:absolute before:left-0 before:top-0 before:w-full before:h-full before:bg-cover before:z-0 lg:before:!bg-[url('/v2-icons/marketoftheday-bg-desktop.png')]`;

const FixtureOfTheDayWithData = ({ data }: { data: FixtureSummary }) => {
  if (!data) return null;
  return (
    <div className={'px-4 pb-4 3xl:pb-6 4xl:pb-8'}>
      <div className={CardContainer}>
        <MainMarketCard mainMarket={data} oddType={1} />
      </div>
    </div>
  );
};

const LoadingComponent = () => {
  return (
    <><div className="px-4 mb-4 lg:hidden">
      <div className={LoaderCardContainer}>
        <div className={`${LoaderBg} h-10 w-3/4 mb-3`}></div>
        <div className={`${LoaderBg} h-7 w-2/3 mb-5`}></div>
        <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
        <div className={`${LoaderBg} h-5 w-1/3 mb-3`}></div>
        <div className="flex flex-col mb-3 space-y-2">
          <div className={`${LoaderBg} ${LoaderBtn}`}></div>
          <div className={`${LoaderBg} ${LoaderBtn}`}></div>
          <div className={`${LoaderBg} ${LoaderBtn}`}></div>
        </div>
      </div>
    </div><div className="px-4 mb-4 hidden lg:block">
        <div className={LoaderCardContainer}>
          <div className='flex justify-between'>
            <div className="w-full">
              <div className={`${LoaderBg} h-10 w-3/4 mb-3`}></div>
              <div className={`${LoaderBg} h-7 w-2/3 mb-5`}></div>
              <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
              <div className={`${LoaderBg} h-5 w-1/3 mb-3`}></div>
            </div>
            <div className="w-full flex flex-col mb-3 space-y-2">
              <div className={`${LoaderBg} ${LoaderBtn}`}></div>
              <div className={`${LoaderBg} ${LoaderBtn}`}></div>
              <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            </div>
          </div>
        </div>
      </div></>
  );
};

const FixtureOfTheDay = WithData<HomepageData>(
  FixtureOfTheDayWithData,
  LoadingComponent,
);

export default FixtureOfTheDay;
