export const AddressText = `text-base text-white font-normal`;
export const DisconnectBtn = `text-base text-black font-bold px-4 py-1.5 rounded-md bg-white text-center flex-none relative`;
export const AvailBalance = `flex space-x-3 text-3xl text-white font-bold px-4 mt-8`;
export const AvailBalanceText = `text-sm text-text-brand-1 font-normal px-4`;
export const DecimalText = `text-2xl text-white font-normal`;
export const CardContainer = `w-full bg-primary-bg-3 rounded-t-2xl -mt-4 pt-6`;
export const CardHeader = `flex justify-between px-4`;
export const RewardsBalance = `flex space-x-3 text-2xl text-white font-bold`;
export const TotalDecimalText = `text-lg text-white font-normal`;
export const InnerContainer = `w-full rounded-t-2xl bg-primary-bg-1 py-4 mt-3 divide-y divide-border-bg-1`;
export const CardFooter = `w-full flex justify-around pt-6`;
export const IconContainer = `w-full flex flex-col items-center text-xs text-white font-light`;
export const CardFooterIcon = `w-5 mb-2`;
export const CopyIcon = `w-4.5 ml-2`;
export const FlexWrapper = `flex items-center`;
export const RewardsBalanceText = `text-sm text-white font-normal px-4`;
