/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { BACK_ICON } from '#/constants/common/images.constants';
import { useSearchParams } from 'react-router-dom';
import {
  ContentContainer,
  ContentText,
  TabTitleText,
  FieldContainer,
  InputLabel,
  InputField,
} from '../ResponsibleGambling.styled';
import SaveButton from './SaveButton.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '#/utils/store';
import { setTimeoutPeriod } from '#/utils/slices/responsibleGamblingSlice';

export default function TimeOut() {
  const dispatch = useDispatch();
  const timePeriod = useSelector(
    (state: RootState) => state.responsibleGambling.timeoutPeriod,
  );

  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setTimeoutPeriod(e.target.value));
  };

  const [, setSearchParams] = useSearchParams();

  const handleBackClick = () => {
    setSearchParams({});
  };

  return (
    <div className={ContentContainer}>
      <h3 className={`${TabTitleText} mb-4`}>
        <img
          className="w-2.5 mr-2 cursor-pointer"
          src={BACK_ICON}
          alt="Back Icon"
          onClick={handleBackClick}
        />
        TIME OUT
      </h3>
      <p className={ContentText}>
        {`If you want to take a short break from gaming with us, you may do so by taking a Time-Out for a specified period of time. During this break, you won't be able to access your account or place bets. It's a responsible way to ensure you maintain control over your gaming activities. Rest assured, we're committed to promoting responsible gambling, and this feature is designed to help you make informed choices about your play.`}
      </p>
      <div className="mt-5">
        <div className={FieldContainer}>
          <label htmlFor="time_period" className={InputLabel}>
            Time Out Period
          </label>
          <select
            className={InputField}
            id="time_period"
            value={timePeriod}
            onChange={handlePeriodChange}
          >
            <option value="None">None</option>
            <option value="15 Days">15 Days</option>
            <option value="1 Month">1 Month</option>
            <option value="1 Year">1 Year</option>
          </select>
        </div>
        <SaveButton />
      </div>
    </div>
  );
}
