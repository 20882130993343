import { BetSlips, HouseSlips } from './Slip';

const BET_SLIPS_KEY = 'bet_slips';
const HOUSE_SLIPS_KEY = 'house_slips';

export const getBetSlips = (): string | null => {
  return window.localStorage.getItem(BET_SLIPS_KEY);
};

export const setBetSlips = (slips: BetSlips) => {
  window.localStorage.setItem(BET_SLIPS_KEY, JSON.stringify(slips));
};

export const getHouseSlips = (): string | null => {
  return window.localStorage.getItem(HOUSE_SLIPS_KEY);
};

export const setHouseSlips = (slips: HouseSlips) => {
  window.localStorage.setItem(HOUSE_SLIPS_KEY, JSON.stringify(slips));
};
