import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OutcomeLiveData } from '../backend';

export type Outcomes = Record<string, OutcomeLiveData>;

interface State {
  outcomes: Outcomes;
}

const initialState: State = {
  outcomes: {},
};

const slice = createSlice({
  name: 'outcomes',
  initialState,
  reducers: {
    updateOutcomes: (state, data: PayloadAction<Outcomes>) => {
      state.outcomes = {
        ...state.outcomes,
        ...data.payload,
      };
    },
  },
});

export const { updateOutcomes } = slice.actions;
export default slice.reducer;
