import { Odds } from "#/utils/backend";
import { BetSlips } from "#/utils/Slip";
import { ExecuteResult } from "@cosmjs/cosmwasm-stargate";

const extractBetIdsFromResult = (result: ExecuteResult): string[] => {
  const betIds: string[] = [];

  // Extracting events from the transaction result
  const events = result?.logs?.flatMap((log) => log.events) || [];

  // Look for the "wasm-bet-submission" event and extract the "id" attribute
  events.forEach((event) => {
    if (event.type === 'wasm-bet-submission') {
      event.attributes.forEach((attribute) => {
        if (attribute.key === 'id') {
          betIds.push(attribute.value);
        }
      });
    }
  });

  return betIds;
};

export const isOddSelected = (betSlips: BetSlips, odd: Odds): boolean => {
  return Object.values(betSlips).some(betSlipWithAmount => {
    const { slip } = betSlipWithAmount;
    return (
      slip.market.id === odd.marketId &&
      slip.outcome.position === odd.outcomePosition &&
      slip.outcome.acceptedRate === odd.value
    );
  });
};

export default extractBetIdsFromResult;
