import env from '#/utils/env';
import { useBalances } from 'graz';

const DENOM = 'usge';

const useBalance = () => {
  const { refetch, isLoading, data } = useBalances({
    chainId: env.chainInfo.chainId,
    denom: DENOM,
  });

  const balance = isLoading
    ? undefined
    : (data && data.find((coin) => coin.denom === DENOM)) || {
        denom: DENOM,
        amount: '0',
      };

  return {
    data: balance,
    isLoading,
    refetch,
  };
};

export default useBalance;
