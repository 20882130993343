/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FlexContainer,
  ColoredContainer,
  MainMarketFlexWrapper,
  MarketTag,
  MatchLeagueTime,
  ContainerBlock,
} from './MatchDetails.styled';
import Link from '#/components/common/Link.component';
import { formatDateToDesiredFormat } from '#/utils/datetime/datetime';
import { DATE_TIME_FORMATS } from '#/utils/datetime/datetime.constants';
import { FixtureSummary } from '#/utils/backend';

export type DetailsCardProp = {
  fixture: FixtureSummary;
  isMainMarketFixture: boolean;
  isMainMarketCard: boolean;
};

function DetailsChildCard({
  fixture,
  isMainMarketFixture,
  isMainMarketCard,
}: DetailsCardProp) {
  return (
    <>
      <div
        className={ContainerBlock({
          $isMainMarketFixture: isMainMarketFixture,
          $isMainMarketCard: isMainMarketCard,
        })}
      >
        {fixture?.fixtureName ? (
          <div className={MatchLeagueTime({ isMainMarketCard })}>
            {formatDateToDesiredFormat(
              fixture.startDate,
              DATE_TIME_FORMATS.DATE_TIME_2,
            )}
            &nbsp; | &nbsp;
          </div>
        ) : null}
        <div className={FlexContainer}>
          <div className={MatchLeagueTime({ isMainMarketCard })}>
            <Link to={''} prefetch="intent" id="redirect_to_league">
              {`${fixture.league.Name}`}
            </Link>
          </div>
        </div>
      </div>
      {isMainMarketFixture && !isMainMarketCard ? (
        <div className={MarketTag}> • MARKET OF THE DAY • </div>
      ) : null}
    </>
  );
}

export default function DetailsCard({
  fixture,
  isMainMarketFixture,
  isMainMarketCard,
}: DetailsCardProp) {
  return isMainMarketFixture && !isMainMarketCard ? (
    <div className={MainMarketFlexWrapper}>
      <DetailsChildCard
        fixture={fixture}
        isMainMarketFixture={isMainMarketFixture}
        isMainMarketCard={isMainMarketCard}
      />
    </div>
  ) : (
    <DetailsChildCard
      fixture={fixture}
      isMainMarketFixture={isMainMarketFixture}
      isMainMarketCard={isMainMarketCard}
    />
  );
}
