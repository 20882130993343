/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  AvatarImg,
  CloseIcon,
  EditIcon,
  FlexWrapper,
  MenuContainer,
  MenuDivider,
  MenuFooter,
  MenuHeader,
  MenuIcon,
  MenuIconActive,
  MenuItems,
  MenuItemsDesktopOnly,
  MenuItemsLogout,
  MenuItemsName,
  MenuItemsUserName,
  MenuList,
  SgeLogo,
  UserName,
} from './SideMenu.styled';
import { SideMenuProps } from './SideMenu.types';
import NavLink from '#/components/common/NavLink.component';
import ActionTag from '#/components/common/ActionTags.component';
import {
  CLOSE_ICON,
  LOG_OUT_ICON,
  EDIT_ICON,
} from '#/constants/common/images.constants';
import { useLocation } from 'react-router-dom';
import Button from '#/components/common/Button';
import { useDisconnect } from 'graz';
import Link from '#/components/common/Link.component';

const SideMenu: React.FC<SideMenuProps> = ({
  close,
  menuItems,
  user,
  logo,
  isAuthenticated,
  logout,
}) => {
  const { pathname } = useLocation();
  const { disconnect } = useDisconnect();
  const handleNavLinkClick = () => {
    close ? close() : null;
  };

  return (
    <div className={MenuContainer}>
      <div>
        <div className={MenuHeader}>
          <span>
            <Link to="/">
              <img className={SgeLogo} src={logo.src} alt={logo.alt} />
            </Link>
          </span>
          {close ? (
            <ActionTag type="span" onClick={close}>
              <img className={CloseIcon} src={CLOSE_ICON} alt="Close Icon" />
            </ActionTag>
          ) : null}
        </div>
        <div className="px-6 lg:px-8">
          <div className={MenuDivider} />
        </div>
        <div>
          <ul className={MenuList}>
            {menuItems.map((item, index) => (
              <li key={index}>
                <NavLink
                  className={
                    item.desktopOnly
                      ? MenuItemsDesktopOnly({
                          isActive: pathname
                            .toLowerCase()
                            .includes(item.to.toLowerCase()),
                        })
                      : MenuItems({
                          isActive: pathname
                            .toLowerCase()
                            .includes(item.to.toLowerCase()),
                        })
                  }
                  to={item.to}
                  prefetch="intent"
                  onClick={handleNavLinkClick}
                >
                  <span>
                    {pathname.toLowerCase().includes(item.to.toLowerCase()) ? (
                      <img
                        className={MenuIconActive}
                        src={item.iconActive}
                        alt={item.alt}
                      />
                    ) : (
                      <img
                        className={MenuIcon}
                        src={item.icon}
                        alt={item.alt}
                      />
                    )}
                  </span>
                  <span
                    className={MenuItemsName({
                      isActive: pathname
                        .toLowerCase()
                        .includes(item.to.toLowerCase()),
                    })}
                  >
                    {item.text}
                  </span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="mt-12">
        {isAuthenticated ? (
          <Button
            className={MenuItemsLogout}
            onClick={() => {
              logout({
                logoutParams: { returnTo: window.location.origin },
              });
              disconnect();
            }}
          >
            <span>
              <img
                className={MenuIcon}
                src={LOG_OUT_ICON}
                alt={'Log out icon'}
              />
            </span>
            <span className={MenuItemsUserName}>Log Out</span>
          </Button>
        ) : null}

        {user && (
          <>
            <div className="px-6 lg:px-8">
              <div className={`${MenuDivider} !mt-4`} />
            </div>
            <div className={MenuFooter}>
              <div className={FlexWrapper}>
                <span>
                  <img
                    className={AvatarImg}
                    src={user.image || ''}
                    alt={`${user.name}'s avatar`}
                  />
                </span>
                <div>
                  <div className={MenuItemsUserName}>{user.username}</div>
                  <div className={UserName}>{user.name}</div>
                </div>
              </div>
              <Link to="/edit-profile" id="edit_profile">
                <img
                  src={EDIT_ICON}
                  className={EditIcon}
                  alt={'Edit'}
                  onClick={close}
                />
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SideMenu;
