export const BlockContainer = ({
  $isMainMarketFixture,
  $isMainMarketCard,
}: {
  $isMainMarketFixture: boolean;
  $isMainMarketCard: boolean;
}) => `flex flex-wrap xl:flex-nowrap xl:whitespace-nowrap px-4 2xl:mt-0 3xl:mt-3 5xl:px-6
  ${
    $isMainMarketFixture
      ? ' 5xl:!pl-6 xl:!flex-wrap xl:!whitespace-wrap'
      : ' mt-2 5xl:pl-6 pb-5 5xl:pb-8 6xl:pb-12'
  }${$isMainMarketCard ? ' mt-4 2xl:mt-4 4xl:px-6 7xl:!px-8 relative z-0' : ''}
     ${$isMainMarketCard ? ' !ml-3 md:!ml-4' : ''}
      ${' ml-4 mb-0 !px-0 flex-wrap'}`;

export const FlexContainer = `flex`;
export const MatchTime = ({
  isMainMarketCard,
}: {
  isMainMarketCard: boolean;
}) =>
  `text-xs 2xl:text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl 6xl:text-3xl ${
    isMainMarketCard ? 'text-text-gray-1' : 'text-text-gray-2'
  } font-normal`;
export const ColoredContainer = `px-4 py-0 text-xs rounded-full bg-btn-success-1 text-white font-normal flex items-center w-max cursor-pointer`;
export const MainMarketFlexWrapper = `flex justify-between items-center mt-2 5xl:mt-4 pb-5 5xl:pb-8 6xl:pb-12 `;
export const MarketTag = `hidden lg:block text-center uppercase text-black lg:text-xxxs xl:text-xs 2xl:text-base 3xl:text-xl 5xl:text-2xl 6xl:text-3xl font-semibold 7xl:font-extrabold tracking-[.2em] md:tracking-[5px] lg:tracking-[2px] py-1 6xl:py-4 7xl:py-6 relative px-6 xl:px-10  min-w-[52%] xl:min-w-[45%] 6xl:min-w-[40%] animate-loaderline`;
// Style for v2
export const MatchLeagueTime = ({
  isMainMarketCard,
}: {
  isMainMarketCard: boolean;
}) =>
  `text-sm font-light 2xl:text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl 6xl:text-3xl ${
    isMainMarketCard ? '!text-base text-white-80' : 'text-white'
  } font-light`;
export const ContainerBlock = ({
  $isMainMarketFixture,
  $isMainMarketCard,
}: {
  $isMainMarketFixture: boolean;
  $isMainMarketCard: boolean;
}) => `flex w-full xl:flex-nowrap xl:whitespace-nowrap mt-3
  ${
    $isMainMarketFixture
      ? ' !mt-1 5xl:!pl-6 xl:!flex-wrap xl:!whitespace-wrap'
      : ' !mt-1 5xl:pl-6 pb-3 5xl:pb-8 6xl:pb-12'
  }${
    $isMainMarketCard
      ? ' lg:justify-end mt-4 2xl:mt-4 4xl:px-6 7xl:!px-8 relative z-0'
      : ''
  }
     ${$isMainMarketCard ? ' ml-0' : ''}
      ${' mb-0 !px-0 flex-wrap'}`;
