import ActivityCard from '#/components/common/ActivityCard/ActivityCard.component';
import { formatDateToDesiredFormat } from '#/utils/datetime/datetime';
import { HouseProvision } from '#/utils/backend';
import WithData from './Wrapper/WrapWithTransitionStates';
import {
  LoaderBg,
  LoaderCardContainer,
} from './common/LoaderStyle/LoaderStyle.styled';
import EmptyList from './common/EmptyStates/EmptyState.component';
import { useAppSelector } from '#/utils/store';
import { getMarketNameById } from '#/utils/marketsMap';
import { useExecute } from '#/hooks/useExecute';
import { toast } from 'react-toastify';
import { useKycStatus } from '#/components/SlipsDrawers/Components/useKycStatus';
import Button from '#/components/common/Button';

const Btn = `flex justify-center items-center rounded-md bg-card-bg-1 px-4 py-2 text-white font-normal text-sm xxxs:text-base h-8 xxxs:h-9`;

function HouseActivityCardsWithData({
  searchInput,
  activeSubItem,
  data,
}: {
  searchInput: string;
  activeTab: string;
  activeSubItem: string;
  data: HouseProvision[];
}) {
  const activityFilters = useAppSelector((state) => state.activityFilters);
  const { executeMultiple, status } = useExecute();
  const { kycToken } = useKycStatus();

  const handleWithdraw = async (marketId: string) => {
    if (status === 'Executing Contract') {
      toast.info('A transaction is already in progress');
      return;
    }

    try {
      const msg = {
        msg: {
          house_withdraw_liquidity: {
            token: kycToken,
            market_id: marketId,
          },
        },
        funds: [],
      };

      await executeMultiple([msg], {
        onSuccess: () => {
          toast.success('Withdrawal successful');
        },
        onError: (error) => {
          toast.error(`Failed to withdraw: ${error.message}`);
        },
      });
    } catch (error) {
      console.error('Error during withdrawal:', error);
    }
  };

  const filteredData = data.filter((provision) => {
    let fitsTheTab;
    switch (activeSubItem) {
      case 'engagement':
        fitsTheTab = provision.state === 'Active';
        break;
      case 'liquidity':
        fitsTheTab =
          provision.state === 'Settled' || provision.state === 'Failed';
        break;
      case 'withdrawals':
        fitsTheTab = parseFloat(provision.withdrawnAmount) > 0;
        break;
      default:
        fitsTheTab = false;
    }

    const fitsSearch =
      searchInput.length > 0
        ? provision.fixtureName
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        : true;

    const allowedMarketTypeIds: number[] = [];
    Object.keys(activityFilters.marketTypeIds).forEach((marketTypeId) => {
      const typedMarketTypeId = parseInt(marketTypeId);
      if (activityFilters.marketTypeIds[typedMarketTypeId]) {
        allowedMarketTypeIds.push(typedMarketTypeId);
      }
    });

    const fitsMarketTypeId =
      allowedMarketTypeIds.length > 0
        ? allowedMarketTypeIds.includes(provision.marketTypeId)
        : true;

    return fitsTheTab && fitsSearch && fitsMarketTypeId;
  });

  if (!filteredData.length) {
    return <EmptyList id="houseDeposits" />;
  }

  return (
    <>
      {filteredData.map((provision) => {
        const statusType =
          provision.state === 'Settled'
            ? provision.profitOrLoss && parseFloat(provision.profitOrLoss) > 0
              ? 'success'
              : 'failure'
            : undefined;

        const formatDecimal = (value: string) => parseFloat(value).toFixed(2);

        return (
          <ActivityCard
            key={provision.id}
            statusText={provision.state}
            titleText={provision.state}
            statusType={statusType}
            dateText={formatDateToDesiredFormat(provision.createdAt)}
            title={getMarketNameById(provision.marketTypeId) || ''}
            subTitle={provision.fixtureName}
            transactionLink={provision.txHash}
            infoItems={[
              {
                label: 'Provided: ',
                value: `${formatDecimal(provision.amount)} SGE`,
              },
              {
                label: 'Win/Loss: ',
                value: provision.profitOrLoss
                  ? `${formatDecimal(provision.profitOrLoss)} SGE`
                  : 'N/A',
              },
              {
                label: 'Returned: ',
                value: `${formatDecimal(provision.withdrawnAmount)} SGE`,
              },
              { label: 'Bet Line: ', value: provision.marketBetLine || 'N/A' },
            ]}
          >
            {provision.state === 'Active' && (
              <Button
                className={Btn}
                onClick={() => handleWithdraw(provision.marketId.toString())}
                disabled={status === 'Executing Contract'}
              >
                {status === 'Executing Contract' ? 'Processing...' : 'Withdraw'}
              </Button>
            )}
          </ActivityCard>
        );
      })}
    </>
  );
}

const LoadingComponent = () => {
  return (
    <div className="px-0">
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5  w-11/12 mb-2`}></div>
          <div className="w-full flex items-center mb-2">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className="w-full flex items-center mb-2">
            <div className={`${LoaderBg} h-5 w-4/12 mr-24`}></div>
            <div className={`${LoaderBg} h-5 w-5/12`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-5/12`}></div>
        </div>
      </div>
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5  w-11/12 mb-2`}></div>
          <div className="w-full flex items-center mb-2">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className="w-full flex items-center mb-2">
            <div className={`${LoaderBg} h-5 w-4/12 mr-24`}></div>
            <div className={`${LoaderBg} h-5 w-5/12`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-5/12`}></div>
        </div>
      </div>
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5  w-11/12 mb-2`}></div>
          <div className="w-full flex items-center mb-2">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className="w-full flex items-center mb-2">
            <div className={`${LoaderBg} h-5 w-4/12 mr-24`}></div>
            <div className={`${LoaderBg} h-5 w-5/12`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-5/12`}></div>
        </div>
      </div>
    </div>
  );
};

const HouseActivityCards = WithData<HouseProvision[]>(
  HouseActivityCardsWithData,
  LoadingComponent,
  true,
);

export default HouseActivityCards;
