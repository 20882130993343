export const footerText = (year: number): string => {
  return `© ${year} SixSigmaSports and affiliated companies. All rights reserved.`;
};

export const IMG_ALT_TEXT = 'Icon';
export const REQUIRMENT_TEXT = 'Requirements';
export const LICENSE_TEXT = 'License';
export const USEFUL_LINK = 'Useful Links';
export const JOIN_COMMUNITY = 'Join the community';
export const HELP_TEXT = 'Help';
export const CONTACT_TEXT = 'Contact us';
export const RESPONSIBLE_GAMBLING = 'Responsible Gambling';
export const PRIVACY_POLICY = 'Privacy Policy';
export const COOKIES_TEXT = 'Cookies Settings';
export const TERMS_TEXT = 'Terms & Conditions';
export const FAQ_TEXT = 'FAQ';
export const BETTING_TEXT = 'Betting Rules';
export const BETTING_RULES_URL =
  'https://six-sigma-sports.gitbook.io/betting-rules/group-1/rules-by-sport';
export const TERMS_URL =
  'https://six-sigma-sports.gitbook.io/docs/application-terms-and-conditions';
export const RESPONSIBLE_GAMBLING_GITBOOK =
  'https://six-sigma-sports.gitbook.io/docs/responsible-gambling-resources';
export const CONTACT_URL = 'https://forms.gle/ccXaW4U4q5C3zvN1A';
export const GITBOOK_URL = 'https://six-sigma-sports.gitbook.io/docs';
export const FAQS_URL = 'https://six-sigma-sports.gitbook.io/docs/faq';
export const PRIVACY_POLICY_URL =
  'https://six-sigma-sports.gitbook.io/docs/privacy-policy';

export const SocialLinksData = [
  {
    href: 'https://sixsigmasports.medium.com/',
    dark_image: 'medium-logo',
    is_button: false,
    label: 'medium',
  },
  {
    href: 'https://t.me/SixSigmaSports',
    dark_image: 'telegram-logo',
    isButton: false,
    label: 'telegram',
  },
  {
    href: 'https://twitter.com/SixSigmaSports',
    dark_image: 'twitter-logo',
    isButton: false,
    label: 'twitter',
  },
  {
    href: 'https://six-sigma-sports.gitbook.io/docs',
    dark_image: 'gitbook-logo',
    isButton: false,
    label: 'gitbook',
  },
];
