/** Converts SGE to USGE */
export function convertToUsge(sgeValue: string) {
  return Math.round(Number(sgeValue) * 10 ** 6);
}

/** Convert currency from usge denom to sge */
export function convertToSge(usgeValue: string, addCurrency?: boolean) {
  if (Number.isNaN(Number(usgeValue))) return '';
  return addCurrency
    ? `${+usgeValue / 10 ** 6} SGE`
    : `${+usgeValue / 10 ** 6}`;
}

export function convertToSgeWithCurrency(usgeValue: string) {
  if (Number.isNaN(Number(usgeValue))) return '';
  return `${convertToSge(usgeValue)} SGE`;
}
