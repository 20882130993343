export const MainMatchContainer = `w-full lg:flex lg:justify-between`;
export const MatchCardHeader = `relative z-1 w-max flex items-center text-left text-text-brand-1 text-sm 3xl:text-base 5xl:text-2xl 6xl:text-3xl font-normal py-2.5 px-3 bg-success-bg-1 rounded-md 5xl:rounded-lg 6xl:rounded-2xl backdrop-opacity-5 backdrop-blur`;
export const MarketText = `text-white text-7xl lg:text-5xl 2lg:text-6xl 3xl:text-7xl 4xl:text-8xl font-bold relative`;
export const MainMatchTitle = `text-white text-5xl font-bold relative mt-1`;
export const WinnerIcon = `w-3.5 mr-2`;
export const MatchCardContainer = `flex flex-nowrap lg:items-center justify-between flex-row pt-2 md:pt-5 4xl:px-6 7xl:px-8 5xl:pt-8 6xl:pt-12 relative items-start xl:flex-row xl:items-center 2xl:items-baseline relative `;
export const MatchName = `text-lg xxxs:text-xl 3xl:text-2xl 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-white font-bold 7xl:mb-2 whitespace-nowrap lg:whitespace-normal xl:whitespace-nowrap`;
export const TeamLogoBox = `size-11 bg-white-40 backdrop-blur-sm rounded-lg flex items-center justify-center`;
export const TeamLogoBoxGray = `-ml-1.5`;
export const MainRightContainer = `relative`;
export const MatchNameContainer = `w-full flex flex-col lg:items-end`;
export const Flex1Container = `flex-1`;
export const TeamLogoContainer = `relative flex lg:absolute lg:left-0 lg:top-0`;
export const MatchHeaderWrapper = `w-full`;
export const MatchTitleWrapper = `flex items-center relative z-1`;
export const FlexColWrapper = `flex`;
export const Break = `md:hidden`;
export const FlexContainer = `flex`;
export const StarIcon = `w-4 3xl:w-6 6xl:w-9 h-4 3xl:h-6 6xl:h-9 ml-2 md:mx-2 self-center cursor-pointer`;
export const TeamJerseyImg = `w-7`;
export const LeaguesWrapper = `w-full mt-5 mb-4 relative z-1`;
export const LeagueName = `text-white text-2xl 3xl:text-3xl 4xl:text-4xl font-bold`;
export const MarketOddsWrapper = `w-full lg:w-2/4 mt-4 relative z-1 space-y-2`;
export const MarketOddsBtn = ($isSelected: boolean) => `w-full flex items-center justify-between rounded-md bg-black px-3 py-0.5 3xl:py-2 3xl:px-5 4xl:py-4 4xl:px-6 ${$isSelected ? 'bg-selected-color-1' : ''}`;
export const TeamName = `text-xl 3xl:text-2xl 4xl:text-4xl text-text-brand-1 font-normal whitespace-nowrap truncate max-w-[70%] xxxs:max-w-[72%]`;
export const WhiteTeamName = `text-xl 3xl:text-2xl 4xl:text-4xl text-white font-normal whitespace-nowrap truncate max-w-[70%] xxxs:max-w-[72%]`;
export const OddsValue = `text-3.5xl 4xl:text-4xl text-text-brand-1 font-bold whitespace-nowrap flex w-full justify-between items-center`;
export const WhiteOddsValue = `text-3.5xl 4xl:text-4xl text-white font-bold whitespace-nowrap flex w-full justify-between items-center`;
export const MarketIcon = `w-8.5 4xl:w-14 mr-2 4xl:mr-4`;
export const WrapperForDesktop = `w-full`;
