/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { BACK_ICON } from '#/constants/common/images.constants';
import { useSearchParams } from 'react-router-dom';
import {
  ContentContainer,
  ContentText,
  TabTitleText,
} from '../ResponsibleGambling.styled';
import SaveButton from './SaveButton.component';

export default function AccountDeactivate() {
  const [, setSearchParams] = useSearchParams();
  const handleBackClick = () => {
    setSearchParams({});
  };
  return (
    <div className={ContentContainer}>
      <h3 className={`${TabTitleText} mb-4`}>
        <img
          className="w-2.5 mr-2 cursor-pointer"
          src={BACK_ICON}
          alt="Back Icon"
          onClick={handleBackClick}
        />
        ACCOUNT DEACTIVATION
      </h3>
      <p className={ContentText}>
        {`If you feel you are at risk of developing a gambling problem or believe you currently have a gambling problem, please consider deactivating your account.
     This option is designed to provide you with a break from gambling activities on our platform. At Six Sigma Sports, we value your safety and well-being. 
    If you choose to deactivate your account, it means you're taking a proactive step towards addressing any gambling-related concerns you may have. It's a responsible choice that allows you to regain control over your gambling habits. Remember, responsible gambling is a priority for us, and we're here to support you every step of the way.`}
      </p>
      <SaveButton />
    </div>
  );
}
