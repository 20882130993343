export const MenuHeader = `flex justify-between mb-8 px-6 lg:px-8 4xl:px-12`;
export const SgeLogo = `w-48 4xl:w-80`;
export const CloseIcon = `w-7 mt-1`;
export const MenuIcon = `w-5 4xl:w-7 flex-none`;
export const MenuIconActive = `w-5 4xl:w-7 5xl:w-9 flex-none zoom-in-out-box`;
export const MenuList = `w-full space-y-2 4xl:space-y-3 5xl:space-y-5`;
export const MenuDivider = `w-full h-0 border border-solid border-t-0 border-border-bg-1 mt-8 mb-6 4xl:my-12`;
export const OddSelectWrapper = `w-full space-y-5 px-6 lg:px-8`;
export const OddWrapper = `flex justify-between`;
export const DropdownArrow = `rotate-180 ml-2 w-3`;
export const OddsItemsName = `flex items-center text-white text-base font-semibold tracking-wide`;
export const MenuFooter = `flex justify-between items-center mb-10 px-6 lg:px-8 4xl:px-12`;
export const EditIcon = `w-8`;
export const AvatarImg = `size-10 rounded-full bg-white p-1 mr-4`;
export const UserName = `text-white text-sm 4xl:text-lg 5xl:text-2xl font-light`;
export const FlexWrapper = `flex items-center`;
export const NavWrapper = `fixed h-full w-full left-0 top-0 p-4 z-1`;
export const MenuItemsLogout = `flex items-center space-x-4 px-6 lg:px-8 4xl:px-12 py-4`;
export const MenuItems = ({ isActive }: { isActive: boolean }) =>
  `flex items-center space-x-4 px-6 lg:px-8 py-4 4xl:py-6 4xl:px-12 lg:transition-all lg:hover:bg-success-bg-3  ${isActive ? 'bg-success-bg-3' : ''}`;
export const MenuItemsUserName = `text-white tracking-wide text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl font-normal`;
export const MenuItemsDesktopOnly = ({ isActive }: { isActive: boolean }) =>
  `hidden lg:flex items-center py-4 px-8 lg:px-8 4xl:py-6 4xl:px-12 space-x-4 4xl:space-x-6 hover:bg-success-bg-3 ${isActive ? 'bg-success-bg-3' : ''}`;
export const MenuItemsName = ({ isActive }: { isActive: boolean }) =>
  `text-white tracking-wide text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl transition-all font-normal ${isActive ? '!text-brand-bg-1 !font-bold' : ''}`;

export const MenuContainer = `w-full xl:w-[345px] 3xl:w-[380px] 4xl:w-[550px] 5xl:w-[650px] flex flex-col justify-between min-h-screen fixed h-full left-0 top-0 pt-6 pb-4 px-0 z-10 bg-primary-bg-2 xl:bg-black xl:border-r xl:border-border-bg-1 overflow-y-auto`;
export const ForDesktopLeftPanel = `w-[345px] hidden xl:block`;
export const RightContainer = `w-full xl:pl-[360px] 3xl:pl-[400px] xl:pr-3 3xl:pr-4 4xl:pl-[580px] 5xl:pl-[680px] 4xl:pr-6`;
