/* add documentation later */
export const DATE_TIME_FORMATS = {
  DATE_TIME_1: 'D MMM YYYY, dddd HH:mm',
  DATE_TIME_2: 'D MMM HH:mm',
  DAY_1: 'dddd',
  DATE_TIME_3: 'D MMMM YYYY',
  DATE_TIME_4: 'D MMM YYYY HH:mm',
  DATE_TIME_5: 'HH:mm',
  DATE_TIME_6: 'D MMM YYYY | HH:mm'
};

export const MILLISECONDS_IN = {
  DAY: 86400000,
  HOUR: 3600000,
  MINUTE: 60000,
  SECOND: 1000,
};

export enum TIME_UNITS {
  DAY = 'day',
  HOUR = 'hour',
  MINUTE = 'minute',
  SECOND = 'second',
  MILLISECOND = 'millisecond',
  MONTH = 'month',
  YEAR = 'year',
  WEEK = 'week',
}
