import {
  ContentContainer,
} from './Transactions.styled';
import { Transaction, TransactionsData } from '#/utils/backend';
import TransactionCard from './TransactionCard.component';
import WithData from '../Wrapper/WrapWithTransitionStates';
import {
  LoaderBg,
  LoaderBtn,
  LoaderCardContainer,
  LoaderSeparator,
} from '../common/LoaderStyle/LoaderStyle.styled';
import EmptyList from '../common/EmptyStates/EmptyState.component';

interface TransactionsProps {
  data: Transaction[];
}

function TransactionsWithData({ data }: TransactionsProps) {
  if (!data || data.length === 0) return (
    <div>
      <EmptyList id="transactions" />
    </div>);
  return (
      <div className={`${ContentContainer} mt-4`}>
        {data.map((transaction) => (
          <TransactionCard transaction={transaction} key={transaction.hash} />
        ))}
      </div>
  );
}

const LoadingComponent = () => {
  return (
    <div>
      <div className={LoaderCardContainer}>
        <div>
          <div className={`${LoaderBg} h-4 w-4/5 mb-1`}></div>
          <div className={`${LoaderBg} h-4 w-8/12 mb-2`}></div>
          <div className="flex space-x-2 mb-4">
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
          </div>
          <div className={`${LoaderBg} h-4 w-1/3`}></div>
        </div>
        <div className={LoaderSeparator} />
        <div>
          <div className={`${LoaderBg} h-4 w-4/5 mb-1`}></div>
          <div className={`${LoaderBg} h-4 w-8/12 mb-2`}></div>
          <div className="flex space-x-2 mb-4">
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
          </div>
          <div className={`${LoaderBg} h-4 w-1/3`}></div>
        </div>
        <div className={LoaderSeparator} />
        <div>
          <div className={`${LoaderBg} h-4 w-4/5 mb-1`}></div>
          <div className={`${LoaderBg} h-4 w-8/12 mb-2`}></div>
          <div className="flex space-x-2 mb-4">
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
          </div>
          <div className={`${LoaderBg} h-4 w-1/3`}></div>
        </div>
      </div>
    </div>
  );
};

const Transactions = WithData<TransactionsData>(
  TransactionsWithData,
  LoadingComponent,
);

export default Transactions;
