import {
  ContentContainer,
  CheckInput,
  HeaderWrapper,
  Title,
  HeadDivider,
  FilterCardContainer,
  MarketLabel,
  MarketItemContainer,
} from './Transactions.styled';

export default function StatusFilter() {
  return (
    <div className={ContentContainer}>
      <div className={HeaderWrapper}>
        <span className={Title}>Status</span>
      </div>
      <hr className={HeadDivider} />
      <div className={FilterCardContainer}>
        <ul>
          <li className={MarketItemContainer}>
            <input
              className={`${CheckInput} !mr-2`}
              type="checkbox"
              id="success"
            />
            <span className={MarketLabel}>Success</span>
          </li>
          <li className={MarketItemContainer}>
            <input
              className={`${CheckInput} !mr-2`}
              type="checkbox"
              id="failed"
            />
            <span className={MarketLabel}>Failed</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
