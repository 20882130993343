export const CardContainer = `w-full lg:flex lg:flex-col lg:flex-col-reverse`;
export const CardInnerContainer = `w-full rounded-t-2xl bg-primary-bg-3 lg:bg-primary-bg-4 px-6 pt-4 pb-6`;
export const CardHeader = `flex items-center justify-between`;
export const HeaderTitle = `text-lg xl:text-xl text-white font-normal tracking-wider`;
export const CloseIcon = `w-5`;
export const IconWrapper = `mt-3`;
export const CardIcon = `w-16 xl:w-20`;
export const ContentContainer = `w-full text-left mt-4`;
export const ContentTitle = `text-lg xl:text-xl text-white font-normal tracking-wider mb-3`;
export const ContentSubTitle = `text-sm text-white font-light tracking-wider`;
export const LearnLink = `text-base text-white font-bold tracking-wider underline underline-offset-4`;
export const SelectText = `text-sm text-white font-bold`;
export const WalletWrapper = `w-full rounded-md flex items-center bg-primary-bg-1 px-4 py-2`;
export const WalletPopular = `w-full rounded-md flex flex-col items-center bg-primary-bg-1 p-3`;
export const WalletIconWrapper = `mb-2`;
export const WalletMobIconWrapper = `mr-2`;
export const WalletIcon = `w-5`;
export const WalletIconPopular = `w-8 lg:w-10`;
export const WalletNameText = `text-sm text-white font-normal`;
export const FieldContainer = `w-full mb-4`;
export const BtnWrapper = `space-y-4`;
export const PopularWalletWrapper = `grid grid-cols-1 gap-4`;
export const InputLabel = `block text-base text-text-gray-2 font-thin`;
export const InputField = `w-full bg-primary-bg-1 text-sm text-white placeholder:opacity-45 focus:outline-none px-4 py-4 border-0 rounded-llg mt-2`;
export const OrSeparator = `flex justify-center items-center w-full border-t border-primary-bg-1`;
export const OrText = `text-base tracking-wider text-text-gray-2 font-thin -mt-3 bg-primary-bg-2 px-4`;
export const PlaceBetBtn = `w-full text-center sm:w-80 tracking-wider rounded-llg bg-transparent px-4 py-3 text-black font-semibold text-sm xxs:text-base bg-text-warning-1`;
export const UnterlineText = `underline underline-offset-1`;
export const IconContainer = `text-center my-8`;
export const SocialIcon = `w-7`;
export const CheckInput = `size-4 appearance-none rounded-sm border border-text-gray-2 focus:outline-none align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer checked:bg-brand-bg-1 checked:bg-[url('/v2-icons/check-icon-dark.svg')] checked:border-brand-bg-1 checked:bg-[length:10px_10px]`;
export const CheckLabel = `inline-block text-text-gray-2 text-sm font-normal`;
export const FlexCenter = `flex items-center`;

export const ModalHeader = `relative flex justify-between px-5 pt-4`;
export const ModalHeaderTitle = `text-lg text-white flex`;
export const CloseBtn = `absolute top-5 right-4 text-gray-500 hover:text-gray-700`;
export const SectionTitle = `text-base text-white font-normal tracking-wider mb-3`;
export const WalletOptionWrapper = `relative grid grid-cols-2 gap-4`;
