import { useEffect } from 'react';
import { loadKycToken } from '#/utils/backend';
import useAccessToken from './useAccessToken';
import { useQuery } from '@tanstack/react-query';
import useAuth0UserData from '#/utils/dataLoaders/useAuth0UserData';

export const useKycStatus = () => {
  const { data: user } = useAuth0UserData();

  const {
    token,
    isLoading: tokenLoading,
    error: tokenError,
  } = useAccessToken();

  const {
    data: kycData,
    error: kycError,
    isLoading: kycLoading,
  } = useQuery({
    queryKey: ['kyc_token', user?.sub],
    queryFn: async () => {
      if (token) {
        return loadKycToken(token);
      }
    },
    enabled: !!user?.sub && !!token,
  });

  useEffect(() => {
    if (kycError) {
      console.error(kycError);
    }
  }, [kycError]);

  const kycToken = kycData?.token || null;

  return {
    kycToken,
    isLoading: tokenLoading || kycLoading,
    error: tokenError || kycError,
  };
};
