import {
  DOWNARROW_BRAND_ICON,
  UPARROW_BRAND_ICON,
} from '#/constants/common/images.constants';
import { OddHeaderMultiple, CardHeader } from './MarketCard.styled';

interface CardCollapseProps {
  title: React.ReactNode | string;
  index: number;
  isCollapsed: boolean;
  onToggle: (index: number) => void;
  children: React.ReactNode;
}
const CardCollapse = ({
  title,
  index,
  isCollapsed,
  onToggle,
  children,
}: CardCollapseProps) => {
  const toggleCollapse = () => {
    onToggle(index);
  };

  return (
    <div>
      <div className={CardHeader} onClick={toggleCollapse}>
        <div className="w-full pr-6">
          <span className={OddHeaderMultiple}>{title}</span>
        </div>
        <div>
          <img
            src={DOWNARROW_BRAND_ICON}
            alt={isCollapsed ? 'Expand' : 'Collapse'}
            className="w-4 animArrow"
          />
        </div>
      </div>
      <div className={`DetailsAnimWrapper ${isCollapsed ? 'detailsInfo' : ''}`}>
        <div className="px-4">{children}</div>
      </div>
    </div>
  );
};

export default CardCollapse;
