import * as Sentry from '@sentry/react';
import { ExecuteResult } from '@cosmjs/cosmwasm-stargate/build/signingcosmwasmclient';
import { PartialExecuteInstruction } from '../hooks/useExecute';

/**
 * This file provides utility functions for instrumenting smart contract transactions
 * with Sentry for performance monitoring and error tracking. It exists to:
 * 1. Centralize transaction instrumentation logic.
 * 2. Provide detailed transaction metrics and error reporting to Sentry.
 */

/**
 * Instruments a smart contract transaction with Sentry span tracking.
 *
 * @param contractAddress - The address of the smart contract being executed.
 * @param walletType - The type of wallet used for the transaction.
 * @param instructions - An array of partial execute instructions for the transaction.
 * @param transactionFunction - A function that executes the actual transaction.
 * @returns A promise that resolves to the execution result, or null if the transaction fails.
 */
export const instrumentTransaction = async (
  contractAddress: string,
  walletType: string | undefined,
  instructions: PartialExecuteInstruction[],
  transactionFunction: () => Promise<ExecuteResult | null>,
): Promise<ExecuteResult | null | undefined> => {
  return Sentry.startSpan(
    {
      name: 'Smart Contract Execution',
      op: 'wallet.transaction',
      forceTransaction: true,
    },
    async (span) => {
      if (!span) {
        console.error('Failed to create Sentry span');
        return null;
      }

      span.setAttribute('contractAddress', contractAddress);
      span.setAttribute('instructionsCount', instructions.length);
      span.setAttribute('walletType', walletType);

      const txStartTime = performance.now();
      const result = await transactionFunction();
      const txEndTime = performance.now();

      if (result) {
        span.setStatus({ code: 1, message: result.transactionHash });
        span.setAttribute('txHash', result.transactionHash);
        span.setAttribute('tx.executionTime', txEndTime - txStartTime);
        span.setAttribute('tx.gasUsed', result.gasUsed.toString());
        span.setAttribute('tx.instructionsCount', instructions.length);
        if (result.events) {
          span.setAttribute('tx.eventsCount', result.events.length);
        }
      } else {
        span.setStatus({ code: 2, message: 'Transaction failed' });
        span.setAttribute('error', 'transaction_failed');
      }

      return result;
    },
  );
};

/**
 * Instruments a wallet connection attempt with Sentry span tracking.
 *
 * @param walletType - The type of wallet being connected.
 * @param connectionFunction - A function that executes the actual connection attempt.
 * @returns A promise that resolves to the connection result.
 */
export const instrumentWalletConnection = async <T>(
  walletType: string,
  connectionFunction: () => Promise<T>,
): Promise<T> => {
  return Sentry.startSpan(
    {
      name: 'Wallet Connection',
      op: 'wallet.connect',
    },
    async (span) => {
      if (!span) {
        console.error('Failed to create Sentry span');
        return connectionFunction();
      }

      span.setAttribute('walletType', walletType);

      const startTime = performance.now();
      try {
        const result = await connectionFunction();
        span.setStatus({ code: 1, message: 'Connection successful' });
        return result;
      } catch (error) {
        span.setStatus({ code: 2, message: 'Connection failed' });
        span.setAttribute(
          'error',
          error instanceof Error ? error.message : 'Unknown error',
        );
        throw error;
      } finally {
        const endTime = performance.now();
        span.setAttribute('connectionTime', endTime - startTime);
      }
    },
  );
};
