import { WalletType, checkWallet } from 'graz';

/**
 * A dictionary of supported wallets with their metadata.
 */
const wallets = {
  [WalletType.KEPLR]: {
    name: 'Keplr',
    imgSrc: '/graz/wallet-icon-keplr.png',
    downloads: {
      chrome:
        'https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap',
      firefox: 'https://addons.mozilla.org/en-US/firefox/addon/keplr/',
      ios: 'https://apps.apple.com/us/app/keplr-wallet/id1567851089',
      android:
        'https://play.google.com/store/apps/details?id=com.chainapsis.keplr',
    },
  },
  [WalletType.LEAP]: {
    name: 'Leap',
    imgSrc: '/graz/wallet-icon-leap.png',
    downloads: {
      chrome:
        'https://chrome.google.com/webstore/detail/leap-cosmos-wallet/fcfcfllfndlomdhbehjjcoimbgofdncg',
      ios: 'https://apps.apple.com/us/app/leap-cosmos/id1642465549',
      android:
        'https://play.google.com/store/apps/details?id=io.leapwallet.cosmos',
    },
  },
  [WalletType.WALLETCONNECT]: {
    name: 'WalletConnect',
    imgSrc: '/graz/wallet-icon-walletconnect.png',
    // WalletConnect is a protocol, not a specific wallet app
  },
  [WalletType.WC_KEPLR_MOBILE]: {
    name: 'Keplr Mobile',
    imgSrc: '/graz/wallet-icon-keplr.png',
    mobile: true,
    downloads: {
      ios: 'https://apps.apple.com/us/app/keplr-wallet/id1567851089',
      android:
        'https://play.google.com/store/apps/details?id=com.chainapsis.keplr',
    },
  },
  [WalletType.WC_LEAP_MOBILE]: {
    name: 'Leap Mobile',
    imgSrc: '/graz/wallet-icon-leap.png',
    mobile: true,
    downloads: {
      ios: 'https://apps.apple.com/us/app/leap-cosmos/id1642465549',
      android:
        'https://play.google.com/store/apps/details?id=io.leapwallet.cosmos',
    },
  },
};

// TODO: need to remove walletconnect icon for mobile
/**
 * List of priority wallets to be displayed first if installed.
 */
const priorityWallets = [
  WalletType.KEPLR,
  WalletType.LEAP,
  WalletType.WC_KEPLR_MOBILE,
  WalletType.WC_LEAP_MOBILE,
];

/**
 * Retrieves a categorized list of wallets based on installation status and priority.
 * @returns An object containing categorized wallet lists.
 */
export const getListedWallets = () => {
  const installedPriorityWallets = priorityWallets.filter((wallet) =>
    checkWallet(wallet),
  );
  const otherInstalledWallets = Object.keys(wallets).filter(
    (wallet) =>
      !priorityWallets.includes(wallet as WalletType) &&
      checkWallet(wallet as WalletType),
  );
  const uninstalledWallets = Object.keys(wallets).filter(
    (wallet) => !checkWallet(wallet as WalletType),
  );

  // Move WalletConnect to the front of uninstalledWallets if it's not installed
  const walletConnectIndex = uninstalledWallets.indexOf(
    WalletType.WALLETCONNECT,
  );
  if (walletConnectIndex > -1) {
    uninstalledWallets.splice(walletConnectIndex, 1);
    uninstalledWallets.unshift(WalletType.WALLETCONNECT);
  }

  return {
    installedPriorityWallets,
    otherInstalledWallets,
    uninstalledWallets,
    allWallets: wallets,
  };
};
