interface MarketTypeOutcome {
  position: number;
  marketTypeId: number;
  outcomeName: string;
  outcomeId: string;
}

interface MarketType {
  id: number;
  marketName: string;
  marketCategory: {
    id: string;
    name: string;
  };
  outcomes: MarketTypeOutcome[];
  outcomesByName: { [key: string]: MarketTypeOutcome };
}

interface MarketTypeMap {
  map: { [key: number]: MarketType };
  outcomesById: { [key: string]: MarketTypeOutcome };
}

const AllMarketCategories = [
  {
    id: 'popular',
    name: 'Popular',
  },
  {
    id: 'all',
    name: 'All',
  },
  {
    id: 'goals',
    name: 'Goals',
  },
  {
    id: 'half',
    name: 'Half',
  },
  {
    id: 'cards-corners',
    name: 'Cards & Corners',
  },
  {
    id: 'other',
    name: 'Other',
  },
];

const rawData: [number, string, string[], string][] = [
  [1, '3 Way Result', ['1', 'X', '2'], 'popular'],
  [2, 'Under/Over', ['Over', 'Under'], 'goals'],
  [
    4,
    'HT/FT',
    ['1/1', '1/X', '1/2', 'X/1', 'X/X', 'X/2', '2/1', '2/X', '2/2'],
    'popular',
  ],
  [5, 'Odd/Even', ['Odd', 'Even'], 'goals'],
  [11, 'Total Corners', ['Over', 'Under', 'Exactly'], 'cards-corners'],
  [16, 'First Team To Score', ['1', '2', 'No Goal'], 'popular'],
  [17, 'Both Teams To Score', ['Yes', 'No'], 'popular'],
  [21, 'Under/Over 1st Period', ['Over', 'Under'], 'half'],
  [34, 'Home Team To Score In Both Halves', ['Yes', 'No'], 'half'],
  [35, 'Away Team To Score In Both Halves', ['Yes', 'No'], 'half'],
  [41, '1st Period Winner', ['1', 'X', '2'], 'half'],
  [42, '2nd Period Winner', ['1', 'X', '2'], 'half'],
  [45, 'Under/Over 2nd Period', ['Over', 'Under'], 'half'],
  [52, '2 Way Result', ['1', '2'], 'popular'],
  [56, 'Last Team To Score', ['Home', 'No Goal', 'Away'], 'other'],
  [
    71,
    'Highest Scoring Half',
    ['1st Half', '2nd Half', 'All Periods the Same'],
    'half',
  ],
  [72, '1st Period Odd/Even', ['Odd', 'Even'], 'half'],
  [73, '2nd Period Odd/Even', ['Odd', 'Even'], 'half'],
  [78, 'To Score Most Sixes', ['1', '2', 'X'], 'popular'],
  [79, 'Will A Penalty Be Awarded?', ['Yes', 'No'], 'other'],
  [82, 'Will A Red Card Be Shown?', ['Yes', 'No'], 'cards-corners'],
  [98, 'Home Team To Keep A Clean Sheet', ['Yes', 'No'], 'goals'],
  [99, 'Away Team To Keep A Clean Sheet', ['Yes', 'No'], 'goals'],
  [101, 'Under/Over - Home Team', ['Under', 'Over'], 'other'],
  [102, 'Under/Over - Away Team', ['Under', 'Over'], 'other'],
  [113, 'Both Teams To Score 1st Half', ['Yes', 'No'], 'half'],
  [
    143,
    'In Which Half Home Team Will Score More Goals?',
    ['1st Half', '2nd Half', 'All Periods the Same'],
    'half',
  ],
  [
    144,
    'In Which Half Away Team Will Score More Goals?',
    ['1st Half', '2nd Half', 'All Periods the Same'],
    'half',
  ],
  [153, 'Under/Over 1st Period - Home Team', ['Over', 'Under'], 'half'],
  [155, 'Under/Over 1st Period - Away Team', ['Over', 'Under'], 'half'],
  [161, '10 Minute Result', ['1', 'X', '2'], 'popular'],
  [162, '10 Minute Under/Over', ['Over', 'Under'], 'popular'],
  [170, 'Odd/Even Corners', ['Even', 'Odd'], 'cards-corners'],
  [194, 'Home Team To Keep A Clean Sheet 1st Period', ['Yes', 'No'], 'half'],
  [195, 'Home Team To Keep A Clean Sheet 2nd Period', ['Yes', 'No'], 'half'],
  [196, 'Away Team To Keep A Clean Sheet 1st Period', ['Yes', 'No'], 'half'],
  [197, 'Away Team To Keep A Clean Sheet 2nd Period', ['Yes', 'No'], 'half'],
  [198, 'Odd/Even - Home Team', ['Odd', 'Even'], 'goals'],
  [199, 'Odd/Even - Away Team', ['Odd', 'Even'], 'goals'],
  [211, 'Both Teams To Score 2nd Half', ['Yes', 'No'], 'half'],
  [409, '1X2 Corners', ['1', 'X', '2'], 'cards-corners'],
  [
    415,
    '1X2 And Both Teams To Score',
    [
      '1 And Both Teams To Score',
      'Both Teams To Score And X',
      '2 And Both Teams To Score',
      '1 To Nil',
      'Both Teams Not To Score',
      '2 To Nil',
    ],
    'popular',
  ],
  [
    427,
    '1X2 And Under/Over',
    [
      '1 And Over',
      'X And Over',
      '2 And Over',
      '1 And Under',
      'X And Under',
      '2 And Under',
    ],
    'popular',
  ],
  [765, 'To Score Most Fours', ['1', '2', 'X'], 'popular'],
  [766, 'Under/Over - Match Fours', ['Under', 'Over'], 'popular'],
  [767, 'Under/Over - Match Sixes', ['Over', 'Under'], 'popular'],
  [1229, 'Under/Over Shots on Target - Home Team', ['Over', 'Under'], 'other'],
  [1230, 'Under/Over Shots on Target - Away Team', ['Over', 'Under'], 'other'],
  [1280, 'Under/Over Wides', ['Under', 'Over'], 'popular'],
  [1282, 'Under/Over Wickets', ['Under', 'Over'], 'popular'],
  [1283, 'Under/Over Match Fours - Home Team', ['Under', 'Over'], 'popular'],
  [1284, 'Under/Over Match Sixes - Home Team', ['Over', 'Under'], 'popular'],
  [1285, 'Under/Over Match Fours - Away Team', ['Under', 'Over'], 'popular'],
  [1286, 'Under/Over Match Sixes - Away Team', ['Over', 'Under'], 'popular'],
];

export const MARKET_TYPES_MAP: MarketTypeMap = (() => {
  const map: { [key: number]: MarketType } = {};
  const outcomesById: { [key: string]: MarketTypeOutcome } = {};

  rawData.forEach(([id, marketName, outcomeNames, category]) => {
    const outcomes: MarketTypeOutcome[] = [];
    const outcomesByName: { [key: string]: MarketTypeOutcome } = {};
    const marketCategory =
      AllMarketCategories.find((type) => type.id === category) ||
      AllMarketCategories[4];
    outcomeNames.forEach((outcomeName, position) => {
      const outcome: MarketTypeOutcome = {
        position,
        marketTypeId: id,
        outcomeName,
        outcomeId: `${id}-${position}`,
      };
      outcomesById[outcome.outcomeId] = outcome;
      outcomesByName[outcome.outcomeName] = outcome;
      outcomes.push(outcome);
    });

    map[id] = { id, marketName, outcomes, outcomesByName, marketCategory };
  });

  return { map, outcomesById };
})();

export function getOutcomeName(
  marketTypeId: number,
  position: number,
  homeTeam?: string,
  awayTeam?: string,
): string | undefined {
  const marketType = MARKET_TYPES_MAP.map[marketTypeId];
  if (marketType) {
    const outcome = marketType.outcomes.find(
      (outcome) => outcome.position === position,
    );

    if (outcome) {
      if (outcome.outcomeName === '1') {
        return homeTeam || 'Home';
      }
      if (outcome.outcomeName === 'X') {
        return 'Draw';
      }
      if (outcome.outcomeName === '2') {
        return awayTeam || 'Away';
      }
      return outcome.outcomeName;
    }
  }
  return undefined;
}

export function getAllMarketCategories(typeIds: string[]) {
  if (!typeIds.length) {
    return AllMarketCategories;
  }
  return AllMarketCategories.filter(
    (category) =>
      !!typeIds.find(
        (id) =>
          MARKET_TYPES_MAP.map[parseInt(id, 10)].marketCategory.id ===
          category.id,
      ) || AllMarketCategories[1].id === category.id,
  );
}

export function getMarketsInCategory(typeIds: string[], categoryId: string) {
  if (categoryId === 'all') {
    return typeIds;
  }
  return typeIds.filter(
    (id) =>
      MARKET_TYPES_MAP.map[parseInt(id, 10)].marketCategory.id === categoryId,
  );
}

export function getOutcomesByMarketId(
  id: number,
): MarketTypeOutcome[] | undefined {
  const marketType = MARKET_TYPES_MAP.map[id];
  return marketType ? marketType.outcomes : undefined;
}

export function getMarketNameById(id: number): string | undefined {
  return MARKET_TYPES_MAP.map[id].marketName;
}
