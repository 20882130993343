import {
  BETS_ICON_ACTIVE,
  BET_ICON_ERROR,
  ICON_FAILED,
  SUCCESS_ICON_ACTIVE,
} from '#/constants/common/images.constants';
import { ReactElement } from 'react';
import {
  IconWrapper,
  ContentText,
  ContentTitle,
  Icon,
  PlacedIcon,
} from './Toast.styled';
import Link from '../Link.component';

const TOASTCONFIG = {
  UnableToFetch: {
    title: 'Unable to fetch data',
    subText: 'Please refresh the page or try again later.',
    iconSrc: ICON_FAILED,
    iconClass: PlacedIcon,
    iconWrapperClass: '!bg-danger-bg-1',
  },
  TryAgain: {
    title: 'Please try again',
    subText: 'Something went wrong while placing your bet.',
    iconSrc: BET_ICON_ERROR,
    iconClass: Icon,
    iconWrapperClass: '!bg-danger-bg-1',
  },
  TransactionPlaced: {
    title: 'Transaction placed successfully',
    subText: 'Check your notifications for updates on its status.',
    iconSrc: SUCCESS_ICON_ACTIVE,
    iconClass: PlacedIcon,
    iconWrapperClass: '',
  },
  TransactionFailed: {
    title: 'Transaction failed',
    subText: 'Please try placing your transaction again.',
    iconSrc: ICON_FAILED,
    iconClass: PlacedIcon,
    iconWrapperClass: '!bg-danger-bg-1',
  },
  ProceedTransaction: {
    title: 'Your transaction is being processed',
    subText: '',
    iconSrc: BETS_ICON_ACTIVE,
    iconClass: Icon,
    iconWrapperClass: '',
  },
  ProcessingBet: {
    title: 'Your bet is being processed',
    subText: '',
    iconSrc: BETS_ICON_ACTIVE,
    iconClass: Icon,
    iconWrapperClass: '',
  },
  ProcessingBets: {
    title: 'Your bets are being processed',
    subText: '',
    iconSrc: BETS_ICON_ACTIVE,
    iconClass: Icon,
    iconWrapperClass: '',
  },
  BetFailed: {
    title: 'Bet placement failed',
    subText: 'Please try placing your bet again.',
    iconSrc: ICON_FAILED,
    iconClass: PlacedIcon,
    iconWrapperClass: '!bg-danger-bg-1',
  },
  BetsFailed: {
    title: 'Some of the bets failed',
    subText: 'Please try placing your bet again.',
    iconSrc: ICON_FAILED,
    iconClass: PlacedIcon,
    iconWrapperClass: '!bg-danger-bg-1',
  },
  BetslipFull: {
    title: 'Your bet slip is full',
    subText: 'You can only have a maximum of 10 bets at a time.',
    iconSrc: BET_ICON_ERROR,
    iconClass: Icon,
    iconWrapperClass: '!bg-danger-bg-1',
  },
  HouseslipFull: {
    title: 'Your deposit slip is full',
    subText: 'You can only have a maximum of 10 deposits at a time.',
    iconSrc: BET_ICON_ERROR,
    iconClass: Icon,
    iconWrapperClass: '!bg-danger-bg-1',
  },
  BetPlaced: {
    title: 'Bet placed successfully',
    subText: 'Check your notifications for updates on its status.',
    iconSrc: SUCCESS_ICON_ACTIVE,
    iconClass: PlacedIcon,
    iconWrapperClass: '',
  },
  BetsPlaced: {
    title: 'Bets placed successfully',
    subText: 'Check your notifications for updates on its status.',
    iconSrc: SUCCESS_ICON_ACTIVE,
    iconClass: PlacedIcon,
    iconWrapperClass: '',
  },
  MatchShareSuccess: {
    title: 'Shared successfully.',
    subText: '',
    iconSrc: SUCCESS_ICON_ACTIVE,
    iconClass: PlacedIcon,
    iconWrapperClass: '',
  },
  MatchShareFailed: {
    title: 'Sharing failed',
    subText: 'Please try again.',
    iconSrc: ICON_FAILED,
    iconClass: PlacedIcon,
    iconWrapperClass: '!bg-danger-bg-1',
  },
  AddressCopiedSuccess: {
    title: 'Copied successfully',
    subText: 'Address copied to your clipboard.',
    iconSrc: SUCCESS_ICON_ACTIVE,
    iconClass: PlacedIcon,
    iconWrapperClass: '',
  },
  WrongWallet: {
    title: 'Wrong wallet',
    subText:
      'The address you are trying to connect is not the registered betting address for your account. Please connect with the registered address. If you feel this message is in error please contact us',
    iconSrc: ICON_FAILED,
    iconClass: PlacedIcon,
    iconWrapperClass: '!bg-danger-bg-1',
  },
  InvalidWallet: {
    title: 'Invalid wallet',
    subText: 'Wallet address already linked with another user.',
    iconSrc: ICON_FAILED,
    iconClass: PlacedIcon,
    iconWrapperClass: '!bg-danger-bg-1',
  },
  OnboardingCompleted: {
    title: 'Approved KYC verification',
    subText: (
      <div>
        Congratulations! Today’s Market of the Day is a great place to start
        placing your bets!
        <Link to={'/soccer#marketoftheday'}>here</Link>
      </div>
    ),
    iconSrc: SUCCESS_ICON_ACTIVE,
    iconClass: PlacedIcon,
    iconWrapperClass: '',
  },
};

export function CustomToast({
  title,
  subText,
  iconSrc,
  iconClass,
  iconWrapperClass,
}: {
  title: string;
  subText: string | ReactElement;
  iconSrc: string;
  iconClass: string;
  iconWrapperClass?: string;
}) {
  return (
    <div className="flex">
      <span className={`${IconWrapper} ${iconWrapperClass}`}>
        <img className={iconClass} src={iconSrc} alt="Icon" />
      </span>
      <div>
        <h2 className={ContentTitle}>{title}</h2>
        {subText ? <h3 className={ContentText}>{subText}</h3> : null}
      </div>
    </div>
  );
}

export function AppToast({ id }: { id: keyof typeof TOASTCONFIG }) {
  return <CustomToast {...TOASTCONFIG[id]} />;
}
