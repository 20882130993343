/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ReactNode } from 'react';
import { NavLink as Navigate } from 'react-router-dom';

interface LinkPropTypes {
  children: ReactNode;
  onClick?: () => void;
  id?: string;
  className?: string;
  to: string;
  target?: string;
  rel?: string;
  end?: boolean;
  prefetch?: string;
}

export default function NavLink({
  children,
  onClick,
  id,
  className = '',
  to,
  target = '',
  rel = '',
  end = false,
}: LinkPropTypes) {
  return (
    <Navigate
      className={className}
      to={to}
      target={target}
      rel={rel}
      end={end}
      id={id}
      data-testid={id}
      onClick={onClick}
    >
      {children}
    </Navigate>
  );
}
