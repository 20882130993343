import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type State = {
  isModalOpen: boolean;
};

const initialState: State = {
  isModalOpen: false,
};

const slice = createSlice({
  name: 'walletConnection',
  initialState,
  reducers: {
    setIsModalOpen: (state, data: PayloadAction<boolean>) => {
      state.isModalOpen = data.payload;
    },
  },
});

export const { setIsModalOpen } = slice.actions;
export default slice.reducer;
