import {
  LoaderBg,
  LoaderSeparator,
} from '../common/LoaderStyle/LoaderStyle.styled';

export default function NotificationsLoader() {
  return (
    <div className="">
      <div className="w-full">
        <div className="w-full flex justify-between">
          <div className="mr-3">
            <div className={`${LoaderBg} !size-8 !rounded-full`}></div>
          </div>
          <div className="flex-1">
            <div className="flex justify-between items-center mb-1">
              <div className={`${LoaderBg} h-5 w-2/3`}></div>
              <div className={`${LoaderBg} h-4 w-1/12`}></div>
            </div>
            <div className={`${LoaderBg} h-5 w-4/5 mb-2`}></div>
            <div className={`${LoaderBg} h-5 w-2/6`}></div>
          </div>
        </div>
        <div className={LoaderSeparator} />
        <div className="w-full flex justify-between">
          <div className="mr-3">
            <div className={`${LoaderBg} !size-8 !rounded-full`}></div>
          </div>
          <div className="flex-1">
            <div className="flex justify-between items-center mb-1">
              <div className={`${LoaderBg} h-5 w-2/3`}></div>
              <div className={`${LoaderBg} h-4 w-1/12`}></div>
            </div>
            <div className={`${LoaderBg} h-5 w-4/5 mb-2`}></div>
            <div className={`${LoaderBg} h-5 w-2/6`}></div>
          </div>
        </div>
        <div className={LoaderSeparator} />
        <div className="w-full flex justify-between">
          <div className="mr-3">
            <div className={`${LoaderBg} !size-8 !rounded-full`}></div>
          </div>
          <div className="flex-1">
            <div className="flex justify-between items-center mb-1">
              <div className={`${LoaderBg} h-5 w-2/3`}></div>
              <div className={`${LoaderBg} h-4 w-1/12`}></div>
            </div>
            <div className={`${LoaderBg} h-5 w-4/5 mb-2`}></div>
            <div className={`${LoaderBg} h-5 w-2/6`}></div>
          </div>
        </div>
        <div className={LoaderSeparator} />
        <div className="w-full flex justify-between">
          <div className="mr-3">
            <div className={`${LoaderBg} !size-8 !rounded-full`}></div>
          </div>
          <div className="flex-1">
            <div className="flex justify-between items-center mb-1">
              <div className={`${LoaderBg} h-5 w-2/3`}></div>
              <div className={`${LoaderBg} h-4 w-1/12`}></div>
            </div>
            <div className={`${LoaderBg} h-5 w-4/5 mb-2`}></div>
            <div className={`${LoaderBg} h-5 w-2/6`}></div>
          </div>
        </div>
        <div className={LoaderSeparator} />
        <div className="w-full flex justify-between">
          <div className="mr-3">
            <div className={`${LoaderBg} !size-8 !rounded-full`}></div>
          </div>
          <div className="flex-1">
            <div className="flex justify-between items-center mb-1">
              <div className={`${LoaderBg} h-5 w-2/3`}></div>
              <div className={`${LoaderBg} h-4 w-1/12`}></div>
            </div>
            <div className={`${LoaderBg} h-5 w-4/5 mb-2`}></div>
            <div className={`${LoaderBg} h-5 w-2/6`}></div>
          </div>
        </div>
        <div className={LoaderSeparator} />
        <div className="w-full flex justify-between">
          <div className="mr-3">
            <div className={`${LoaderBg} !size-8 !rounded-full`}></div>
          </div>
          <div className="flex-1">
            <div className="flex justify-between items-center mb-1">
              <div className={`${LoaderBg} h-5 w-2/3`}></div>
              <div className={`${LoaderBg} h-4 w-1/12`}></div>
            </div>
            <div className={`${LoaderBg} h-5 w-4/5 mb-2`}></div>
            <div className={`${LoaderBg} h-5 w-2/6`}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
