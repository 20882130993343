import Link from './common/Link.component';
import {
  CardHeaderWrapper,
  ExploreAll,
  ExploreMatchContainer,
  HeadTitle,
  IconSize,
  IconWrapper,
} from './MatchCard/TrendingCard/TrendingCard.styled';
import WithData from './Wrapper/WrapWithTransitionStates';
import { FixtureSummary, HomepageData } from '#/utils/backend';
import {
  LoaderBg,
  LoaderBtn,
  LoaderCardContainer,
  LoaderSeparator,
} from './common/LoaderStyle/LoaderStyle.styled';
import { useLocation } from 'react-router-dom';
import EmptyList from './common/EmptyStates/EmptyState.component';
import MatchCard from './MatchCard/MatchCard/MatchCard.component';
import { GroupWrapper } from './MatchCard/GroupedMatches/GroupedMatches.styled';

const VerticalMatchListWithData = ({
  data,
  header,
  icon,
}: {
  data: FixtureSummary[];
  header?: string;
  icon: string;
}) => {
  const location = useLocation();
  const isOnExplorePage = location.pathname.includes('explore');
  if (!data || data.length === 0)
    return (
      <div>
        {header ? (
          <div className={CardHeaderWrapper}>
            <span className={IconWrapper}>
              <img className={IconSize} src={icon} alt="" />
            </span>
            <h2 className={HeadTitle}>{header}</h2>
          </div>
        ) : null}
        <EmptyList id="homepage" />
      </div>
    );
  return (
    <div>
      <div>
        {header ? (
          <div className={CardHeaderWrapper}>
            <span className={IconWrapper}>
              <img className={IconSize} src={icon} alt="" />
            </span>
            <h2 className={HeadTitle}>{header}</h2>
          </div>
        ) : null}
        <div className={GroupWrapper}>
          {data.map((fixture) => {
            return <MatchCard fixture={fixture} key={fixture.id} />;
          })}
        </div>
        {isOnExplorePage ? null : (
          <div className={ExploreMatchContainer}>
            <Link to="/explore" id="see_all_matches">
              <p className={ExploreAll}>EXPLORE ALL MATCHES</p>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

const LoadingComponent = ({
  header,
  icon,
}: {
  header?: string;
  icon?: string;
}) => {
  return (
    <div>
      {header ? (
        <div className={CardHeaderWrapper}>
          <span className={IconWrapper}>
            {icon ? <img src={icon} alt={'icon'} /> : null}
          </span>
          <h2 className={HeadTitle}>{header}</h2>
        </div>
      ) : null}
      <div className={LoaderCardContainer}>
        <div>
          <div className={`${LoaderBg} h-4 w-4/5 mb-1`}></div>
          <div className={`${LoaderBg} h-4 w-8/12 mb-2`}></div>
          <div className="flex space-x-2 mb-4">
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
          </div>
          <div className={`${LoaderBg} h-4 w-1/3`}></div>
        </div>
        <div className={LoaderSeparator} />
        <div>
          <div className={`${LoaderBg} h-4 w-4/5 mb-1`}></div>
          <div className={`${LoaderBg} h-4 w-8/12 mb-2`}></div>
          <div className="flex space-x-2 mb-4">
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
          </div>
          <div className={`${LoaderBg} h-4 w-1/3`}></div>
        </div>
        <div className={LoaderSeparator} />
        <div>
          <div className={`${LoaderBg} h-4 w-4/5 mb-1`}></div>
          <div className={`${LoaderBg} h-4 w-8/12 mb-2`}></div>
          <div className="flex space-x-2 mb-4">
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
          </div>
          <div className={`${LoaderBg} h-4 w-1/3`}></div>
        </div>
      </div>
    </div>
  );
};

const VerticalMatchList = WithData<HomepageData>(
  VerticalMatchListWithData,
  LoadingComponent,
);

export default VerticalMatchList;
