export const PageContainer = `w-full p-4`;
export const ContentContainer = `w-full mt-5`;
export const Header = `w-full flex items-center`;
export const PageTitle = `text-white font-normal text-sm xxxs:text-xl tracking-wider`;
export const HeaderIcon = `w-5 mr-4`;
export const ContentText = `text-text-gray-4 font-light text-sm mb-1`;
export const TabsContainer = `w-full space-y-3 mt-4`;
export const TabItems = `w-full text-left rounded-llg bg-primary-bg-2 py-4 pl-4 pr-28 relative whitespace-normal`;
export const TabTitleText = `flex text-white font-bold text-sm uppercase mb-1`;
export const TabContentText = `text-text-gray-4 font-normal text-sm`;
export const ArrowIcon = `w-3 absolute right-4 top-2/4 -mt-3`;
export const FieldContainer = `w-full mb-4`;
export const BtnWrapper = `space-y-4`;
export const InputLabel = `block text-white font-normal text-sm`;
export const InputField = `w-full font-light bg-primary-bg-3 text-base text-text-gray-1 placeholder:text-gray-1 focus:outline-none pl-4 pr-12 py-2.5 border-0 rounded-md mt-2`;
export const InputFieldWrapper = `relative w-full`;
export const SgeText = `text-text-gray-1 font-light text-sm xxxs:text-base absolute right-3 top-4`;
export const ButtonSave = `w-full text-center sm:w-80 tracking-wider rounded-md bg-brand-bg-1 px-4 py-2.5 text-black font-bold text-sm xxxs:text-base uppercase`;
export const ButtonSaveWrapper = `mt-6`;

export const LogoWrapper = `w-full mt-14`;
export const LogoImg = `w-16`;
export const HeaderTitle = `text-2xl text-text-brand-1 font-bold tracking-wider mt-10`;
export const InfoText = `text-white font-normal text-sm mb-1`;
