import { forwardRef } from 'react';
import { type ButtonProps } from './Button.types';
import {
  IconStyles,
  LoadingButtonStyles,
  SpinnerLoaderStyles,
  dynamicButtonStyles,
} from './Button.styled';
import SpinLoader from '../../SpinLoader/SpinLoader.component';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled = false,
      icon,
      iconPosition = 'left',
      iconClass = '',
      isLoading = false,
      type = 'button',
      onClick,
      id = 'button_click',
      name,
      value,
    },
    ref,
  ) => {
    // Construct dynamic classes for the button
    const buttonClassNames = dynamicButtonStyles({
      isLoading,
      hasIcon: !!icon,
      iconPosition,
      className,
    });

    // Optional icon component
    const iconComponent = icon ? (
      <img src={icon} className={IconStyles(iconClass)} alt="Button Icon" />
    ) : null;

    return (
      <button
        ref={ref}
        className={buttonClassNames}
        disabled={disabled || isLoading}
        // eslint-disable-next-line react/button-has-type
        type={type}
        onClick={onClick}
        data-testid={id}
        id="button_click"
        name={name}
        value={value}
      >
        {isLoading ? (
          <span className={LoadingButtonStyles}>
            <div className={SpinnerLoaderStyles}>
              <SpinLoader />
            </div>
            {children}
          </span>
        ) : (
          <>
            {iconComponent}
            {children}
          </>
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
