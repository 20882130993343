import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  CheckInput,
  FilterCardContainer,
  HeadDivider,
  HeaderWrapper,
  MarketItemContainer,
  MarketLabel,
  Title,
} from '../FilterCards/FilterCards.styled';
import { MARKET_TYPES_MAP } from '#/utils/marketsMap';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import { toggleMarketTypeIdFilterAndSynchUrl } from '#/utils/slices/activityFiltersSlice';

const MarketFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const marketTypeIdFilters = useAppSelector(
    (state) => state.activityFilters.marketTypeIds,
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const handleMarketChange = (marketTypeId: number) => {
    dispatch(
      toggleMarketTypeIdFilterAndSynchUrl({
        marketTypeId,
        searchParams,
        setSearchParams,
      }),
    );
  };

  return (
    <>
      <div className={HeaderWrapper}>
        <span className={Title}>Market</span>
      </div>
      <hr className={HeadDivider} />
      <div className={FilterCardContainer}>
        <ul>
          {Object.values(MARKET_TYPES_MAP.map).map((marketType) => {
            const value = marketTypeIdFilters[marketType.id] || false;

            return (
              <li
                key={marketType.id}
                className={MarketItemContainer}
                onClick={() => {
                  handleMarketChange(marketType.id);
                }}
              >
                <input
                  className={`${CheckInput} !mr-0`}
                  type="checkbox"
                  id={String(marketType.id)}
                  name="market_filter"
                  checked={value}
                  onChange={() => {}}
                />
                <span className={MarketLabel}>{marketType.marketName}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default MarketFilters;
