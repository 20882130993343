import { SearchListContainer2 } from './SearchResults.styled';
import { DEFAULT_MARKET_TYPE } from '#/constants/common/global-constants';
import { SearchResultsProps } from './SearchResults.types';
import GroupMatches from '#/components/MatchCard/GroupedMatches/GroupedMatches.component';
import { useEffect, useState } from 'react';

const SearchResults = ({ searchInput }: SearchResultsProps) => {
  const [queryParam, setQueryParam] = useState({
    search: searchInput,
    offset: 0,
    filters: {
      marketType: DEFAULT_MARKET_TYPE,
      leagueIds: [],
    },
    leagues: [],
  });

  useEffect(() => {
    if (searchInput) {
      setQueryParam({
        search: searchInput,
        offset: 0,
        filters: {
          marketType: DEFAULT_MARKET_TYPE,
          leagueIds: [],
        },
        leagues: [],
      });
    }
  }, [searchInput]);

  if (!searchInput) return null;

  return (
    <div className={SearchListContainer2}>
      <GroupMatches
        isSearchResult={true}
        isFiltered={false}
        queryParams={queryParam}
        title={`search-result`}
      />
    </div>
  );
};

export default SearchResults;
