import env from '#/utils/env';
import { setUser as setUserAction, UserState } from '#/utils/slices/usersSlice';
import { useAppSelector } from '#/utils/store';
import { useAuth0 } from '@auth0/auth0-react';
import { useDisconnect } from 'graz';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Function to fetch user data from Auth0 and API
const fetchUserData = async (accessToken: string) => {
  const auth0UserInfo = await fetch(`https://${env.auth0.domain}/userinfo`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });

  const userInfo = await fetch(`${env.apiServer}/users/me`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });

  if (!auth0UserInfo.ok || !userInfo.ok) {
    throw new Error('Failed to fetch user data');
  }

  const [auth0UserBody, userBody] = await Promise.all([
    auth0UserInfo.json(),
    userInfo.json(),
  ]);

  return { auth0UserBody, userBody };
};

// Custom hook to manage user data
const useUserData = () => {
  const auth0 = useAuth0();
  const {
    getAccessTokenSilently,
    logout,
    isAuthenticated: isAuthenticatedAuth0,
    isLoading: isLoadingAuth0,
  } = auth0;

  const { disconnect: disconnectWallet } = useDisconnect();

  const userState = useAppSelector((state) => state.users);
  const dispatch = useDispatch();

  const setUser = useCallback(
    (user: Partial<UserState>) => {
      dispatch(setUserAction(user));
    },
    [dispatch],
  );

  // State to control refetchInterval
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false); // No refetch by default

  // Fetch user data with React Query
  const { data, isLoading, isError, isSuccess, refetch } = useQuery({
    queryKey: ['userData'], // Unique query key for user data
    queryFn: async () => {
      // Check if this required everytime. Ignoring it for now.
      const accessToken = await getAccessTokenSilently(); // Get Auth0 access token
      return fetchUserData(accessToken); // Fetch user data from the API
    },
    retry: 3, // Retry up to 3 times on failure
    refetchInterval, // Dynamically controlled refetch interval
    refetchIntervalInBackground: true, // Continue polling even when the app is in the background
    refetchOnWindowFocus: true,
    enabled: isAuthenticatedAuth0 && !isLoadingAuth0,
  });

  // Use useEffect to handle dynamic refetchInterval
  useEffect(() => {
    if (isSuccess && data) {
      const { auth0UserBody, userBody } = data;

      // Update user in the Redux store
      setUser({
        isLoading: false,
        auth0Id: auth0UserBody.sub,
        username: auth0UserBody.nickname,
        name: auth0UserBody.name,
        image: auth0UserBody.picture,
        email: auth0UserBody.email,
        isEmailVerified: auth0UserBody.email_verified,
        sgeAddress: userBody.walletAddr,
        kycSessionId: userBody.kycSessionId,
        kycStatus: userBody.kycStatus, // Map API string to KycStatus
      });

      // Set the refetch interval after the first successful fetch
      if (userBody.kycStatus !== 'APPROVED') {
        setRefetchInterval(30000); // Set interval to 30 seconds for non-approved KYC status
      }
    }
  }, [isSuccess, data, setUser]);

  // Handle errors in useEffect
  useEffect(() => {
    if (isError) {
      disconnectWallet();
      if (isAuthenticatedAuth0) {
        setUser({ isLoading: false });
        logout({
          logoutParams: { returnTo: window.location.origin },
        });
      }
    }
  }, [isError, setUser, disconnectWallet, logout, isAuthenticatedAuth0]);

  const isAuthenticated = auth0.isAuthenticated && !userState.isLoading;

  return {
    fetchUser: refetch, // Allows manual refetching of the user data
    getAccessToken: auth0.getAccessTokenSilently, // For manual access token fetching
    setUser,
    isLoading: isLoading || userState.isLoading, // Show loading state
    isAuthenticated,
    user: isAuthenticated ? userState : undefined, // Return user data if authenticated
  };
};

export default useUserData;
