/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ReactNode } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

interface LinkPropTypes {
  children: ReactNode;
  id?: string;
  className?: string;
  to: string;
  state?: unknown;
  prefetch?: string;
}

export default function Link({
  children,
  id = 'react-router-link',
  className = '',
  to,
  state,
}: LinkPropTypes) {
  return (
    <ReactRouterLink
      className={className}
      to={to}
      state={state}
      id={id}
      data-testid={id}
    >
      {children}
    </ReactRouterLink>
  );
}
