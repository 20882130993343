import { Base64 } from 'js-base64';
import { JSONValue } from 'types/global';

/**
 * @description - Convert Objects to Base 64 Strings
 * @param {JSONValue} query - Object to Convert
 * @returns {string} - Converted base 64 string
 */
export const convertStringToBase64 = (query: JSONValue) => {
  return `${Base64.encode(JSON.stringify(query))}`;
};

/**
 * @description - Convert String to formatted currency numbers
 * @param {string} amount - Object to Convert
 * @returns {string} - Converted base 64 string
 */
export function formatCurrency(amount: string) {
  return new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: amount.length,
  }).format(Number(amount));
}

/**
 * @description - Replaces Dynamic UID from a text
 * @param {string} text -String from which dynamic params has to be removed
 * @param {string} replacement -String which is a replacement of a dynamic param.
 * @returns {string} - the string which had dynamic params to be replaced with replacement string
 */
export function replacePathParams(
  urlOrPath: string,
  params: Record<string, string> = {},
) {
  if (!urlOrPath) {
    return '';
  }
  let path = urlOrPath;
  let prePath = '';
  try {
    const url = new URL(urlOrPath);
    path = url.href.replace(url.origin, '');
    prePath = url.origin;
    // eslint-disable-next-line no-empty
  } catch (error) {}
  const hasStartingSlash = path.startsWith('/');
  const hasTrailingSlash = path.endsWith('/');
  const pathFragments = path
    .split('/')
    .map((fragment) => {
      if (!fragment || !fragment.startsWith(':')) {
        return fragment;
      }
      if (fragment.includes('?') && !fragment.endsWith('?')) {
        throw Error('Invalid URL');
      }
      const copyFragment = fragment.replace(':', '').replace('?', '');
      return params[copyFragment];
    })
    .filter(Boolean);
  return `${prePath}${hasStartingSlash ? '/' : ''}${pathFragments.join('/')}${
    hasTrailingSlash ? '/' : ''
  }`;
}

/** Converts a word to Camel Case */
export function toCamelCase(key: string) {
  if (!key) return '';
  const arr = key.split('');
  arr[0] = arr[0].toUpperCase();
  return arr.join('');
}

/** Copies the text to clipboard */
export async function copyToClipboard(text: string) {
  await navigator.clipboard.writeText(text);
}

/** Clone using JSON */
export function cloneObject<B>(params: B) {
  return JSON.parse(JSON.stringify(params)) as B;
}

/** Convert string "false"/"true" to bool */
export function parseToBool(params: string) {
  try {
    return JSON.parse(params) as boolean;
  } catch (err) {
    return true;
  }
}

/** Addresses and Transactions */
export function truncateString(str: string, isSmall?: boolean) {
  if (!str) return str;
  if (isSmall)
    return `${str.slice(0, 4)}...${str.slice(str.length - 6, str.length)}`;
  if (str.length <= 30) return str;
  const start = str.slice(0, 24);
  const end = str.slice(-5);
  return `${start}...${end}`;
}

export function convertStringToInt(str: string, defaultValue = 0): number {
  const intValue: number = parseInt(str, 10);
  return Number.isNaN(intValue) ? defaultValue : intValue;
}

export function formatNumberEuropean(number: string): string {
  try {
    // Validate the input is not empty and is a number
    if (!number.trim()) {
      throw new Error('Input is empty.');
    }
    // Split the number from the currency if present
    const parts = number.split(' ');
    const numericPart = parts[0];
    const currency = parts.length > 1 ? ` ${parts[1]}` : '';
    // Check if the numeric part is a valid number
    const numericValue = Number(numericPart);
    if (Number.isNaN(numericValue)) {
      throw new Error('Input must be a valid number.');
    }
    // Special handling for zero
    if (numericValue === 0) {
      return `0 ${currency}`;
    }
    // Format the number
    const fixedNumeric = numericValue.toFixed(2);
    const [integer, decimal] = fixedNumeric.split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // Construct the final formatted string
    const formattedNumber = `${formattedInteger}.${decimal}`;

    // Return the formatted number with the currency if it was present
    return formattedNumber + currency;
  } catch (error) {
    // Log the error and provide a fallback value
    // Return a fallback value or handle it as needed
    return '0 SGE';
  }
}
