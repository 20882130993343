import {
  CardContainer,
  CardHeaderWrapper,
  CardInnerContainer,
  CardMainContainer,
  CardScrollContainer,
  CardSubTitle,
  CardTitle,
  HeadTitle,
  IconSize,
  IconWrapper,
  LostBadge,
  OddValueContainer,
  WonBadge,
} from './TrendingCard.styled';
import { formatDateToDesiredFormat } from '#/utils/datetime/datetime';
import { DATE_TIME_FORMATS } from '#/utils/datetime/datetime.constants';
import DashboardMarket from '#/components/common/MarketComponent/DashboardMarket.component';
import { getMarketNameById } from '#/utils/marketsMap';
import { useAppDispatch } from '#/utils/store';
import { selectOrDeselect } from '#/utils/slices/betSlipsSlice';
import { FixtureSummary, Odds, outcomeId } from '#/utils/backend';
import Link from '#/components/common/Link.component';
import {
  FlexContainer,
  MatchLeagueTime,
} from '../MatchDetails/MatchDetails.styled';

type TrendingCardProps = {
  icon: string;
  title: string;
  markets: FixtureSummary[] | undefined;
};

export default function TrendingList({
  icon,
  title,
  markets,
}: TrendingCardProps) {
  const dispatch = useAppDispatch();

  function addBetSlip(market: FixtureSummary, odd: Odds) {
    dispatch(
      selectOrDeselect({
        fixture: {
          id: market.fixtureId.toString(),
        },
        market: {
          id: market.id,
          name: market.marketTypeName,
          marketTypeId: market.marketTypeId,
        },
        outcome: {
          id: outcomeId(odd),
          position: odd.outcomePosition,
          name: odd.outcomeName,
          acceptedRate: odd.value,
        },
        homeTeam: {
          name: market.teams.Home.Name,
        },
        awayTeam: {
          name: market.teams.Away.Name,
        },
        slippageParams: {
          slippage: '0',
          odds: odd.value,
        },
        betProvisions: {
          acceptableBetSize: odd.acceptableBetSize
        }
      }),
    );
  }

  return (
    <div className={CardMainContainer}>
      <div className={CardHeaderWrapper}>
        <span className={IconWrapper}>
          <img className={IconSize} src={icon} alt="" />
        </span>
        <h2 className={HeadTitle}>{title}</h2>
      </div>
      <div id="CardScroll" className={CardScrollContainer}>
        {markets?.map((market) => (
          <TrendingCard
            market={market}
            key={market.id}
            addBetSlip={addBetSlip}
          />
        ))}
      </div>
    </div>
  );
}

export function TrendingCard({
  market,
  addBetSlip,
}: {
  market: FixtureSummary;
  addBetSlip: (market: FixtureSummary, odd: Odds) => void;
}) {
  return (
    <div className={CardContainer}>
      <div
        className={CardInnerContainer({
          $isDisabled: market.fixtureStatus !== 'NotStarted',
        })}
      >
        <div className="flex justify-between w-full">
          <Link
            to={`/match-detail/${market.fixtureId}`}
            state={{ navigated: true }}
            prefetch="intent"
            id="redirect_to_match_details"
          >
            <div
              className={CardTitle}
            >{`${market.teams.Home.Name} vs ${market.teams.Away.Name}`}</div>
          </Link>
          {market.fixtureStatus !== 'NotStarted' ? (
            <div
              className={
                market.fixtureStatus === 'Cancelled' ? LostBadge : WonBadge
              }
            >
              {market.fixtureStatus.toUpperCase()}
            </div>
          ) : null}
        </div>
        <div className={CardSubTitle}>
          {market.fixtureName ? (
            <div className={MatchLeagueTime({ isMainMarketCard: false })}>
              {formatDateToDesiredFormat(
                market.startDate,
                DATE_TIME_FORMATS.DATE_TIME_2,
              )}
              &nbsp; | &nbsp;
            </div>
          ) : null}

          <div className={FlexContainer}>
            <div className={MatchLeagueTime({ isMainMarketCard: false })}>
              <Link to={''} prefetch="intent" id="redirect_to_league">
                {`${market.league.Name}`}
              </Link>
            </div>
          </div>
        </div>
        <div className={CardTitle}>
          {getMarketNameById(market.marketTypeId) || market.marketTypeName}
        </div>
        <div className={OddValueContainer}>
          <DashboardMarket
            market={market}
            oddType={1}
            addBetSlip={addBetSlip}
          />
        </div>
      </div>
    </div>
  );
}
