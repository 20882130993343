interface FilterListItemProp {
  isActive: boolean;
}
export const ContentContainer = `w-full`;
export const Header = `w-full flex items-center mb-3`;
export const PageTitle = `text-white font-normal text-sm xxxs:text-xl tracking-wider`;
export const HeaderIcon = `w-5 mr-4`;
export const TxAddress = `text-base text-white font-normal uppercase`;
export const LabelText = `text-sm text-text-gray-5 font-light`;
export const ValueText = `text-sm text-white font-normal`;
export const CardHeader = `flex justify-between items-center mb-2`;
export const StatusBadge = `flex-none text-center uppercase text-sm text-text-gray-1 font-bold rounded-md px-4 py-1 border border-solid border-card-bg-2 min-w-28`;
export const DateText = `text-sm text-white font-light text-right`;
export const CardContainer = `relative w-full mb-4 bg-primary-bg-2 rounded-md p-4`;
export const CardFooter = `flex items-center space-x-8 mt-3`;
export const FlexCenter = `flex items-center mt-3`;
export const CopyIcon = `w-4.5 ml-2`;
export const CheckInput = `size-4 6xl:size-5 mr-2 appearance-none rounded-sm  border border-text-gray-2 focus:outline-none align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer checked:bg-brand-bg-1 checked:bg-[url('/v2-icons/check-icon-dark.svg')] checked:border-brand-bg-1 checked:bg-[length:10px_10px]`;

export const HeaderWrapper = `flex justify-between items-center`;
export const Title = `text-base md:text-lg 3xl:text-xl 4xl:text-xl 5xl:text-2xl 6xl:text-3xl text-text-gray-1 font-light uppercase`;
export const HeadDivider = `h-0 border border-solid border-t-0 border-border-bg-1 mt-3 mb-4`;
export const FilterListItemText = ({
  isActive,
}: FilterListItemProp) => `w-full text-sm lg:text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl 6xl:text-4xl text-text-gray-2 flex justify-between font-light
  ${isActive ? 'text-white' : ''}`;
export const MarketItemContainer = `pb-3 lg:py-2 4xl:py-4 5xl:py-6 lg:cursor-pointer flex items-center`;
export const MarketLabel = `text-sm lg:text-base text-white font-normal`;
export const FilterCardContainer = `block bg-transparent overflow-y-hidden`;
export const SuccessBadge = `flex-none min-w-24 text-center uppercase text-sm text-text-success-1 font-bold rounded-md px-3 py-1 bg-success-bg-2`;
export const FailedBadge = `flex-none min-w-24 text-center uppercase text-sm text-text-danger-1 font-bold rounded-md px-3 py-1 bg-danger-bg-2`;
