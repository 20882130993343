import React from 'react';
import { twMerge } from 'tailwind-merge';

import {
  CardContainer,
  CardHeader,
  StatusBadge,
  WonBadge,
  LostBadge,
  DateText,
  Title,
  SubTitle,
  CardFooter,
  FlexCenter,
  CardFooterIcon,
  CardFooterText,
  CardFlex,
  CardFlexItems,
  LabelText,
  ValueText,
} from './ActivityCard.styled';
import RedirectLink from '../RedirectLink.component';
import { ActivityCardProps, StatusType } from './ActivityCard.types';
import { COPY_ICON } from '#/constants/common/images.constants';

const statusTypeToClass = (statusType: StatusType): string => {
  switch (statusType) {
    case 'success':
      return WonBadge;
    case 'failure':
      return LostBadge;
  }
};

const ActivityCard: React.FC<ActivityCardProps> = ({
  statusText,
  statusType,
  dateText,
  title,
  titleText,
  subTitle,
  footerInfo,
  infoItems,
  transactionLink,
  children,
}) => {
  const statusClasses = statusType
    ? statusTypeToClass(statusType)
    : StatusBadge;

  return (
    <div className={CardContainer}>
      <div className={CardHeader}>
        {statusText && <span className={statusClasses}>{statusText}</span>}
        <span className={DateText}>{dateText}</span>
      </div>
      <h2 className={Title}>{title}</h2>
      {titleText ? <h3 className={Title}>{titleText}</h3> : null}
      <h3 className={SubTitle}>{subTitle}</h3>
      {transactionLink && (
        <RedirectLink
          className={twMerge(SubTitle, 'flex my-2')}
          href={transactionLink}
          target="_blank"
          rel="noreferrer"
          id="liquidity_card_transaction_explorer_link"
        >
          {transactionLink}
          <img
            className={twMerge(CardFooterIcon, 'ml-2')}
            src={COPY_ICON}
            alt="copy"
          />
        </RedirectLink>
      )}
      {infoItems && (
        <div className={twMerge(CardFlex, 'grid grid-cols-2 gap-2')}>
          {infoItems.map((item, index) => (
            <div className={CardFlexItems} key={index}>
              <span className={LabelText}>{item.label}</span>
              <span className={ValueText}>{` ${item.value}`}</span>
            </div>
          ))}
        </div>
      )}
      {footerInfo && (
        <div className={twMerge(CardFooter, 'grid grid-cols-2 gap-4')}>
          {footerInfo.map((item, index) => (
            <span
              className={twMerge(
                FlexCenter,
                index === footerInfo.length - 1 ? 'justify-self-end' : '',
              )}
              key={index}
            >
              <img className={CardFooterIcon} src={item.iconSrc} alt="icon" />
              <span className={CardFooterText}>{item.text}</span>
            </span>
          ))}
        </div>
      )}
      {children && <div className="mt-4">{children}</div>}
    </div>
  );
};

export default ActivityCard;
